import React from 'react';

let RightSidebarContext = React.createContext();

function RightSidebarContextProvider(props) {

    let initialState = {
        loading: true,
        reload: false,
        sidebarOpen: false,
        contentType: null,
        textboxesOpen: {},
        contentReference: null,
        show: false
    };

    const reducer = (sidebarState, action) => {
        switch (action.type) {
            case "SET_TEXTBOX_OPEN":
                sidebarState.textboxesOpen[action.payload.boxRef] = action.payload.boxOpen;
                return {
                    ...sidebarState,
                    loading: false,
                    reload: false
                };
            case "SET_TEXTBOXES_CLOSED":
                return {
                    ...sidebarState,
                    textboxesOpen: {},
                    loading: false,
                    reload: false
                };
            case "SET_SIDEBAR_OPEN":
                return {
                    ...sidebarState,
                    sidebarOpen: action.payload,
                    show: true,
                    loading: false,
                    reload: false
                };
            case "SET_CONTENT_TYPE":
                return {
                    ...sidebarState,
                    contentType: action.payload,
                    loading: false,
                    reload: false
                };
            case "SET_CONTENT_REFERENCE":
                return {
                    ...sidebarState,
                    contentReference: action.payload,
                    loading: false,
                    reload: false
                };
            default:
                break;
        }
    };

    let [rightSidebarState, rightSidebarDispatch] = React.useReducer(reducer, initialState);
    let value = { rightSidebarState, rightSidebarDispatch };

    return (
        <RightSidebarContext.Provider value={value}>{props.children}</RightSidebarContext.Provider>
    );
}

let RightSidebarContextConsumer = RightSidebarContext.Consumer;

export { RightSidebarContext, RightSidebarContextProvider, RightSidebarContextConsumer };