import React from 'react';
import AddressForm from '../Forms/AddressForm';
import Loading from '../Loading/Loading';

export default function AddressEditModal(props) {
	const {
		handleAddressUpdateInput,
		patchAddress,
		setNewAddressFormOpen,
		setShowEdit,
		updatedAddress,
		updateLoading,
		setUpdateLoading,
	} = props || {};

	return (
		<div className='mainModal'>
			<AddressForm address={updatedAddress} handleAddressInput={handleAddressUpdateInput} required={true} />
			{!updateLoading ? (
				<div className='addressButtons'>
					<div>
						<input
							type='button'
							className='functionalButtonPrimary'
							onClick={() => {
								patchAddress(updatedAddress);
								setUpdateLoading(true);
							}}
							defaultValue='Update address'
						/>
					</div>
					<div className='marginTop-10'>
						<input
							type='button'
							className='functionalButtonSecondary'
							onClick={() => {
								setNewAddressFormOpen(false);
								setShowEdit(false);
							}}
							defaultValue='Cancel'
						/>
					</div>
				</div>
			) : (
				<>
					<Loading type='circle' />
				</>
			)}
		</div>
	);
}
