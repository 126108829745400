import React, { useEffect } from 'react';
import * as THREE from 'three';

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';

export default function ThreeJsModel(props) {

	function renderModel() {
		if (!window.requestAnimationFrame) {
			window.requestAnimationFrame = (function () {
				return window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame ||
					window.oRequestAnimationFrame ||
					window.msRequestAnimationFrame ||
					function (callback, element) {
						window.setTimeout(callback, 1000 / 60);
					};
			})();
		}

		let container;
		let camera, cameraTarget, scene, renderer;
		let mesh;

		let targetRotationX = 0.5;
		let targetRotationOnMouseDownX = 0;

		let targetRotationY = 0.2;
		let targetRotationOnMouseDownY = 0;

		let mouseX = 0;
		let mouseXOnMouseDown = 0;

		let mouseY = 0;
		let mouseYOnMouseDown = 0;

		let windowHalfX = window.innerWidth / 2;
		let windowHalfY = window.innerHeight / 2;

		let slowingFactor = 0.25;

		init();

		function init() {
			container = document.getElementsByClassName('threeJsModel')[0];
			document.body.appendChild(container);

			scene = new THREE.Scene();

			scene.background = new THREE.Color('white');

			let materials = [];

			for (let i = 0; i < 6; i++) {
				materials.push(new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff }));
			}

			renderer = new THREE.WebGLRenderer();
			renderer.setSize(700, 400);

			container.appendChild(renderer.domElement);

			renderer.domElement.addEventListener('mousedown', onDocumentMouseDown, false);
		}

		function addShadowedLight(x, y, z, color, intensity) {
			let directionalLight = new THREE.DirectionalLight(color, intensity);
			directionalLight.position.set(x, y, z);
			scene.add(directionalLight);

			directionalLight.castShadow = true;

			let d = 1;
			directionalLight.shadow.camera.left = - d;
			directionalLight.shadow.camera.right = d;
			directionalLight.shadow.camera.top = d;
			directionalLight.shadow.camera.bottom = - d;

			directionalLight.shadow.camera.near = 1;
			directionalLight.shadow.camera.far = 4;

			directionalLight.shadow.bias = - 0.002;
		}

		function onDocumentMouseDown(event) {
			renderer.domElement.addEventListener('mousemove', onDocumentMouseMove, false);
			renderer.domElement.addEventListener('mouseup', onDocumentMouseUp, false);
			renderer.domElement.addEventListener('mouseout', onDocumentMouseOut, false);

			mouseXOnMouseDown = event.clientX - windowHalfX;
			targetRotationOnMouseDownX = targetRotationX;

			mouseYOnMouseDown = event.clientY - windowHalfY;
			targetRotationOnMouseDownY = targetRotationY;
		}

		function onDocumentMouseMove(event) {
			mouseX = event.clientX - windowHalfX;

			targetRotationX = (mouseX - mouseXOnMouseDown) * 0.00025;

			mouseY = event.clientY - windowHalfY;

			targetRotationY = (mouseY - mouseYOnMouseDown) * 0.00025;
		}

		function onDocumentMouseUp(event) {
			renderer.domElement.removeEventListener('mousemove', onDocumentMouseMove, false);
			renderer.domElement.removeEventListener('mouseup', onDocumentMouseUp, false);
			renderer.domElement.removeEventListener('mouseout', onDocumentMouseOut, false);
		}

		function onDocumentMouseOut(event) {
			renderer.domElement.removeEventListener('mousemove', onDocumentMouseMove, false);
			renderer.domElement.removeEventListener('mouseup', onDocumentMouseUp, false);
			renderer.domElement.removeEventListener('mouseout', onDocumentMouseOut, false);
		}

		camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1000);
		camera.position.y = 0;
		camera.position.z = 20;

		document.getElementsByClassName('rangeSlider')[0].oninput = (e) => {
			camera.position.z = e.target.value;
		};

		scene.add(camera);

		cameraTarget = new THREE.Vector3(0, - 0.25, 0);

		let loader = new STLLoader();
		let material = new THREE.MeshPhongMaterial({ color: 0xAAAAAA, specular: 0x111111, shininess: 200 });
		console.log(props.file)
		loader.load(props.file, function (geometry) {
			let meshMaterial = material;
			if (geometry.hasColors) {
				meshMaterial = new THREE.MeshPhongMaterial({ opacity: geometry.alpha, vertexColors: true });
			}

			mesh = new THREE.Mesh(geometry, meshMaterial);

			mesh.position.set(0, 0, 0);
			mesh.scale.set(1, 1, 1);

			mesh.castShadow = false;
			mesh.receiveShadow = false;

			scene.add(mesh);

			function animate() {
				requestAnimationFrame(animate);
				render();
			}

			function render() {
				rotateAroundWorldAxis(mesh, new THREE.Vector3(0, 1, 0), targetRotationX);
				rotateAroundWorldAxis(mesh, new THREE.Vector3(1, 0, 0), targetRotationY);

				targetRotationY = targetRotationY * (1 - slowingFactor);
				targetRotationX = targetRotationX * (1 - slowingFactor);
				renderer.render(scene, camera);
			}

			function rotateAroundObjectAxis(object, axis, radians) {
				let rotationMatrix = new THREE.Matrix4();

				rotationMatrix.makeRotationAxis(axis.normalize(), radians);
				object.matrix.multiply(rotationMatrix);
				object.rotation.setFromRotationMatrix(object.matrix);
			}

			function rotateAroundWorldAxis(object, axis, radians) {
				let rotationMatrix = new THREE.Matrix4();

				rotationMatrix.makeRotationAxis(axis.normalize(), radians);
				rotationMatrix.multiply(object.matrix);
				object.matrix = rotationMatrix;
				object.rotation.setFromRotationMatrix(object.matrix);
			}

			addShadowedLight(20, 0, 20, 'white', 0.8);
			addShadowedLight(0, - 0, 20, 'white', 0.8);
			animate()
		});
	}

	useEffect(() => {
		let canvasElements = document.querySelectorAll('.threeJsModel canvas');
		for (let i = 0; i < canvasElements.length; i++) {
			canvasElements[i].remove();
		}
		if (!props.hidden) {
			renderModel()
		}
	}, [props.hidden]); // eslint-disable-next-line

	return (
		<div className="threeJsContainer">
			<div className={!props.hidden ? "overlay fadeIn" : "overlay fadeOut"} hidden={props.hidden} />
			<div className={`threeJsModel`} hidden={props.hidden}>
				<button onClick={(e) => { props.toggle(null, true) }} className="closeModel">Close</button>
				<input className="rangeSlider" type="range" min="1" max="100" defaultValue="20" />
			</div>
		</div>
	)
}

