import React from 'react';
import { Link } from 'react-router-dom';
import {
	AppsIcon,
	CaretDownIcon,
	CaretUpIcon,
	CartBuyIcon,
	CartSellIcon,
	ChartIcon,
	DashboardIcon,
	LegalIcon,
	SupportIcon,
} from '../../../components/SVGIcons/SVGIcons';

export default function SidebarOrderListing(props) {
	const { textboxesOpen, openTextbox } = props || {};

	return (
		<>
			<div className='leftSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => {}}>
					<DashboardIcon iconClass={'dashboardIcon'} />
					<Link to='/'>
						<h4>Dashboard</h4>
					</Link>
				</div>
				<div className='sidebarTextHeader' onClick={() => openTextbox('buy')}>
					<CartBuyIcon iconClass={'cartBuyIcon'} />
					<h4>Buy</h4>
					{textboxesOpen['buy'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{textboxesOpen['buy'] && (
					<>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>Approved</p>
							</Link>
						</div>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>Suppliers</p>
							</Link>
						</div>
						<div className='sidebarTextBody'>
							<Link to='/orders?type=1'>
								<p>Orders</p>
							</Link>
						</div>
					</>
				)}
				<div className='sidebarTextHeader' onClick={() => openTextbox('sell')}>
					<CartSellIcon iconClass={'cartSellIcon'} />
					<h4>Sell</h4>
					{textboxesOpen['sell'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{textboxesOpen['sell'] && (
					<>
						<div className='sidebarTextBody'>
							<Link to='/orders?type=2'>
								<p>Sales</p>
							</Link>
						</div>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>Listings</p>
							</Link>
						</div>
					</>
				)}
				<div className='sidebarTextHeader disabledDiv'>
					<ChartIcon iconClass={'chartIcon'} />
					<Link to='/'>
						<h4>Analytics</h4>
					</Link>
				</div>
				<div className='sidebarTextHeader'>
					<AppsIcon iconClass={'appsIcon'} />
					<Link to='/manage'>
						<h4>Apps</h4>
					</Link>
				</div>
			</div>
			<div className='leftSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => openTextbox('support')}>
					<SupportIcon iconClass={'supportIcon'} />
					<h4>Support</h4>
					{textboxesOpen['support'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{textboxesOpen['support'] && (
					<>
						<div className='sidebarTextBody'>
							<Link to='/tutorials/en/tutorial'>
								<p>Tutorials</p>
							</Link>
						</div>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>FAQ</p>
							</Link>
						</div>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>Support</p>
							</Link>
						</div>
					</>
				)}
				<div className='sidebarTextHeader' onClick={() => openTextbox('legal')}>
					<LegalIcon iconClass={'legalIcon'} />
					<h4>Legal</h4>
					{textboxesOpen['legal'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{textboxesOpen['legal'] && (
					<>
						<div className='sidebarTextBody disabledDiv'>
							<Link to='/'>
								<p>Terms & conditions</p>
							</Link>
						</div>
					</>
				)}
			</div>
		</>
	);
}
