import React, { useState } from 'react';
import SupportDropdown from '../../../components/Dropdowns/SupportDropdown';
import { QuestionIcon } from '../../../components/SVGIcons/SVGIcons';

export default function SupportIcon() {
	const [open, setOpen] = useState(false);
	return (
		<>
			{open && <SupportDropdown setOpen={setOpen} />}
			<QuestionIcon
				iconClass='supportIconFixed'
				onClick={() => {
					setOpen(!open);
				}}
			/>
		</>
	);
}
