import React from 'react';
import { Checkmark, ErrorIcon } from '../SVGIcons/SVGIcons';

export default function AddressForm(props) {
	const { address, handleAddressInput, required } = props || {};
	return (
		<div>
			<div className='formRowThin'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='addressName'>
						Name:
					</p>
				</div>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						type='text'
						name='name'
						defaultValue={address?.name}
						onChange={handleAddressInput}
						required={required}
					/>
					{address?.name ? <Checkmark iconClass={'inputIcon'} /> : <ErrorIcon iconClass={'inputIcon'} />}
				</div>
			</div>
			<div className='formRowThin'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='addressStreet'>
						Street:
					</p>
				</div>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						type='text'
						name='street'
						defaultValue={address?.street}
						onChange={handleAddressInput}
						required={required}
					/>
					{address?.street ? <Checkmark iconClass={'inputIcon'} /> : <ErrorIcon iconClass={'inputIcon'} />}
				</div>
			</div>
			<div className='formRowThin'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='addressCity'>
						City:
					</p>
				</div>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						type='text'
						name='city'
						defaultValue={address?.city}
						onChange={handleAddressInput}
						required={required}
					/>
					{address?.city ? <Checkmark iconClass={'inputIcon'} /> : <ErrorIcon iconClass={'inputIcon'} />}
				</div>
			</div>
			<div className='formRowThin'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='addressPostalCode'>
						Postal code:
					</p>
				</div>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						type='text'
						name='postalCode'
						defaultValue={address?.postalCode}
						onChange={handleAddressInput}
						required={required}
					/>
					{address?.postalCode ? (
						<Checkmark iconClass={'inputIcon'} />
					) : (
						<ErrorIcon iconClass={'inputIcon'} />
					)}
				</div>
			</div>
			<div className='formRowThin'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='addressCountry'>
						Country:
					</p>
				</div>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						type='text'
						name='country'
						defaultValue={address?.country}
						onChange={handleAddressInput}
						required={required}
					/>
					{address?.country ? <Checkmark iconClass={'inputIcon'} /> : <ErrorIcon iconClass={'inputIcon'} />}
				</div>
			</div>
		</div>
	);
}
