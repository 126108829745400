import React, { useContext } from 'react';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { dateConverter, parseProcessingStatus } from '../../helpers';
import {
	BillIcon,
	BookmarkIcon,
	CaretDownIcon,
	CaretRightIcon,
	CartBuyIcon,
	CartSellIcon,
	InboxIcon,
	ProductPlaceholderIcon,
} from '../../components/SVGIcons/SVGIcons';

export default function OrderListItem(props) {
	const { order, isSeller } = props || {};
	const { rightSidebarDispatch } = useContext(RightSidebarContext);

	function fillAndOpenRightSidebar(boxRef) {
		const contentType = 2;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: order });
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
		rightSidebarDispatch({ type: 'SET_TEXTBOX_OPEN', payload: { boxRef: boxRef, boxOpen: true } });
	}

	return (
		<div className={`orderItemCont ${isSeller ? 'sale' : 'purchase'}`}>
			<div className='floatingHeader'>
				<h2 className='marginLeft-40'>
					{isSeller ? (
						<InboxIcon iconClass='orderPageIconLarge' />
					) : (
						<CartBuyIcon iconClass='orderPageIconLarge' />
					)}
					{isSeller ? order?.buyerTeamName : order?.sellerTeamName}
				</h2>
				<div className='btnCont'>
					<button
						className='btn3-primary marginRight-10'
						onClick={() => {
							window.location.assign(`/orders/${order.orderUuid}`);
						}}>
						See order details
					</button>
				</div>
			</div>
			<div className='cardCont'>
				<div className='row'>
					<div className='orderItemText'>
						<BookmarkIcon iconClass='orderPageIcon' />
						<h4>{order.orderRef.length > 20 ? `${order.orderRef.slice(0, 20)}...` : order.orderRef}</h4>
					</div>
					<div className='orderItemText'>
						<BillIcon iconClass='orderPageIcon' />
						<h4>{order.totalPrice ? order.totalPrice : 'Awaiting pricing'}</h4>
					</div>
					<div className='orderItemText'>
						<CartBuyIcon iconClass='orderPageIcon' />
						<h4>{dateConverter(order.createdAt)}</h4>
					</div>
					<div className='orderItemText'></div>
				</div>
				<div className='orderItemCenterRow'>
					<div className='colRow'>
						<div className='img'>
							<ProductPlaceholderIcon iconClass='placeholderIcon' />
						</div>
						<div className='flexColumn-1'>
							{order.orderPrescription && (
								<h4 className='prescription'>
									{order.orderPrescription.length > 150
										? `${order.orderPrescription.slice(0, 150)}...`
										: order.orderPrescription}
								</h4>
							)}
						</div>
					</div>
					<div className='flexColumn-2'>
						<div className='orderItemText'>
							Status:
							<h4 className={`${parseProcessingStatus(order.orderProcessingStatus).style} marginLeft-10`}>
								{parseProcessingStatus(order.orderProcessingStatus).text}
							</h4>
						</div>
					</div>
					<div className='flexColumn-3'>
						<button className='btn3-secondary' disabled>
							Actions
							<CaretDownIcon iconClass='caretDownIconGray marginLeft-10' />
						</button>
					</div>
				</div>
				<div className='row'>
					<div className='orderItemLinkText' onClick={() => fillAndOpenRightSidebar('contact')}>
						See order summary
						<CaretRightIcon iconClass='caretRightIconWhite' />
					</div>
				</div>
			</div>
		</div>
	);
}
