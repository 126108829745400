import React from 'react';
import { Link } from 'react-router-dom';

export default function DashboardWidgetLinks(props) {
	const { links } = props || {};

	return (
		<>
			{links.length > 0 &&
				links.map((link, key) => {
					return (
						<div className='cardLink' key={key}>
							<Link to={`/${link.path}?type=${link.type}&status=${link.status}`}>
								<h4>{link.name}</h4>
								<h4>{link.count}</h4>
							</Link>
						</div>
					);
				})}
		</>
	);
}
