import React, { useState, useEffect } from 'react';
import { CaretRightIcon, SupplierTrustedIcon } from '../../components/SVGIcons/SVGIcons';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import TrustedAddBtn from '../global/TrustedAddBtn/TrustedAddBtn';
import Loading from '../../components/Loading/Loading';
import config from '../../config';
import Axios from 'axios';

export default function TeamProfile() {
	const [supplier, setSupplier] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [showExtendedDescription, setShowExtendedDescription] = useState(false);
	const [paragraphLength, setParagraphLength] = useState(900);

	let navigate = useNavigate();
	let params = useParams();

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			async function fetchSupplier() {
				try {
					const response = await Axios({
						url: `${config.apiv1}/team/team.read/${params.id}`,
						method: 'GET',
					});

					if (response?.data?.data) {
						setSupplier(response.data.data);
						setLoaded(true);
					} else {
						navigate('/error');
					}
				} catch (err) {
					console.log(err);
				}
			}
			fetchSupplier();
		}
		return () => (mounted = false);
	}, [navigate]);

	if (!loaded) {
		return <Loading type={'circle'} />;
	}

	return (
		<>
			{loaded && (
				<>
					<div className='productPageContainer'>
						<div className='sellerLogo'>
							<img src={supplier.teamIcon} alt='' />
						</div>
						<div className='productPageTop'>
							<div className='leftColumn'>
								<div className='headerCont'>
									<SupplierTrustedIcon iconClass='supplierTrustedIcon' />
									<h1 className='marginLeft-20'>{supplier.teamInfo.teamName}</h1>
								</div>
								<div className='headerCont'>
									<h3>{supplier.teamInfo.companyDescriptionHeader}</h3>
								</div>
								{supplier.teamInfo.companyDescription.length > paragraphLength ? (
									<div>
										<p className={!showExtendedDescription ? 'hideTextOverflow' : ''}>
											{supplier.teamInfo.companyDescription}
										</p>
										<h5 onClick={() => setShowExtendedDescription(!showExtendedDescription)}>
											Read more
										</h5>
									</div>
								) : (
									<>
										<p>{supplier.teamInfo.companyDescription}</p>
									</>
								)}
							</div>
							<div className='centerColumn'>
								<div className='supplierPageDropdown disabledDiv'>
									<div className='ratingLink'>
										<h4>Imprint </h4>
										<CaretRightIcon iconClass='caretRightIcon' />
									</div>
								</div>
								<div className='supplierPageDropdown disabledDiv'>
									<div className='ratingLink'>
										<h4>Shipping terms </h4>
										<CaretRightIcon iconClass='caretRightIcon' />
									</div>
								</div>
								<div className='supplierPageDropdown disabledDiv'>
									<div className='ratingLink'>
										<h4>Terms & conditions </h4>
										<CaretRightIcon iconClass='caretRightIcon' />
									</div>
								</div>
								<div className='supplierPageDropdown disabledDiv'>
									<div className='ratingLink'>
										<h4>Privacy policy </h4>
										<CaretRightIcon iconClass='caretRightIcon' />
									</div>
								</div>
							</div>
							<div className='rightColumn'>
								<Link
									to={`/order?uuid=${supplier?.teamUuid}&name=${supplier?.teamInfo?.teamName}`}
									className='button1-primary marginBottom-50'>
									Place an order
								</Link>
								<div className='btnCont'>
									<TrustedAddBtn
										supplierUuid={supplier?.teamUuid}
										supplierName={supplier?.teamInfo?.teamName}
										style={1}
									/>
									<button className='button1-secondary marginTop-20' disabled>
										Send message
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
