import React, { useContext, useEffect } from 'react';
import SidebarOrder from './SidebarOrder';
import SidebarOrderListing from './SidebarOrderListing';
import { RightSidebarContext } from '../../../contexts/RightSidebarContext';
import { useLocation } from 'react-router';
import Overlay from '../../../components/Overlay/Overlay';
import { TopnavCloseIcon } from '../../../components/SVGIcons/SVGIcons';
import SidebarOrderFilter from './SidebarOrderFilter';

export default function SidebarRight() {
	const { rightSidebarState, rightSidebarDispatch } = useContext(RightSidebarContext);
	let location = useLocation();

	function openSwitch() {
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !rightSidebarState.sidebarOpen });
	}

	function openTextbox(boxRef) {
		rightSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: {
				boxRef: boxRef,
				boxOpen: rightSidebarState.textboxesOpen[boxRef]
					? rightSidebarState.textboxesOpen[boxRef]?.boxOpen
					: true,
			},
		});
	}

	useEffect(() => {
		if (rightSidebarState.show) {
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
		}
	}, [location]); // eslint-disable-next-line

	return (
		<div className='rightSidebar'>
			<Overlay
				loadOverlay={rightSidebarState.show}
				showOverlay={rightSidebarState.sidebarOpen}
				setShowOverlay={openSwitch}
			/>
			{rightSidebarState.show ? (
				<div
					className={
						rightSidebarState.sidebarOpen
							? 'rightSidebarList slideInRight'
							: 'rightSidebarList slideOutRight'
					}>
					<div className='rightSidebarTopRow'>
						<TopnavCloseIcon iconClass='closeIcon' onClick={openSwitch} />
					</div>
					{rightSidebarState.contentType === 1 && (
						<SidebarOrder
							contentReference={rightSidebarState.contentReference}
							textboxesOpen={rightSidebarState.textboxesOpen}
							openTextbox={openTextbox}
						/>
					)}
					{rightSidebarState.contentType === 2 && (
						<SidebarOrderListing
							contentReference={rightSidebarState.contentReference}
							textboxesOpen={rightSidebarState.textboxesOpen}
							openTextbox={openTextbox}
						/>
					)}
					{rightSidebarState.contentType === 3 && (
						<SidebarOrderFilter
							contentReference={rightSidebarState.contentReference}
							textboxesOpen={rightSidebarState.textboxesOpen}
							openTextbox={openTextbox}
						/>
					)}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}
