import Axios from 'axios';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Checkmark, ErrorIcon } from '../../components/SVGIcons/SVGIcons';
import config from '../../config';
import authConfig from '../../authConfig.json';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';

export default function InviteTeam() {
	const [responseMsg, setResponseMsg] = useState('');
	const [email, setEmail] = useState('');

	const { teamState } = useContext(CurrentTeamContext);

	function sendInvitation(e) {
		e.preventDefault();
		Axios({
			url: `${config.apiv1}/email/teamInvite`,
			method: 'POST',
			data: {
				senderName: teamState.currentTeam.teamInfo.teamName,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				invitations: [{ recipientEmail: email }],
				clientObject: {
					clientId: authConfig.clientId,
					redirectUri: window.location.origin,
					scope: 'openid profile email offline_access',
				},
			},
		});
		setEmail('');
		setResponseMsg('Invitation successfully sent!');
	}

	function checkEmail() {
		if (email) {
			if (email.includes('@') && email.split('@')[1]) {
				return true;
			}
		}
	}

	return (
		<div className='memberManagementContainer'>
			<div className='invitationContainer'>
				<h2>Want to invite your preferred supplier?</h2>
				<h4>Send them an invitation with the form below:</h4>
				{responseMsg ? <h4>{responseMsg}</h4> : null}
				<form onSubmit={sendInvitation}>
					<div className='inputRow'>
						<p className='bold' htmlFor='email'>
							Email:
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								type='email'
								name='email'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								required
							/>
							{email !== '' && checkEmail() ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<input className='submitBtn' type='submit' value='Submit' />
				</form>
			</div>
		</div>
	);
}
