import React from 'react';

export default function OrderTracking() {
	return (
		<>
			<div className='wideColumn'>
				<div className='flexRow'>
					<button className='btn3-secondary marginLeft-40'>See History</button>
					<button className='btn3-secondary marginRight-40'>Send item</button>
				</div>
			</div>
		</>
	);
}
