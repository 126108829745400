import React from 'react';
import { CaretDownIcon, CaretUpIcon } from '../../../../components/SVGIcons/SVGIcons';

export default function SellerContact(props) {
	const { order, textboxesOpen, openTextbox } = props || {};
	return (
		<>
			<div className='rightSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => openTextbox('contact')}>
					<span className='bold'>Seller's contact</span>
					{textboxesOpen['contact'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{order && textboxesOpen['contact'] && <></>}
			</div>
		</>
	);
}
