import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import Loading from '../components/Loading/Loading';
import config from '../config';
import { splitUserId } from '../helpers';
import { CurrentUserContext } from './CurrentUserContext';

let CurrentTeamContext = React.createContext();

function CurrentTeamContextProvider(props) {
	let initialState = {
		loading: true,
		reload: false,
		currentTeam: null,
		isTeamOfOne: false,
		userPermissions: {},
	};

	let { userState, userDispatch } = useContext(CurrentUserContext);

	const halfSecond = 500;

	const reducer = (teamState, action) => {
		switch (action.type) {
			case 'RESET':
				localStorage.removeItem('currentTeam');
				return {
					...teamState,
					loading: true,
					reload: true,
					currentTeam: null,
					isTeamOfOne: false,
				};
			case 'SET_TEAM':
				localStorage.setItem('currentTeam', action.payload);
				setTimeout(() => {
					window.location.reload();
				}, halfSecond);
				return {
					...teamState,
					loading: false,
					reload: false,
					userPermissions: extractUserPermissions(teamState.userTeams, action.payload),
				};
			case 'SET_TEAM_ADDRESSES':
				teamState.currentTeam.teamInfo.addresses = action.payload;
				return {
					...teamState,
					loading: false,
					reload: true,
				};
			case 'SET_USER_TEAMS':
				return {
					...teamState,
					loading: false,
					reload: false,
					userTeams: action.payload,
				};
			case 'FETCH_SUCCESS':
				return {
					...teamState,
					loading: false,
					reload: false,
					currentTeam: action.payload.currentTeam,
					userTeams: action.payload.userTeams,
					isTeamOfOne: action.payload.isTeamOfOne,
					userPermissions: extractUserPermissions(
						action.payload.userTeams,
						action.payload.currentTeam.teamUuid
					),
				};
			case 'RELOAD':
				return {
					...teamState,
					loading: true,
					reload: true,
				};
			default:
				return {
					...teamState,
				};
		}
	};

	let [teamState, teamDispatch] = React.useReducer(reducer, initialState);
	let value = { teamState, teamDispatch };

	function extractUserPermissions(userTeams, teamUuid) {
		let permissions = {};
		let team = userTeams.filter((team) => team.teamUuid === teamUuid)[0];
		if (team) {
			let member = team.members.filter((member) => member.userUuid === splitUserId(userState.currUser.sub))[0];
			if (member) {
				permissions = member.permissions || {};
			}
		}
		return permissions;
	}

	useEffect(() => {
		const getTeamMembersInfo = async (team) => {
			const userUuids = [];
			for (let i = 0; i < team.members.length; i++) {
				userUuids.push(`"${team.members[i].userUuid}"`);
			}
			let res = await Axios({
				url: `${config.auth}/v1/user/users.read/{"users": [${userUuids}]}`,
				method: 'GET',
			});
			for (let i = 0; i < team.members.length; i++) {
				for (let i2 = 0; i2 < res.data.users.length; i2++) {
					if (team.members[i].userUuid === res.data.users[i2].userUuid) {
						team.members[i].email = res.data.users[i2].email;
						team.members[i].firstname = res.data.users[i2].firstname;
						team.members[i].lastname = res.data.users[i2].lastname;
						team.members[i].avatar = res.data.users[i2].avatar;
					}
				}
			}
		};
		if (userState.isAuthenticated && !userState.loading) {
			async function getCurrentTeam() {
				Axios({
					url: `${config.apiv1}/team/team.read/me/${splitUserId(userState.currUser.sub)}`,
					method: 'GET',
				})
					.then((response) => {
						if (!localStorage.getItem('currentTeam')) {
							if (!response?.data?.data) {
								userDispatch({ type: 'LOGOUT' });
							}
							localStorage.setItem('currentTeam', response.data.data[0].teamUuid);
							Axios.defaults.headers.common['teamUuid'] = response.data.data[0].teamUuid;
							getTeamMembersInfo(response.data.data[0]).then(() => {
								teamDispatch({
									type: 'FETCH_SUCCESS',
									payload: {
										currentTeam: response.data.data[0],
										userTeams: response.data.data,
										isTeamOfOne: response.data.data[0].teamInitials === 'ME',
									},
								});
							});
						} else {
							let currentTeam;
							for (let team of response.data.data) {
								if (team.teamUuid === localStorage.getItem('currentTeam')) {
									Axios.defaults.headers.common['teamUuid'] = team.teamUuid;
									currentTeam = team;
								}
							}
							if (currentTeam) {
								getTeamMembersInfo(currentTeam).then(() => {
									teamDispatch({
										type: 'FETCH_SUCCESS',
										payload: {
											currentTeam: currentTeam,
											userTeams: response.data.data,
											isTeamOfOne: currentTeam.teamInitials === 'ME',
										},
									});
								});
							} else {
								teamDispatch({ type: 'RESET' });
							}
						}
					})
					.catch((err) => {
						if (err) {
							console.log(err);
							teamDispatch({ type: 'RESET' });
						}
					});
			}
			getCurrentTeam();
		} else {
			initialState.loading = true;
		}
	}, [userState?.isAuthenticated, teamState?.reload, userState?.reload]); // eslint-disable-next-line

	if (teamState?.loading) {
		return <Loading type='circle' />;
	}

	return <CurrentTeamContext.Provider value={value}>{props.children}</CurrentTeamContext.Provider>;
}

let CurrentTeamContextConsumer = CurrentTeamContext.Consumer;

export { CurrentTeamContext, CurrentTeamContextProvider, CurrentTeamContextConsumer };
