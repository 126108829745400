import React from 'react';
import { Link } from 'react-router-dom';
import { dateConverter, sliceString } from '../../helpers';

export default function MessageNotification(props) {
	const { notification, setRead, checkHref, deleteNotification } = props || {};
	const { notificationData } = notification;
	return (
		<>
			<div className='orderItemTopRow'>
				<Link to={`/orders/${notificationData.orderUuid}?openFocus=chat`}>
					<div
						className='orderItemText'
						onClick={() => {
							setRead(notification.notificationUuid);
							checkHref(`/orders/${notificationData.orderUuid}?openFocus=chat`);
						}}>
						<span className={`bold orderLink${notification.isRead ? '' : ' unread'}`}>
							{notificationData.messageSenderUuid === notificationData.sellerTeamUuid
								? `${notificationData.sellerUserRef} sent you a message.`
								: `${notificationData.buyerUserRef} sent you a message`}
						</span>
					</div>
				</Link>
				<div className='orderItemText'>
					<span className='bold'>{dateConverter(notification.createdAt)}</span>
				</div>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						deleteNotification(notification);
					}}>
					Delete
				</button>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						setRead(notification?.notificationUuid);
					}}>
					Set read
				</button>
			</div>
			<div className='orderItemCenterRow'>
				<div className='flexColumn-1'>{sliceString(notification.notificationData.messageText, 0, 100)}</div>
				<div className='flexColumn-2'></div>
				<div className='flexColumn-3'></div>
			</div>
			<div className='orderItemBottomRow'></div>
		</>
	);
}
