import EditorJS from '@editorjs/editorjs';
import { useEffect } from 'react';
import { EDITOR_JS_TOOLS } from '../global/Tutorial/EditorJsTools';
import { useParams } from 'react-router-dom';

export default function Editorials() {
	const params = useParams();

	async function getPage(name) {
		const importedPage = await import(`../global/Tutorial/editorialPages/${name}.json`);

		const editor = new EditorJS({
			holder: 'editorjs',
			readOnly: true,
			tools: EDITOR_JS_TOOLS,
			data: importedPage,
		});
	}

	useEffect(() => {
		let mounted = true;
		let instanceExists = document.getElementById('editorjs');
		if (instanceExists) {
			instanceExists.innerHTML = '';
		}
		if (mounted) {
			getPage(params.name);
		}
		return () => (mounted = false);
	}, []);
	return (
		<>
			<div id='editorjs'></div>
		</>
	);
}
