import React from 'react';

export default function Overlay(props) {
	const { loadOverlay, showOverlay, setShowOverlay } = props || {};
	return (
		<>
			{loadOverlay ? (
				<div
					className={showOverlay ? 'overlay fadeIn' : 'overlay fadeOut'}
					onClick={() => {
						setShowOverlay ? setShowOverlay(false) : null;
					}}
				/>
			) : (
				<></>
			)}
		</>
	);
}
