import React from 'react';
import { Link } from 'react-router-dom';

export default function OrderSuborders(props) {
	const { orderUuid, parentUuid, suborders, isSeller } = props || {};
	return (
		<>
			<div className='wideColumn'>
				<div className='flexRow'>
					<div className='flexColumn'>
						<h4>Order chain</h4>
						<p>
							Orders can be linked to parent or child orders to form a chain.
							<br />
							{isSeller && parentUuid ? (
								<span className='newLine'>{'ORDER IS ALREADY SUBORDER!\n'}</span>
							) : (
								''
							)}
							<a href=''>Learn more about Order Chains</a>.
						</p>
					</div>

					{isSeller && !parentUuid && (
						<div className='flexRow'>
							<button className='functionalButtonSecondaryDark marginRight-10'>Break from chain</button>
							<Link to={`/order?parentUuid=${orderUuid}`}>
								<button className='functionalButtonSecondaryDark'>Add suborder</button>
							</Link>
						</div>
					)}
				</div>
				{parentUuid && !isSeller && (
					<div>
						<h3>Parent order</h3>
						<Link to={`/orders/${parentUuid}`}>{parentUuid}</Link>
					</div>
				)}
				{suborders.length > 0 && isSeller && !parentUuid && (
					<div>
						<h3>Suborders</h3>
						{suborders.map((orderUuid, key) => {
							return (
								<div key={key}>
									<Link to={`/orders/${orderUuid}`}>{orderUuid}</Link>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
}
