import React from 'react';
import LearningZoneCarousel from './carouselTypes/LearningZoneCarousel';
import SupplierCarousel from './carouselTypes/SupplierCarousel';

export default function CarouselTypesSwitch(props) {
	const { type, carouselValues } = props || {};
	return (
		<>
			{(() => {
				switch (type) {
					case 'suppliers':
						return <SupplierCarousel values={carouselValues} />;
					case 'learningZone':
						return <LearningZoneCarousel values={carouselValues} />;
					case 'test':
						return <></>;
					default:
						return <></>;
				}
			})()}
		</>
	);
}
