import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { CurrentTeamContext } from '../../../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../../../contexts/CurrentUserContext';
import config from '../../../../config';
import { CartPlusIcon, ChatIcon, PhoneOutIcon } from '../../../../components/SVGIcons/SVGIcons';

export default function SupplierCarousel(props) {
	const { settings, currOffset, carouselWidth, setTotalWidth } = props.values || {};
	const [suppliers, setSuppliers] = useState([]);
	const [loaded, setLoaded] = useState(false);
	let { userState } = useContext(CurrentUserContext);
	let { teamState } = useContext(CurrentTeamContext);

	useEffect(() => {
		let mounted = true;
		if (userState.isAuthenticated && mounted) {
			async function fetchSuppliers() {
				try {
					const response = await Axios({
						url: `${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`,
						method: 'GET',
					});
					setSuppliers(response.data.data);
					setTotalWidth(-(response.data.data.length / 4) * settings.max.carouselWidth);
					setLoaded(true);
				} catch (err) {
					console.log(err);
				}
			}
			fetchSuppliers();
		}
		return () => (mounted = false);
	}, [settings, userState]);

	return (
		<div
			className='suppliersCont'
			style={{
				transform: `translateX(${currOffset + carouselWidth}px)`,
				transition: '0.6s ease-in-out',
				width: `${carouselWidth}`,
			}}>
			{loaded &&
				suppliers.map((supplier, key) => {
					if (supplier.isPublic && supplier.teamUuid !== teamState.currentTeam?.teamUuid) {
						return (
							<div
								className='supplierCont'
								key={key}
								onClick={() => {
									window.location.href = `/suppliers/${supplier?.teamUuid}?name=${supplier?.teamInfo?.teamName}`;
								}}>
								<div className='supplierHeader'></div>
								<div className='supplierBody'>
									<h3>{supplier?.teamInfo?.teamName}</h3>
									<p>{supplier?.teamInfo?.companyDescriptionHeader}</p>
								</div>
								<div className='supplierFooter'>
									<div className='iconCont disabledDiv'>
										<PhoneOutIcon iconClass='listingIcon blue' />
									</div>
									<div className='iconCont disabledDiv'>
										<ChatIcon iconClass='listingIcon blue' />
									</div>
									<button
										className='buttonDashboard2'
										onClick={(e) => {
											e.stopPropagation();
											window.location.href = `/order?uuid=${supplier?.teamUuid}&name=${supplier?.teamInfo?.teamName}`;
										}}>
										<CartPlusIcon />
										Place order
									</button>
								</div>
							</div>
						);
					} else {
						return <React.Fragment key={key}></React.Fragment>;
					}
				})}
		</div>
	);
}
