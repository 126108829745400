import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import Addresses from '../global/Addresses/Addresses';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';

export default function TeamAddresses() {
	const [address, setAddress] = useState({});
	const [chosenAddress, setChosenAddress] = useState(null);

	const { teamState } = useContext(CurrentTeamContext);

	const handleAddressInput = (e) => {
		const { name, value } = e.target;
		setAddress((address) => ({
			...address,
			[name]: value,
		}));
	};

	useEffect(() => {
		if (teamState) {
			const addresses = teamState?.currentTeam?.teamInfo?.addresses;
			if (addresses) {
				// Check if first entry is null or undefined
				if (addresses[0]) {
					for (let i = 0; i < addresses.length; i++) {
						if (addresses[i]?.isDefault) {
							setChosenAddress(addresses[i]);
						}
					}
					if (!chosenAddress) {
						setChosenAddress(addresses[0]);
					}
				}
			}
		}
	}, [chosenAddress, teamState]);

	return (
		<>
			<Addresses
				address={address}
				setAddress={setAddress}
				addresses={teamState?.currentTeam?.teamInfo?.addresses}
				chosenAddress={chosenAddress}
				setChosenAddress={setChosenAddress}
				handleAddressInput={handleAddressInput}
				layoutType={'addressManager'}
			/>
		</>
	);
}
