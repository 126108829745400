import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import config from '../../config';
import { ChatIcon, PhoneOutIcon, ShopIcon } from '../../components/SVGIcons/SVGIcons';

export default function TeamList() {
	const [suppliers, setSuppliers] = useState([]);
	const [sortingAlphabet, setSortingAlphabet] = useState('asc');
	const [loaded, setLoaded] = useState(false);

	async function handleSort(sortType, sortData) {
		if (sortType === 'date') {
			// setSortingDate(sortData);
			// fetchSuppliers(sortType, sortData);
		} else if (sortType === 'alphabet') {
			setSortingAlphabet(sortData);
		}
	}

	async function fetchSuppliers() {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.read?sort=${sortingAlphabet}`,
				method: 'GET',
			});
			if (response.data?.data) {
				setSuppliers(response.data.data);
			}
			setLoaded(true);
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchSuppliers();
		}
		return () => (mounted = false);
	}, [sortingAlphabet]);

	return (
		<div className='supplierListCont'>
			<div className='supplierList'>
				<div className='filterHeader'>
					<p>COMPANY NAME</p>
					<p>KEY PRODUCTS</p>
					{/* <p>Certified</p>
                    <p>Trusted</p>
                    <p>Awards</p>
                    <p>Rating</p> */}
				</div>
				{loaded && suppliers ? (
					suppliers.map((supplier, key) => {
						return (
							<div className='supplier' key={key}>
								<div className='bodyRow'>
									<Link className='linkText' to={supplier.teamUuid}>
										{supplier.teamInfo.teamName.length > 15
											? supplier.teamInitials
											: supplier.teamInfo.teamName}
									</Link>
									<p>
										{supplier.teamInfo?.companyDescription?.substr(0, 60)}
										{supplier.teamInfo?.companyDescription?.length > 60 && <span>...</span>}
									</p>
									<div className='iconRow'>
										<div className='iconCont disabledDiv'>
											<PhoneOutIcon iconClass='listingIcon blue' />
										</div>
										<div className='iconCont disabledDiv'>
											<ChatIcon iconClass='listingIcon blue' />
										</div>
										<Link
											to={`/suppliers/${supplier?.teamUuid}?name=${supplier?.teamInfo?.teamName}`}>
											<div className='iconCont'>
												<ShopIcon iconClass='listingIcon turq' />
											</div>
										</Link>
									</div>
								</div>
							</div>
						);
					})
				) : (
					<></>
				)}
			</div>
		</div>
	);
}
