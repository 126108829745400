import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { TutorialContext } from '../../../contexts/TutorialContext';

export default function DirectedTutorialModal(props) {
	const { page, order, header, body, isLast, position, size, flip } = props || {};
	const { tutorialState, tutorialDispatch } = useContext(TutorialContext);

	const selfRef = useRef(null);

	function handleNext() {
		if (isLast) {
			tutorialDispatch({
				type: 'SET_TUTORIAL_INACTIVE',
				payload: { page: page, data: { open: false, prompt: false } },
			});
		} else {
			tutorialDispatch({ type: 'SET_TUTORIAL_NEXT', payload: page });
		}
	}
	function handlePrev() {
		tutorialDispatch({ type: 'SET_TUTORIAL_PREV', payload: page });
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && selfRef?.current) {
			selfRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
		return () => (mounted = false);
	}, [tutorialState]);

	return (
		<>
			{tutorialState.tutorials[page] && (
				<>
					{tutorialState.tutorials[page].open && tutorialState.tutorials[page].curr === order && (
						<div
							className={`tutorialModal ${flip ? flip : 'flipRight'} ${position ? position : ''}`}
							ref={selfRef}
							onClick={(e) => {
								e.stopPropagation();
							}}>
							<h4>{header}</h4>
							<p>{body}</p>
							<div className='btnCont'>
								<button
									disabled={order === 1}
									className='btn2'
									onClick={(e) => {
										handlePrev();
										e.stopPropagation();
									}}>
									Prev
								</button>
								<button
									className='btn1'
									onClick={(e) => {
										handleNext();
										e.stopPropagation();
									}}>
									{isLast ? 'Close' : 'Next'}
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}
