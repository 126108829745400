import React, { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../../App';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import { splitUserId } from '../../../helpers';
import ProcessingModal from '../../../components/Modals/ProcessingModal';
import Overlay from '../../../components/Overlay/Overlay';
import config from '../../../config';
import Axios from 'axios';

export default function TrustedAddBtn(props) {
	const { supplierUuid, supplierName, style } = props;

	const [trustedSuggested, setTrustedSuggested] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [message, setMessage] = useState(false);

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);
	const socket = useContext(SocketContext);

	function handleModal(message, loading, open, isSuggested) {
		const second = 1000;
		setMessage(message);
		setLoading(loading);
		setModalOpen(open);
		setTrustedSuggested(isSuggested);
		if (isSuggested) {
			setTimeout(() => {
				setLoading(false);
			}, second);
		}
	}

	function checkSuggestedSupplier(teamUuid) {
		try {
			Axios({
				url: `${config.apiv1}/notification/notifications.read/${
					teamState.currentTeam.teamUuid
				}?limit=1&sortDate=desc&type=${'team.trustedSuggested'}&teamUuid=${teamUuid}`,
				method: 'GET',
			}).then((response) => {
				if (response?.data?.data) {
					setTrustedSuggested(true);
				}
				setLoaded(true);
			});
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	async function addTrusted(teamUuid) {
		if (teamState.userPermissions['canAddTrusted']) {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.create/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
					},
				},
			});
			if (!response?.data?.ok) {
				handleModal('Error adding supplier as trusted supplier', false, true, false);
			} else {
				if (response?.data?.data) {
					handleModal('Supplier successfully added as trusted supplier', true, true, true);
				} else {
					handleModal('Error adding supplier as trusted supplier', false, true, false);
				}
			}
		} else {
			socket.emit('suggest-trusted', {
				userUuid: splitUserId(userState.currUser.sub),
				name: userState.currUser.name,
				trustedSuggestedUuid: teamUuid,
				trustedSuggestedName: supplierName,
				recipientUuid: teamState.currentTeam.teamUuid,
			});
			handleModal('Suggestion successfully sent to Admin', true, true, true);
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			checkSuggestedSupplier(supplierUuid);
		}
		return () => (mounted = false);
	}, []);

	return (
		<>
			{loaded && (
				<>
					<ProcessingModal loading={loading} show={modalOpen} message={message} setShow={setModalOpen} />
					<Overlay loadOverlay={modalOpen} showOverlay={modalOpen} />
					{!teamState?.currentTeam?.trustedSuppliers &&
					supplierUuid !== teamState.currentTeam.teamUuid &&
					!trustedSuggested ? (
						<button
							className={style === 1 ? 'button1-secondary' : 'btn3-inverted'}
							onClick={() => {
								addTrusted(supplierUuid);
							}}>
							Add as trusted supplier
						</button>
					) : (
						<>
							{teamState?.currentTeam?.trustedSuppliers?.filter(
								(trustedSupplier) => trustedSupplier.teamUuid === supplierUuid
							).length === 0 &&
							supplierUuid !== teamState.currentTeam.teamUuid &&
							!trustedSuggested ? (
								<button
									className={style === 1 ? 'button1-secondary' : 'btn3-inverted'}
									onClick={() => {
										addTrusted(supplierUuid);
									}}>
									Add as trusted supplier
								</button>
							) : (
								<></>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}
