import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TeamDropdown from '../../../components/Dropdowns/TeamDropdown';
import BellDropdown from '../../../components/Dropdowns/BellDropdown';
import ProfileDropdown from '../../../components/Dropdowns/ProfileDropdown';

import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { LeftSidebarContext } from '../../../contexts/LeftSidebarContext';
import {
	CircleSolidIcon,
	TopnavBellIcon,
	TopnavMenuIcon,
	TopnavTeamCircleIcon,
	TopnavUserCircleIcon,
} from '../../../components/SVGIcons/SVGIcons';
import Axios from 'axios';
import config from '../../../config';
import DirectedTutorialModal from '../Tutorial/DirectedTutorialModal';

export default function Header() {
	const [openDropdown, setOpenDropdown] = useState('');
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	const [avatarUrl, setAvatarUrl] = useState(null);

	let { notificationsState } = useContext(NotificationContext);
	let { leftSidebarState, leftSidebarDispatch } = useContext(LeftSidebarContext);
	let { teamState } = useContext(CurrentTeamContext);
	let { userState, userDispatch } = useContext(CurrentUserContext);

	let location = useLocation();

	async function createAvatarUrl(file) {
		if (file) {
			Axios({
				url: `${config.auth}/file?path=${file.path}`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				setAvatarUrl(url);
			});
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			createAvatarUrl(userState.currUser.avatar);
		}
		return () => (mounted = false);
	}, []);

	function openSwitch() {
		leftSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		leftSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !leftSidebarState.sidebarOpen });
	}

	function handleOpenDropdown(dropdown) {
		if (openDropdown !== dropdown) {
			setOpenDropdown(dropdown);
		} else {
			setOpenDropdown('');
		}
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		return setOpenDropdown('');
	}, [location]);

	return (
		<div className='navbar'>
			<Link to={'/'}>
				<div className='logo'></div>
			</Link>
			{/* <div className="searchBar">
                <div className="searchDropdownBtn">
                <form>
                <select>
                <option value="">All</option>
                </select>
                </form>
                </div>
                <div className="searchIcon icon">
                <FontAwesomeIcon icon={["fas", getIcon("search")]} />
                </div>
                <input type="text" className="searchInput" />
            </div> */}
			<div className='navItems'>
				<div className='navItem'>
					<div className='navIcon'>
						<DirectedTutorialModal
							page='dashboard'
							order={1}
							flip='flipLeft'
							header='Menu'
							body="Click to reveal the main menu. In it you'll find everything you need whether you are buying or selling on Procuur."
						/>
						<TopnavMenuIcon iconClass='menuIcon' onClick={openSwitch} />
					</div>
				</div>
				{/* <div className="navItem">
                    <div onClick={() => { handleOpenDropdown("cartDropdown") }} className="navIcon">
                        <div className="cartIcon icon"></div>
                    </div>
                    {openDropdown === "cartDropdown" ? (
                        <CartDropdown />
                    ) : (
                        <></>
                    )
                    }
                </div>
                <div className="navItem">
                    <div onClick={() => { handleOpenDropdown("messagingDropdown") }} className="navIcon">
                        <div className="messagingIcon icon"></div>
                    </div>
                    {openDropdown === "messagingDropdown" ? (
                        <MessagingDropdown />
                    ) : (
                        <></>
                    )
                    }
                </div> */}
				{teamState.userTeams && (
					<>
						<div className={windowSize.width > 640 ? 'navItem' : 'hidden'}>
							<DirectedTutorialModal
								page='dashboard'
								order={2}
								flip='flipLeft'
								header='Notifications'
								body='When stuff happens we notify you here. Examples could be a delivery update or a message that you have received.'
							/>
							<div
								onClick={() => {
									handleOpenDropdown('bellDropdown');
								}}
								className='navIcon'>
								<TopnavBellIcon iconClass='bellIcon' />
								{notificationsState.unreadNotifications && (
									<CircleSolidIcon iconClass='unreadCircleIcon' />
								)}
							</div>
							{openDropdown === 'bellDropdown' ? <BellDropdown /> : <></>}
						</div>
						{teamState?.currentTeam?.teamInfo?.teamName ? (
							<div className='navItem'>
								<DirectedTutorialModal
									page='dashboard'
									order={3}
									flip='flipLeft'
									size='wide'
									header='Teams'
									body={
										<>
											A Team is a collection of individuals with shared purpose. Examples are a
											company, a corporate group, a department or an office. You can create a Team
											at any time and you can be a member of multiple teams. Here you can select
											the Team you want to be within for buying and selling.{' '}
											<a href='/manage'>Find out more about Teams</a> and how you can register
											one.
										</>
									}
								/>
								{teamState?.currentTeam?.teamIcon ? (
									<img
										onClick={() => {
											handleOpenDropdown('teamDropdown');
										}}
										src={teamState?.currentTeam?.teamIcon}
										alt='teamIcon'
									/>
								) : (
									<div
										onClick={() => {
											handleOpenDropdown('teamDropdown');
										}}
										className='teamIcon icon'>
										<p>{teamState?.currentTeam?.teamInitials?.toUpperCase()}</p>
									</div>
								)}
								{openDropdown === 'teamDropdown' ? <TeamDropdown /> : <></>}
							</div>
						) : (
							<div className='navItem'>
								<div
									onClick={() => {
										handleOpenDropdown('teamDropdown');
									}}
									className='defaultTeamIcon icon'>
									<TopnavTeamCircleIcon iconClass='defaultTeamIcon' />
								</div>
								{openDropdown === 'teamDropdown' ? <TeamDropdown /> : <></>}
							</div>
						)}
					</>
				)}
				{userState.isAuthenticated && (
					<div className='navItem'>
						<DirectedTutorialModal
							page='dashboard'
							order={4}
							flip='flipLeft'
							size='wide'
							header='Personas'
							body={
								<>
									You may have multiple email addresses. You can register each one with us and switch
									between them. You maybe in different Teams for each email address used.{' '}
									<a href='/manage'>Find out more about Personas</a> and how to add one.
								</>
							}
						/>
						{avatarUrl ? (
							<img onClick={() => handleOpenDropdown('profileDropdown')} src={avatarUrl} alt='avatar' />
						) : (
							<TopnavUserCircleIcon
								iconClass='userIcon'
								onClick={() => handleOpenDropdown('profileDropdown')}
							/>
						)}
						{openDropdown === 'profileDropdown' ? <ProfileDropdown /> : <></>}
					</div>
				)}
				{!userState.isAuthenticated && (
					<>
						<div className='navItem'>
							<button onClick={() => userDispatch({ type: 'LOGIN' })}>Login</button>
						</div>
						<div className='navLink'>
							<Link to={'/register'}>Register</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
