import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

import './styles/main.css';
import './styles/carousel.css';
import './styles/dashboard.css';
import './styles/form.css';
import './styles/grid.css';
import './styles/icon.css';
import './styles/messaging.css';
import './styles/orders.css';
import './styles/products.css';
import './styles/sidebar.css';
import './styles/team.css';

import { Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import SidebarLeft from './containers/global/SidebarLeft/SidebarLeft';
import Navbar from './containers/global/NavigationTop/Header';
import SidebarRight from './containers/global/SidebarRight/SidebarRight';

import config from './config';
import { splitUserId } from './helpers';

// import ProductsPage from './pages/ProductsPage';
// import ProductPage from './pages/ProductPage';

import Dashboard from './containers/Dashboard/Dashboard';
import Breadcrumbs from './containers/global/NavigationTop/Breadcrumb';
import InviteTeam from './containers/InviteTeam/InviteTeam';
import Order from './containers/Order/Order';
import OrderList from './containers/OrderList/OrderList';
import OrderCreate from './containers/OrderCreate/OrderCreate';
import OrderConfirm from './containers/OrderConfirm/OrderConfirm';

import TeamManageGrid from './containers/TeamManageGrid/TeamManageGrid';
import TeamApps from './containers/TeamApps/TeamApps';
import TeamAppDetails from './containers/TeamApps/TeamAppDetails';
import TeamMembers from './containers/TeamMembers/TeamMembers';
import TeamAddresses from './containers/TeamAddresses/TeamAddresses';
import TeamEdit from './containers/TeamEdit/TeamEdit';
import TeamRegister from './containers/TeamRegister/TeamRegister';
import TeamTrusted from './containers/TeamTrusted/TeamTrusted';
import TeamProfile from './containers/TeamProfile/TeamProfile';
import TeamList from './containers/TeamList/TeamList';
import MemberConfirm from './containers/MemberConfirm/MemberConfirm';

import TutorialsPage from './containers/Editorials/Editorials';
import Topbar from './containers/global/NavigationTop/Topbar';
import Notifications from './containers/Notifications/Notifications';

import Error from './components/Error/Error';

import { CurrentUserContext } from './contexts/CurrentUserContext';
import { CurrentTeamContext } from './contexts/CurrentTeamContext';
import { NotificationContextProvider } from './contexts/NotificationContext';

export const LoadedContext = React.createContext();
export const SocketContext = React.createContext();

export default function App() {
	const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
	const [show, setShow] = useState(false);
	const [socket, setSocket] = useState(null);

	let location = useLocation();

	let { userState, userDispatch } = useContext(CurrentUserContext);
	let { teamState } = useContext(CurrentTeamContext);

	function openLeftSwitch() {
		if (!show) {
			setShow(true);
		}
		setLeftSidebarOpen(!leftSidebarOpen);
	}

	const ProtectedRoutes = ({ permission }) => {
		if (!teamState.userPermissions[permission]) {
			return <Navigate to='/' replace />;
		}
		return <Outlet />;
	};

	useEffect(() => {
		// Console message for peekers
		console.log(
			`%cIf you're here because something broke... it wasn't me!\n\nYours sincerely, \nRobin Neuman\n`,
			`font-family: 'Proza Libre', sans-serif; font-weight: bold; font-size: 30px;color: rgb(212, 70, 23);`
		);
	}, []);

	useEffect(() => {
		if (socket === null) {
			setSocket(
				io.connect(config.api, {
					query: `userUuid=${splitUserId(userState.currUser?.sub)}&teamUuid=${
						teamState.currentTeam.teamUuid
					}`,
				})
			);
		}
	}, [socket, teamState.currentTeam.teamUuid, userState.currUser.sub]);

	useEffect(() => {
		// Cleans up threejs, will find way to do this inside threejs module
		let elem = document.querySelectorAll('.threeJsModel');
		for (let i = 0; i < elem.length; i++) {
			elem[i].remove();
		}
	}, [location]);

	return (
		<SocketContext.Provider value={socket}>
			<NotificationContextProvider>
				<div className='pageContainer'>
					<SidebarLeft show={show} open={leftSidebarOpen} leftSwitch={openLeftSwitch} />
					<div className='centerContainer'>
						<Topbar />
						<Navbar leftSwitch={openLeftSwitch} />
						<Breadcrumbs />
						<Routes>
							{!userState.isAuthenticated && userDispatch({ type: 'LOGIN' })}
							<Route exact path='/' element={<Dashboard />} />
							<Route exact path='/tutorials/:lang/:name' element={<TutorialsPage />} />
							<Route exact path='/invite' element={<InviteTeam />} />
							<Route exact path='/notifications' element={<Notifications />} />
							<Route exact path='/order' element={<OrderCreate />} />
							<Route exact path='/orders' element={<OrderList />} />
							<Route exact path='/orders/:orderId' element={<Order />} />
							<Route exact path='/confirmOrderReceived' element={<OrderConfirm />} />
							<Route exact path='/confirmMember' element={<MemberConfirm />} />
							<Route path='/manage' element={<ProtectedRoutes permission='isAdmin'></ProtectedRoutes>}>
								<Route exact path='/manage' element={<TeamManageGrid />} />
								<Route exact path='/manage/apps' element={<TeamApps />} />
								<Route exact path='/manage/apps/:app' element={<TeamAppDetails />} />
								<Route exact path='/manage/team' element={<TeamEdit />} />
								<Route exact path='/manage/addresses' element={<TeamAddresses />} />
								<Route exact path='/manage/members' element={<TeamMembers />} />
								<Route exact path='/manage/trusted' element={<TeamTrusted />} />
							</Route>
							<Route exact path='/teamRegistration' element={<TeamRegister />} />
							<Route exact path='/suppliers' element={<TeamList />} />
							<Route exact path='/suppliers/:id' element={<TeamProfile />} />
							{/* <Route exact path='/suppliers/:id/products' element={<ProductsPage />} />
							<Route exact path='/suppliers/:id/products/:productId' element={<ProductPage />} /> */}
							<Route exact path='/error' element={<Error />} />
							<Route element={() => <Redirect to={{ pathname: '/' }} />} />
						</Routes>
					</div>
					<SidebarRight />
				</div>
			</NotificationContextProvider>
		</SocketContext.Provider>
	);
}
