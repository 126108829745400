import React from 'react';
import LearningZoneHeader from './carouselHeaders/LearningZoneHeader';
import SupplierHeader from './carouselHeaders/SupplierHeader';

export default function CarouselHeadersSwitch(props) {
	const { type } = props || {};
	return (
		<>
			{(() => {
				switch (type) {
					case 'suppliers':
						return <SupplierHeader />;
					case 'learningZone':
						return <LearningZoneHeader />;
					case 'test':
						return <></>;
					default:
						return <></>;
				}
			})()}
		</>
	);
}
