import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { LeftSidebarContext } from '../../../contexts/LeftSidebarContext';
import SidebarLinks from './SidebarLinks';
import Overlay from '../../../components/Overlay/Overlay';
import { TopnavCloseIcon } from '../../../components/SVGIcons/SVGIcons';

export default function SidebarLeft() {
	const { leftSidebarState, leftSidebarDispatch } = useContext(LeftSidebarContext);
	let location = useLocation();

	function openSwitch() {
		leftSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !leftSidebarState.sidebarOpen });
	}

	function openTextbox(boxRef) {
		leftSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: {
				boxRef: boxRef,
				boxOpen: leftSidebarState.textboxesOpen[boxRef]
					? leftSidebarState.textboxesOpen[boxRef]?.boxOpen
					: true,
			},
		});
	}

	useEffect(() => {
		if (leftSidebarState.show) {
			leftSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
		}
	}, [location]); // eslint-disable-next-line

	return (
		<div className='leftSidebar'>
			<Overlay
				loadOverlay={leftSidebarState.show}
				showOverlay={leftSidebarState.sidebarOpen}
				setShowOverlay={openSwitch}
			/>
			{leftSidebarState.show ? (
				<div
					className={
						leftSidebarState.sidebarOpen ? 'leftSidebarList slideInLeft' : 'leftSidebarList slideOutLeft'
					}>
					<div className='leftSidebarTopRow'>
						<TopnavCloseIcon iconClass='closeIcon' onClick={openSwitch} />
						<div className='logo'></div>
					</div>
					<SidebarLinks textboxesOpen={leftSidebarState.textboxesOpen} openTextbox={openTextbox} />
				</div>
			) : (
				<div />
			)}
		</div>
	);
}
