let orderStatusOptions = [
	<option value='1'>Draft</option>,
	<option value='2'>Received</option>,
	<option value='3'>Processing</option>,
	<option value='4'>Awaiting dispatch</option>,
	<option value='5'>Dispatched</option>,
	<option value='6'>Delivered</option>,
	<option value='7'>Disputed</option>,
	<option value='8'>Void</option>,
	<option value='9'>Rejected</option>,
];

export default orderStatusOptions;
