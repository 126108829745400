import Axios from 'axios';
import React, { useContext } from 'react';
import config from '../../../config';
import AddressForm from '../../../components/Forms/AddressForm';
import { v4 as uuidv4 } from 'uuid';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import { useState } from 'react';
import Overlay from '../../../components/Overlay/Overlay';
import AddressEditModal from '../../../components/Modals/AddressEditModal';
import AddressNewModal from '../../../components/Modals/AddressNewModal';

export default function Addresses(props) {
	const {
		address,
		setAddress,
		addresses,
		chosenAddress,
		setChosenAddress,
		handleAddressInput,
		scrollToBottom,
		layoutType,
	} = props || {};

	const [errorMessage, setErrorMessage] = useState('');
	const [loadOverlay, setLoadOverlay] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showAddressOptions, setShowAddressOptions] = useState(false);
	const [newAddressFormOpen, setNewAddressFormOpen] = useState(false);
	const [updatedAddress, setUpdatedAddress] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);

	let { teamState, teamDispatch } = useContext(CurrentTeamContext);

	const handleAddressUpdateInput = (e) => {
		const { name, value } = e.target;
		setUpdatedAddress((address) => ({
			...address,
			[name]: value,
		}));
	};

	function postNewAddress() {
		try {
			let newAddress = {
				addressUuid: uuidv4(),
				isDefault: false,
				addressType: 'deliveryAddress',
				name: address.name,
				street: address.street,
				city: address.city,
				postalCode: address.postalCode,
				country: address.country,
			};
			Axios({
				url: `${config.apiv1}/team/team.update`,
				method: 'PATCH',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
					deliveryAddress: newAddress,
				},
			}).then((res) => {
				if (res?.data?.error) {
					setErrorMessage('Error when creating team');
				} else {
					if (res?.data?.ok) {
						teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res.data.data.teamInfo.addresses });
						setChosenAddress(newAddress);
						setAddress({});
						setNewAddressFormOpen(false);
						setShowAddressOptions(false);
						setShowEdit(false);
						setLoadOverlay(false);
						setUpdateLoading(false);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	function patchAddress(updatedAddress, param, addressUuid) {
		try {
			let reqData;
			if (param === 'default') {
				reqData = {
					teamUuid: teamState.currentTeam.teamUuid,
					updatedAddress: { isDefault: true },
				};
			} else {
				reqData = {
					teamUuid: teamState.currentTeam.teamUuid,
					updatedAddress: updatedAddress,
				};
			}
			Axios({
				url: `${config.apiv1}/team/team.update/address/${addressUuid || updatedAddress.addressUuid}`,
				method: 'PATCH',
				data: reqData,
			}).then((res) => {
				if (res.data?.error) {
					setErrorMessage('Error when updating address');
				} else {
					if (res?.data?.ok) {
						teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res.data.data.teamInfo.addresses });
						setChosenAddress(updatedAddress);
						setNewAddressFormOpen(false);
						setShowAddressOptions(false);
						setShowEdit(false);
						setLoadOverlay(false);
						setUpdateLoading(false);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	function deleteAddress(addressUuid) {
		try {
			Axios({
				url: `${config.apiv1}/team/team.delete/address/${addressUuid}`,
				method: 'PATCH',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
				},
			}).then((res) => {
				if (res.data?.error) {
					setErrorMessage('Error when deleting address');
				} else {
					if (res?.data?.ok) {
						teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res.data.data.teamInfo.addresses });
						setNewAddressFormOpen(false);
						setShowAddressOptions(false);
						setShowEdit(false);
						setLoadOverlay(false);
						setUpdateLoading(false);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	function scroll() {
		if (typeof scrollToBottom === 'function') {
			scrollToBottom();
		}
	}

	function showOverlaySetter(bool) {
		setShowEdit(bool);
		setNewAddressFormOpen(bool);
	}

	return (
		<>
			<Overlay
				loadOverlay={loadOverlay}
				showOverlay={showEdit || newAddressFormOpen}
				setShowOverlay={showOverlaySetter}
			/>
			<h3 className='error'>{errorMessage}</h3>
			{layoutType === 'addressPicker' ? (
				<>
					{!newAddressFormOpen && !showEdit ? (
						<>
							{showAddressOptions && !showEdit ? (
								<>
									<div className='addressesContainer'>
										{addresses.map((address, key) => {
											return (
												<div className='formRowCenter' key={key}>
													<label className='radioLabel'>
														<input
															type='radio'
															name='deliveryAddress'
															checked={
																chosenAddress?.addressUuid === address?.addressUuid
															}
															onChange={() => {
																setChosenAddress(address);
															}}
														/>
														<div className='customRadio'></div>
														<span>
															{`${address?.name}, 
                                                            ${address?.street}, 
                                                            ${address?.city}, 
                                                            ${address?.postalCode}, 
                                                            ${address?.country} 
                                                            ${
																address?.addressType === 'registeredAddress'
																	? '(Registered address)'
																	: '(Delivery address)'
															}`}
														</span>
														<button
															className='functionalButtonSecondary'
															onClick={() => {
																setShowEdit(true);
																setUpdatedAddress(address);
															}}>
															Edit
														</button>
													</label>
												</div>
											);
										})}
										{showEdit && (
											<div className='editAddressBox'>
												<AddressForm
													address={address}
													handleAddressInput={handleAddressInput}
													required={false}
												/>
											</div>
										)}
									</div>
									<div>
										<input
											type='button'
											className='functionalButtonPrimary'
											onClick={() => {
												setShowAddressOptions(false);
											}}
											defaultValue='Save'
										/>
									</div>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(true);
											}}
											defaultValue='Add new'
										/>
									</div>
								</>
							) : (
								<>
									<div className='addressDisplay'>
										{`${chosenAddress?.name}, ${chosenAddress?.street}, ${chosenAddress?.city}, ${chosenAddress?.postalCode}, ${chosenAddress?.country}`}
									</div>
									<input
										type='button'
										className='functionalButtonSecondary'
										onClick={() => {
											setShowAddressOptions(true);
											scroll();
										}}
										defaultValue='Change'
									/>
								</>
							)}
						</>
					) : (
						<>
							{!showEdit ? (
								<>
									<AddressForm handleAddressInput={handleAddressInput} required={false} />
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonPrimary'
											onClick={() => {
												postNewAddress();
											}}
											defaultValue='Save new address'
										/>
									</div>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(false);
												setShowEdit(false);
											}}
											defaultValue='Cancel'
										/>
									</div>
								</>
							) : (
								<>
									<AddressForm
										address={updatedAddress}
										handleAddressInput={handleAddressUpdateInput}
										required={false}
									/>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonPrimary'
											onClick={() => {
												patchAddress(updatedAddress);
											}}
											defaultValue='Update address'
										/>
									</div>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(false);
												setShowEdit(false);
											}}
											defaultValue='Cancel'
										/>
									</div>
								</>
							)}
						</>
					)}
				</>
			) : (
				<div className='addressManagementContainer'>
					{addresses.map((address, key) => {
						return (
							<div className='addressItem' key={key}>
								<h4>{`${address?.name}, ${address?.street}, ${address?.city}, ${address?.postalCode}, ${
									address?.country
								} ${address?.isDefault ? '(Default)' : ''}`}</h4>
								<h4>{`Type: ${
									address?.addressType === 'registeredAddress'
										? 'Registered address'
										: 'Delivery address'
								}`}</h4>
								<div className='addressButtons'>
									<button
										className='functionalButtonSecondary'
										onClick={() => {
											setShowEdit(true);
											setLoadOverlay(true);
											setUpdatedAddress(address);
										}}>
										Edit
									</button>
									{address?.addressType !== 'registeredAddress' && (
										<>
											<button
												className='functionalButtonSecondary'
												onClick={() => {
													deleteAddress(address.addressUuid);
												}}>
												Delete
											</button>
										</>
									)}
									{!address.isDefault && (
										<>
											<button
												className='functionalButtonSecondary'
												onClick={() => {
													patchAddress(null, 'default', address.addressUuid);
												}}>
												Make default
											</button>
										</>
									)}
								</div>
							</div>
						);
					})}
					<div
						className='addAddress'
						onClick={() => {
							setNewAddressFormOpen(true);
							setLoadOverlay(true);
						}}>
						<h3>Add address</h3>
					</div>
					{showEdit && (
						<AddressEditModal
							handleAddressUpdateInput={handleAddressUpdateInput}
							patchAddress={patchAddress}
							setNewAddressFormOpen={setNewAddressFormOpen}
							setShowEdit={setShowEdit}
							setUpdateLoading={setUpdateLoading}
							updateLoading={updateLoading}
							updatedAddress={updatedAddress}
						/>
					)}
					{newAddressFormOpen && (
						<AddressNewModal
							postNewAddress={postNewAddress}
							setNewAddressFormOpen={setNewAddressFormOpen}
							setShowEdit={setShowEdit}
							setUpdateLoading={setUpdateLoading}
							handleAddressInput={handleAddressInput}
							updateLoading={updateLoading}
							address={address}
						/>
					)}
				</div>
			)}
		</>
	);
}
