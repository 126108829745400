import React, { useContext, useEffect, useState } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import config from '../../config';
import Axios from 'axios';
import Overlay from '../../components/Overlay/Overlay';
import TrustedEditModal from '../../components/Modals/TrustedEditModal';
import ProcessingModal from '../../components/Modals/ProcessingModal';

export default function TeamTrusted() {
	const [suppliers, setSuppliers] = useState([]);
	const [trustedSuggested, setTrustedSuggested] = useState([]);
	const [preferences, setPreferences] = useState({});
	const [showEdit, setShowEdit] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [loadOverlay, setLoadOverlay] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [supplierUuid, setSupplierUuid] = useState(null);
	const [messageSuggested, setMessageSuggested] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const { userState } = useContext(CurrentUserContext);
	const { teamState, teamDispatch } = useContext(CurrentTeamContext);

	function handleOverlay() {
		if (!loadOverlay) {
			setLoadOverlay(true);
		}
	}

	function getSupplierPreferences(teamUuid) {
		for (let i = 0; i < teamState.currentTeam.trustedSuppliers.length; i++) {
			if (teamState.currentTeam.trustedSuppliers[i].teamUuid === teamUuid) {
				return teamState.currentTeam.trustedSuppliers[i].preferences || {};
			}
		}
	}

	function handlePreference(name, value) {
		setPreferences((preferences) => ({
			...preferences,
			[name]: value,
		}));
	}

	async function patchTrusted(teamUuid, preferences) {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.update/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
						preferences: preferences,
					},
				},
			});
			if (response.data.ok) {
				teamDispatch({ type: 'RELOAD' });
				setUpdateLoading(false);
				setShowEdit(false);
			} else {
				console.log(response);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function deleteTrusted(teamUuid) {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.delete/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
					},
				},
			});
			console.log(response);
			if (response.data.ok) {
				window.location.reload();
			} else {
				console.log(response);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function fetchSuggestedSuppliers() {
		try {
			Axios({
				url: `${config.apiv1}/notification/notifications.read/${
					teamState.currentTeam.teamUuid
				}?limit=&sortDate=desc&type=${'team.trustedSuggested'}`,
				method: 'GET',
			}).then((response) => {
				console.log(response);
				if (response?.data?.data) {
					setTrustedSuggested(response.data.data);
				}
			});
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	async function addTrusted(teamUuid) {
		if (teamState.userPermissions['canAddTrusted']) {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.create/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
					},
				},
			});
			if (!response?.data?.ok) {
				console.log(response?.data?.error);
			} else {
				if (response?.data?.data) {
					setUpdateLoading(false);
					setMessageSuggested('Successfully added trusted supplier');
				} else {
					console.log(response);
				}
			}
		} else {
			console.log(reponseAdd);
		}
	}

	async function patchSuggested(notification, bool) {
		if (notification) {
			try {
				const response = await Axios({
					url: `${config.apiv1}/notification/notification.update/${notification.notificationUuid}`,
					method: 'PATCH',
					data: {
						notificationData: {
							teamUuid: notification.notificationData.teamUuid,
							teamName: notification.notificationData.teamName,
							suggestedBy: notification.notificationData.suggestedBy,
							isAccepted: bool,
						},
					},
				});
				if (response?.data?.ok) {
					setUpdateLoading(true);
					setModalOpen(true);
					addTrusted(notification.notificationData.teamUuid);
				} else {
					console.log(response);
				}
			} catch (err) {
				console.log(err);
			}
		} else {
			console.error('Notification not set');
		}
	}

	useEffect(() => {
		let mounted = true;
		if (userState.isAuthenticated && !updateLoading && mounted) {
			async function fetchSuppliers() {
				try {
					const response = await Axios({
						url: `${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`,
						method: 'GET',
					});
					if (response?.data?.data?.length > 0) {
						setSuppliers(response?.data?.data);
					}
					setLoaded(true);
				} catch (err) {
					setLoaded(true);
					console.log(err);
				}
			}
			fetchSuggestedSuppliers();
			fetchSuppliers();
		}
		return () => (mounted = false);
	}, [userState, updateLoading]);

	return (
		<div className='manageTrustedCont'>
			{loaded && (
				<>
					<div className='trustedSuppliersCont'>
						<ProcessingModal
							loading={updateLoading}
							show={modalOpen}
							message={messageSuggested}
							setShow={setModalOpen}
						/>
						<Overlay loadOverlay={loadOverlay || modalOpen} showOverlay={showEdit || modalOpen} />
						<h3>Your trusted suppliers</h3>
						<div className='supplierList'>
							{suppliers.length > 0 ? (
								suppliers.map((supplier, key) => {
									return (
										<div className='supplier' key={key}>
											<h4>{supplier?.teamInfo?.teamName}</h4>
											<div className='btnCont'>
												<button
													className='functionalButtonPrimary'
													onClick={() => {
														setShowEdit(true);
														setPreferences(getSupplierPreferences(supplier.teamUuid));
														setSupplierUuid(supplier.teamUuid);
														handleOverlay();
													}}>
													Edit preferences
												</button>
												<button
													className='functionalButtonSecondary'
													onClick={() => {
														deleteTrusted(supplier.teamUuid);
													}}>
													Remove
												</button>
											</div>
										</div>
									);
								})
							) : (
								<h4>You have no trusted suppliers</h4>
							)}
						</div>
					</div>
					<div className='trustedSuppliersCont'>
						<h3>Suggested suppliers</h3>
						<div className='suggestedList'>
							{trustedSuggested?.length > 0 ? (
								trustedSuggested.map((suggested, key) => {
									return (
										<div className='supplier' key={key}>
											<h4>{suggested?.notificationData?.teamName}</h4>
											{suggested.notificationData.isAccepted === 'true' && (
												<>
													Status: <h4 className='success'>Accepted</h4>
												</>
											)}
											{suggested.notificationData.isAccepted === 'false' && (
												<>
													Status: <h4 className='error'>Declined</h4>
												</>
											)}
											{suggested.notificationData.isAccepted === 'pending' && (
												<div className='btnCont'>
													<button
														className='functionalButtonSecondary'
														onClick={() => {
															patchSuggested(suggested, 'true');
														}}>
														Accept
													</button>
													<button
														className='functionalButtonSecondary'
														onClick={() => {
															patchSuggested(suggested, 'false');
														}}>
														Decline
													</button>
												</div>
											)}
										</div>
									);
								})
							) : (
								<h4>You have no suggested trusted suppliers</h4>
							)}
						</div>
					</div>
					{showEdit && (
						<TrustedEditModal
							preferences={preferences}
							updateLoading={updateLoading}
							supplierUuid={supplierUuid}
							handlePreference={handlePreference}
							postForm={patchTrusted}
							setShowEdit={setShowEdit}
							setUpdateLoading={setUpdateLoading}
							setPreferences={setPreferences}
						/>
					)}
				</>
			)}
		</div>
	);
}
