import React, { useContext } from 'react';
import { SocketContext } from '../../../App';
import ChatBox from './functionalComponents/ChatBox';

export default function SidebarOrder(props) {
	const socket = useContext(SocketContext);
	const { contentReference, textboxesOpen, openTextbox } = props || {};

	return (
		<>
			<ChatBox
				socket={socket}
				contentReference={contentReference}
				textboxesOpen={textboxesOpen}
				openTextbox={openTextbox}
			/>
		</>
	);
}
