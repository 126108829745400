import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { ManageTeamIcon, TopnavTeamCircleIcon, TopnavUserSwitchIcon } from '../SVGIcons/SVGIcons';

export default function TeamDropdown(props) {
	let { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const [switchOpen, setSwitchOpen] = useState(false);

	function setTeam(team) {
		teamDispatch({ type: 'SET_TEAM', payload: team.teamUuid });
	}

	return (
		<div className='dropdown'>
			{teamState.userPermissions['isAdmin'] && (
				<div className='navSection'>
					<Link to='/manage'>
						<ManageTeamIcon iconClass='manageTeamIcon' />
						<p>Manage</p>
					</Link>
				</div>
			)}
			<div
				className='navSection'
				onClick={() => {
					setSwitchOpen(!switchOpen);
				}}>
				<TopnavUserSwitchIcon iconClass='manageTeamIcon' />
				<p>Switch</p>
			</div>
			{switchOpen && userState.isAuthenticated && (
				<>
					<h4>Switch Team</h4>
					{teamState.userTeams.map((team, key) => {
						return (
							<div
								key={key}
								className='teamSwitchItem'
								onClick={() => {
									setTeam(team);
								}}>
								{team.teamInfo?.teamName ? (
									<>
										{team.teamIcon ? (
											<img src={team.teamIcon} alt='teamIcon' />
										) : (
											<div className='teamChoicesIcon marginRight-10'>
												{team.teamInitials.toUpperCase()}
											</div>
										)}
										<p>{team.teamInfo?.teamName}</p>
									</>
								) : (
									<>
										<TopnavTeamCircleIcon iconClass='icon marginRight-10' />
										<p
											onClick={() => {
												setTeam(team);
											}}>
											Personal
										</p>
									</>
								)}
							</div>
						);
					})}
				</>
			)}
		</div>
	);
}
