import React from 'react';
import Invite from './Invite';
import InvitesList from './InvitesList';
import MembersList from './MembersList';

export default function TeamMembers() {
	return (
		<div className='memberManagementContainer'>
			<Invite header={'Invite new members to your team'} />
			<MembersList />
			<InvitesList />
		</div>
	);
}
