import React from 'react';
import PermissionsForm from '../Forms/PermissionsForm';
import Loading from '../Loading/Loading';

export default function MemberEditModal(props) {
	const {
		handlePermissionsInput,
		setShowEdit,
		setUpdateLoading,
		patchMember,
		setRole,
		setPermissions,
		role,
		permissions,
		updateLoading,
		member,
	} = props || {};

	return (
		<div className='wideModal'>
			<PermissionsForm
				role={role}
				permissions={permissions}
				setRole={setRole}
				setPermissions={setPermissions}
				handlePermissionsInput={handlePermissionsInput}
				required={true}
			/>
			{!updateLoading ? (
				<div className='addressButtons'>
					<div>
						<input
							type='button'
							className='functionalButtonPrimary'
							onClick={() => {
								patchMember(member, permissions, role);
								setUpdateLoading(true);
							}}
							defaultValue='Update permissions'
						/>
					</div>
					<div className='marginTop-10'>
						<input
							type='button'
							className='functionalButtonSecondary'
							onClick={() => {
								setShowEdit(false);
								setRole(null);
								setPermissions(null);
							}}
							defaultValue='Cancel'
						/>
					</div>
				</div>
			) : (
				<>
					<Loading type='circle' />
				</>
			)}
		</div>
	);
}
