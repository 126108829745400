import React from 'react';

export default function TrustedForm(props) {
	const { preferences, handlePreference } = props || {};
	return (
		<div className='mainFormCont'>
			<h2>Order preferences</h2>
			<h4>(choices will be pre-set for all orders towards supplier)</h4>
			<div className='formRowTop'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='estimateAccept'>
						Accept estimate automatically
					</p>
				</div>
				<div className='formChoices'>
					<label className='radioLabel'>
						<input
							type='radio'
							name='acceptEstimate'
							checked={preferences?.acceptEstimate || false}
							onChange={(e) => handlePreference(e.target.name, true)}
							required
						/>
						<div className='customRadio'></div>
						<span>Yes</span>
					</label>
					<label className='radioLabel'>
						<input
							type='radio'
							name='acceptEstimate'
							checked={preferences?.acceptEstimate === false || false}
							onChange={(e) => handlePreference(e.target.name, false)}
							required
						/>
						<div className='customRadio'></div>
						<span>No, authorization required</span>
					</label>
				</div>
			</div>
			<div className='formRowTop'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='pricingAccept'>
						Will you automatically accept changes to pricing throughout the manufacturing process?
					</p>
				</div>
				<div className='formChoices'>
					<label className='radioLabel'>
						<input
							type='radio'
							name='acceptPricing'
							checked={preferences?.acceptPricing || false}
							onChange={(e) => handlePreference(e.target.name, true)}
							required
						/>
						<div className='customRadio'></div>
						<span>Yes</span>
					</label>
					<label className='radioLabel'>
						<input
							type='radio'
							name='acceptPricing'
							checked={preferences?.acceptPricing === false || false}
							onChange={(e) => handlePreference(e.target.name, false)}
							required
						/>
						<div className='customRadio'></div>
						<span>No, each pricing change needs approval</span>
					</label>
				</div>
			</div>
		</div>
	);
}
