import React, { useState } from 'react';
import MemberNotification from './MemberNotification';
import MessageNotification from './MessageNotification';
import OrderNotification from './OrderNotification';

export default function NotificationSwitch(props) {
	const { notification, setRead, checkHref, deleteNotification } = props || {};

	const [isFading, setIsFading] = useState(false);

	const handleDelete = (notification, cb) => {
		setIsFading(true);
		deleteNotification(notification, cb);
	};

	return (
		<div className={`orderItemContainer${isFading ? ' fadeOut' : ''}`}>
			{(() => {
				switch (notification.notificationType) {
					case 'message.created':
						return (
							<>
								<MessageNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</>
						);
					case 'order.created':
						return (
							<>
								<OrderNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</>
						);
					case 'order.updated':
						return (
							<>
								<OrderNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</>
						);
					case 'order.priceSuggested':
						return (
							<>
								<OrderNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</>
						);
					case 'member.added':
						return (
							<>
								<MemberNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</>
						);
					default:
						return <></>;
				}
			})()}
		</div>
	);
}
