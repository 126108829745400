import React from 'react';
import { Link } from 'react-router-dom';
import { dateConverter } from '../../helpers';

export default function OrderNotification(props) {
	const { notification, setRead, checkHref, deleteNotification } = props || {};
	const { notificationData } = notification;

	return (
		<>
			<div className='orderItemTopRow'>
				<Link to={`/orders/${notification?.notificationData?.orderUuid}`}>
					<div
						className='orderItemText'
						onClick={() => {
							setRead(notification?.notificationUuid);
							checkHref(`/orders/${notificationData.orderUuid}`);
						}}>
						<span className={`bold orderLink${notification.isRead ? '' : ' unread'}`}>{`New order`}</span>
					</div>
				</Link>
				<div className='orderItemText'>
					<span className='bold'>{dateConverter(notification.createdAt)}</span>
				</div>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						deleteNotification(notification);
					}}>
					Delete
				</button>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						setRead(notification?.notificationUuid);
					}}>
					Set read
				</button>
			</div>
			<div className='orderItemCenterRow'>
				<div className='flexColumn-1'>
					{`New order '${notificationData.orderRef}' from ${notification.senderTeamName}`}
				</div>
				<div className='flexColumn-2'></div>
				<div className='flexColumn-3'></div>
			</div>
			<div className='orderItemBottomRow'></div>
		</>
	);
}
