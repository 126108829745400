import Axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import { MultiSignOutIcon, PlusIcon, PlusIcons, SignOutIcon } from '../SVGIcons/SVGIcons';

export default function ProfileDropdown() {
	let { userState, userDispatch } = useContext(CurrentUserContext);
	let { teamDispatch } = useContext(CurrentTeamContext);

	const [avatarUrls, setAvatarUrls] = useState({});

	async function createAvatarUrl(file, uuid) {
		if (file) {
			Axios({
				url: `${config.auth}/file?path=${file.path}`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				setAvatarUrls({
					...avatarUrls,
					[uuid]: url,
				});
			});
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			for (let user of userState.users) {
				createAvatarUrl(user.avatar, splitUserId(user.sub));
			}
		}
		return () => (mounted = false);
	}, []);

	return (
		<div className='dropdown'>
			<div className='profile'>
				<p>You are active as</p>
				<div className='profileContainer'>
					{userState.currUser.avatar ? (
						<>
							<img src={avatarUrls[splitUserId(userState.currUser.sub)]} alt='avatar' />
						</>
					) : (
						<div className='userIconAlt icon marginRight-10'>
							{userState.currUser.given_name[0].toUpperCase() +
								userState.currUser.family_name[0].toUpperCase()}
						</div>
					)}
					<div className='profileDetails'>
						<h4>{userState.currUser.name}</h4>
						<p>{userState.currUser.email}</p>
					</div>
				</div>
				<div className={userState.users.length > 1 ? 'dropdownItem' : 'dropdownItemBorderBottom'}>
					<button
						className='functionalButtonSecondaryDark'
						onClick={() => {
							userDispatch({ type: 'MANAGE' });
						}}>
						Manage account
					</button>
				</div>
				{userState.users.length > 1 && <p className='linesOnSides'>Switch account to</p>}
				{userState.users.map((user, key) => {
					if (user.sub !== userState.currUser.sub) {
						return (
							<div key={key} className='profileItem'>
								<div
									className='profileContainerChoice'
									onClick={() => {
										userDispatch({ type: 'SET_CURR_USER', payload: user.sub });
										teamDispatch({ type: 'RESET' });
										window.location.reload();
									}}>
									{user.avatar ? (
										<img src={avatarUrls[splitUserId(user.sub)]} alt='' />
									) : (
										<div className='userIconAlt icon marginRight-10'>
											{user.given_name[0].toUpperCase() + user.family_name[0].toUpperCase()}
										</div>
									)}
									<div className='profileDetails'>
										<h4>{user.name}</h4>
										<p>{user.email}</p>
									</div>
								</div>
							</div>
						);
					} else {
						return <React.Fragment key={key}></React.Fragment>;
					}
				})}
				<div className='dropdownItem'>
					<PlusIcon iconClass={'plusIcon'} />
					<input
						type='button'
						value='Add account'
						onClick={() => {
							userDispatch({ type: 'LOGIN' });
						}}
					/>
				</div>
				<div className='dropdownItem'>
					<SignOutIcon iconClass={'signOutAltSolid'} />
					<input
						type='button'
						value='Sign out'
						onClick={() => {
							userDispatch({ type: 'LOGOUT', payload: splitUserId(userState.currUser.sub) });
						}}
					/>
				</div>
				<div className='dropdownItem'>
					<MultiSignOutIcon iconClass={'signOutAltSolid'} />
					<input
						type='button'
						value='Sign out all accounts'
						onClick={() => {
							userDispatch({ type: 'LOGOUT_ALL' });
						}}
					/>
				</div>
				<div className='bottomLinks'>
					<p>Legal</p>
					<p>Privacy Policy</p>
				</div>
			</div>
		</div>
	);
}
