import React from 'react';
import { Link } from 'react-router-dom';

export default function GridItem(props) {
	const { url, icon, body, header, colorCode, disabled } = props || {};
	return (
		<>
			<Link to={`/manage/${url}`}>
				<div className={`gridItem ${disabled ? 'disabledDiv' : ''}`}>
					<div className='mainCont'>
						<div className='gridHeader'>
							<h2>{header}</h2>
						</div>
						<div className='gridBody'>
							<p>{body}</p>
						</div>
					</div>
					<div className={`iconColumn ${colorCode}`}>{icon}</div>
				</div>
			</Link>
		</>
	);
}
