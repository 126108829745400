import React from 'react';
import Chat from '../../Chat/Chat';
import { CaretDownIcon, CaretUpIcon } from '../../../../components/SVGIcons/SVGIcons';

export default function ChatBox(props) {
	const { socket, contentReference, textboxesOpen, openTextbox } = props || {};
	return (
		<div className='rightSidebarChatContainer'>
			<div className='sidebarTextHeader' onClick={() => openTextbox('chat')}>
				<span className='bold'>Messages</span>
				{textboxesOpen['chat'] ? (
					<CaretUpIcon iconClass={'caretUpIcon'} />
				) : (
					<CaretDownIcon iconClass={'caretDownIcon'} />
				)}
			</div>
			{textboxesOpen['chat'] && <Chat socket={socket} order={contentReference} />}
		</div>
	);
}
