import React from 'react';
import GridItem from './GridItem';
import {
	AddressesDiscIcon,
	AppDiscIcon,
	BillDiscIcon,
	CartBuyDiscIcon,
	CartSellDiscIcon,
	ChartDiscIcon,
	CustomerDiscIcon,
	LegalDiscIcon,
	MemberAddDiscIcon,
	ProductDiscIcon,
	SupplierDiscIcon,
	TeamEditDiscIcon,
} from '../../components/SVGIcons/SVGIcons';

export default function TeamManageGrid() {
	return (
		<>
			<div className='gridContainer'>
				<GridItem
					url={'members'}
					header={'Members'}
					body={'Add members to your Team, manage them including their permissions.'}
					icon={<MemberAddDiscIcon iconClass='gridIcon' />}
					colorCode={'team'}
				/>
				<GridItem
					url={'team'}
					header={'Edit Team'}
					body={'Manage your Team’s registered address and details.'}
					icon={<TeamEditDiscIcon iconClass='gridIcon' />}
					colorCode={'team'}
				/>
				<GridItem
					url={'addresses'}
					header={'Addresses'}
					body={'Manage your delivery addresses and notification preferences.'}
					icon={<AddressesDiscIcon iconClass='gridIcon' />}
					colorCode={'team'}
				/>
				<GridItem
					url={''}
					header={'Billing'}
					body={'Change your subscription level and manage your payments.'}
					icon={<BillDiscIcon iconClass='gridIcon' />}
					colorCode={'team'}
					disabled
				/>
				<GridItem
					url={''}
					header={'Sales'}
					body={'See your sales, invoices and ledger.'}
					icon={<CartSellDiscIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled
				/>
				<GridItem
					url={''}
					header={'Inventory'}
					body={'Manage your products that you have for sale and your shop.'}
					icon={<ProductDiscIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled
				/>
				<GridItem
					url={''}
					header={'Customers'}
					body={`See who has ordered from you and has you in their 'trusted supplier' network.`}
					icon={<CustomerDiscIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled
				/>
				<GridItem
					url={''}
					header={'Analytics'}
					body={`Monitor your analytics properties to keep track of your business.`}
					icon={<ChartDiscIcon iconClass='gridIcon' />}
					colorCode={'analytics'}
					disabled
				/>
				<GridItem
					url={''}
					header={'Purchase Orders'}
					body={`See your current purchases and purchase history.`}
					icon={<CartBuyDiscIcon iconClass='gridIcon' />}
					colorCode={'purchase'}
					disabled
				/>
				<GridItem
					url={'trusted'}
					header={'Trusted Suppliers'}
					body={`Manage your 'trusted suppliers'.`}
					icon={<SupplierDiscIcon iconClass='gridIcon' />}
					colorCode={'purchase'}
				/>
				<GridItem
					url={''}
					header={'Legal'}
					body={`Edit your Terms and Conditions.`}
					icon={<LegalDiscIcon iconClass='gridIcon' />}
					colorCode={'legal'}
					disabled
				/>
				<GridItem
					url={'apps'}
					header={'Apps'}
					body={`Discover how you can extend your Team by using Apps and Plug-ins.`}
					icon={<AppDiscIcon iconClass='gridIcon' />}
					colorCode={'apps'}
				/>
			</div>
		</>
	);
}
