import * as moment from 'moment-timezone';
import 'moment/locale/uk';
import Decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import React from 'react';

export function getCurrTimestamp() {
	moment.locale('uk');
	return moment(Date.now()).tz('Europe/London').format('h:mm:ss');
}

export function timestampConverter(timestamp, format, timezone) {
	if (typeof timestamp === 'string' || typeof timestamp === 'object') {
		return moment(timestamp || '')
			.tz(timezone || '')
			.format(format || '');
	} else {
		return timestamp;
	}
}

export function checkWhichDay(date) {
	moment.locale('uk');
	let REFERENCE = moment();
	let TODAY = REFERENCE.clone().startOf('day');
	let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

	if (moment(date).isSame(TODAY, 'd')) {
		return 'Today';
	} else if (moment(date).isSame(YESTERDAY, 'd')) {
		return 'Yesterday';
	} else {
		return timestampConverter(date, 'DD/MM/YYYY', 'Europe/London');
	}
}

export function areDifferentDays(date1, date2) {
	moment.locale('uk');
	return moment(date1).format('DD') !== moment(date2).format('DD');
}

export function dateConverter(date) {
	moment.locale('uk');
	return moment(date).format('DD/MM/YYYY');
}

export function decodeJwt(token) {
	if (token !== null && token !== undefined) {
		return Decode(token);
	}
}

export function handleRedirect(isAuthenticated, path) {
	if (!isAuthenticated) {
		return <Navigate to={path} />;
	}
}

export function splitUserId(id) {
	if (id) {
		return id.split('|')[1];
	}
}

export function parseProcessingStatus(status) {
	switch (status) {
		case 1:
			return {
				text: 'Draft',
				style: 'waiting',
			};
		case 2:
			return {
				text: 'Pending',
				style: 'waiting',
			};
		case 3:
			return {
				text: 'Processing',
				style: 'waiting',
			};
		case 4:
			return {
				text: 'Awaitng dispatch',
				style: 'waiting',
			};
		case 5:
			return {
				text: 'Dispatched',
				style: 'waiting',
			};
		case 6:
			return {
				text: 'Delivered',
				style: 'success',
			};
		case 7:
			return {
				text: 'Disputed',
				style: 'error',
			};
		case 8:
			return {
				text: 'Void',
				style: 'error',
			};
		case 9:
			return {
				text: 'Rejected',
				style: 'error',
			};
		default:
			return {
				text: 'Unknown',
				style: '',
			};
	}
}

export function parseOrderPriority(priority) {
	switch (priority) {
		case 1:
			return 'Standard';
		case 2:
			return 'Express';
		default:
			return 'Unknown';
	}
}

export function splitNotificationType(type) {
	if (type) {
		return type.split('.');
	}
}

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		if (typeof file !== 'string') {
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		} else {
			resolve(file);
		}
	});

export const getUnreadNotificationCount = (notifications, notificationType, status, teamUuid, isBuyer) => {
	let unread = 0;
	if (status && notificationType) {
		for (let i = 0; i < notifications.length; i++) {
			if (
				notifications[i]?.notificationData?.orderProcessingStatus === status &&
				notifications[i]?.notificationData[isBuyer ? 'buyerTeamUuid' : 'sellerTeamUuid'] === teamUuid &&
				!notifications[i].isRead
			) {
				unread++;
			}
		}
	}
	return unread;
};

export const getUnreadMessageCount = (notifications, notificationType, orderUuid) => {
	let unread = 0;
	if (notificationType) {
		for (let i = 0; i < notifications.length; i++) {
			if (
				notifications[i].notificationType === notificationType &&
				!notifications[i].isRead &&
				notifications[i]?.notificationData?.orderUuid === orderUuid
			) {
				unread++;
			}
		}
	}
	return unread;
};

export const parseToOptions = (data, type) => {
	if (data && type) {
		let options = [];
		switch (type) {
			case 'suppliers':
				for (let i = 0; i < data.length; i++) {
					options.push({ name: data[i]?.teamInfo?.teamName, value: data[i]?.teamUuid });
				}
				return options;
			case 'members':
				for (let i = 0; i < data.length; i++) {
					options.push({ name: `${data[i]?.firstname} ${data[i]?.lastname}`, value: data[i]?.userUuid });
				}
				return options;
		}
	}
};

export const sliceString = (string, start, end) => {
	if (string.length < string.end) {
		return string;
	} else {
		return string.slice(start, end);
	}
};

export const checkFileType = (filetype) => {
	filetype = filetype.toLowerCase();
	switch (filetype) {
		case 'png':
			return 1;
		case 'jpg':
			return 1;
		case 'stl':
			return 2;
		case 'docx':
			return 2;
		case 'pdf':
			return 2;
		default:
			return 2;
	}
};
