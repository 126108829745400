import React, { useEffect, useState } from 'react';
import CarouselTypesSwitch from './CarouselTypesSwitch';
import CarouselHeadersSwitch from './CarouselHeaderSwitch';
import ArrowLeft from '../../../components/Arrows/ArrowLeft';
import ArrowRight from '../../../components/Arrows/ArrowRight';

export default function Carousel(props) {
	const { type, backColor, mode } = props || {};

	const [currOffset, setCurrOffset] = useState(-360);
	const [carouselWidth, setCarouselWidth] = useState(360);
	const [totalWidth, setTotalWidth] = useState(0);

	const [lastMousePress, setLastMousePress] = useState(null);
	const [mouseEventLoading, setMouseEventLoading] = useState(false);

	const [settings] = useState({
		single: { windowWidth: 1024, carouselWidth: 360 },
		dual: { windowWidth: 1280, carouselWidth: 720 },
		triple: { windowWidth: 1680, carouselWidth: 1080 },
		max: { carouselWidth: 1440 },
	});
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	function nextPage() {
		setCurrOffset(currOffset - carouselWidth);
	}

	function previousPage() {
		setCurrOffset(currOffset + carouselWidth);
	}

	function carouselSwipe(e) {
		if (!mouseEventLoading) {
			if (e.touches[0].clientX < lastMousePress) {
				if (currOffset === totalWidth || currOffset < totalWidth) {
					return;
				} else {
					nextPage();
				}
			} else if (e.touches[0].clientX > lastMousePress) {
				if (currOffset === -carouselWidth) {
					return;
				} else {
					previousPage();
				}
			}
		}
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (window.innerWidth > settings.triple.windowWidth && carouselWidth !== settings.max.carouselWidth) {
			setCarouselWidth(settings.max.carouselWidth);
			setCurrOffset(-settings.max.carouselWidth);
		} else if (window.innerWidth < settings.single.windowWidth && carouselWidth !== settings.single.carouselWidth) {
			setCarouselWidth(settings.single.carouselWidth);
			setCurrOffset(-settings.single.carouselWidth);
		} else if (
			window.innerWidth < settings.dual.windowWidth &&
			window.innerWidth > settings.single.windowWidth &&
			carouselWidth !== settings.dual.carouselWidth
		) {
			setCarouselWidth(settings.dual.carouselWidth);
			setCurrOffset(-settings.dual.carouselWidth);
		} else if (
			window.innerWidth < settings.triple.windowWidth &&
			window.innerWidth > settings.dual.windowWidth &&
			carouselWidth !== settings.triple.carouselWidth
		) {
			setCarouselWidth(settings.triple.carouselWidth);
			setCurrOffset(-settings.triple.carouselWidth);
		}
	}, [windowSize, carouselWidth, settings]);

	return (
		<div className='carousel'>
			<CarouselHeadersSwitch type={type} />
			<div
				className={`carouselInnerContainer ${type}`}
				style={{
					backgroundColor: `var(--${backColor})`,
					color: `var(--${mode === 'dark' ? 'white' : 'black'})`,
				}}>
				<ArrowLeft
					previous={previousPage}
					disabled={currOffset === -carouselWidth}
					color={mode === 'dark' ? 'white' : 'gray'}
				/>
				<div
					className='carouselItems'
					style={{
						width: `${carouselWidth}px`,
						transition: '0s',
					}}
					onTouchStart={(e) => {
						setLastMousePress(e.touches[0].clientX);
					}}
					onTouchMove={(e) => {
						carouselSwipe(e);
						setMouseEventLoading(true);
					}}
					onTouchEnd={(e) => {
						setMouseEventLoading(false);
					}}>
					<CarouselTypesSwitch
						type={type}
						carouselValues={{
							settings: settings,
							currOffset: currOffset,
							carouselWidth: carouselWidth,
							setTotalWidth: setTotalWidth,
						}}
					/>
				</div>
				<ArrowRight
					next={nextPage}
					disabled={currOffset === totalWidth || currOffset < totalWidth}
					color={mode === 'dark' ? 'white' : 'gray'}
				/>
			</div>
		</div>
	);
}
