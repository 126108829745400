import Axios from 'axios';
import React, { useContext, useRef, useState } from 'react';
import config from '../../config';
import { splitUserId, toBase64 } from '../../helpers';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import TeamForm from '../../components/Forms/TeamForm';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';

export default function TeamRegister() {
	let location = useLocation();

	const [registeredAddress, setRegisteredAddress] = useState({});
	const [deliveryAddress, setDeliveryAddress] = useState({});
	const [isDeliveryAddress, setIsDeliveryAddress] = useState(null);
	const [collection, setCollection] = useState({});
	const [errorMessage, setErrorMessage] = useState('');

	const [formData, setFormData] = useState({});

	let { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const { userTeams } = teamState;

	const millisecond = 1;

	const pageStart = useRef(null);
	const pageEnd = useRef(null);
	const acceptCollectRef = useRef(null);
	const isCompanyRef = useRef(null);
	const isDeliveryAddressRef = useRef(null);

	function scrollToTop() {
		pageStart.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	}

	function scrollToBottom() {
		setTimeout(function () {
			pageEnd.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}, millisecond);
	}

	function scrollToRef(ref) {
		console.log(ref.current);
		setTimeout(function () {
			if (ref.current) {
				ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}, millisecond);
	}

	const handleRegisteredAddressInput = (e) => {
		const { name, value } = e.target;
		setRegisteredAddress((registeredAddress) => ({
			...registeredAddress,
			[name]: value,
		}));
	};

	const handleDeliveryAddressInput = (e) => {
		const { name, value } = e.target;
		setDeliveryAddress((deliveryAddress) => ({
			...deliveryAddress,
			[name]: value,
		}));
	};

	function handleFormData(name, value) {
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	}

	const handleCollectionInput = (e) => {
		const { name, value } = e.target;
		setCollection((collection) => ({
			...collection,
			[name]: value,
		}));
	};

	async function handleSubmit(e) {
		e.preventDefault();
		e.target.disabled = true;
		const teamUuid = new URLSearchParams(location.search).get('teamUuid');
		const data = {
			userUuid: splitUserId(userState.currUser.sub),
			registeredAddress: {
				addressUuid: uuidv4(),
				isDefault: false,
				addressType: 'registeredAddress',
				name: registeredAddress.name,
				street: registeredAddress.street,
				city: registeredAddress.city,
				postalCode: registeredAddress.postalCode,
				country: registeredAddress.country,
			},
			deliveryAddress: {
				addressUuid: uuidv4(),
				isDefault: true,
				addressType: 'deliveryAddress',
				name: isDeliveryAddress ? registeredAddress.name : deliveryAddress.name,
				street: isDeliveryAddress ? registeredAddress.street : deliveryAddress.street,
				city: isDeliveryAddress ? registeredAddress.city : deliveryAddress.city,
				postalCode: isDeliveryAddress ? registeredAddress.postalCode : deliveryAddress.postalCode,
				country: isDeliveryAddress ? registeredAddress.country : deliveryAddress.country,
			},
			preferredSuppliers: [teamUuid],
			collection: {
				collectionPoint: collection.collectionPoint,
				collectionStreet: collection.collectionStreet,
				collectionCity: collection.collectionCity,
				collectionPostalCode: collection.collectionPostalCode,
				collectionCountry: collection.collectionCountry,
				collectionHours: collection.collectionHours,
				collectionInstructions: collection.collectionInstructions,
			},
			companyDescriptionHeader: formData.companyDescriptionHeader,
			companyDescription: formData.companyDescription,
			teamName: formData.teamName,
			isPublic: formData.isPublic,
			acceptCollect: formData.acceptCollect,
			isCompany: formData.isCompany,
			teamIcon: formData.formProfileImg ? await toBase64(formData.formProfileImg) : null,
			teamPicture: formData.formPicture ? await toBase64(formData.formProfileImg) : null,
			companyType: formData.companyType,
			teamInitials: formData.teamInitials,
			spokenLanguage: formData.language,
			phoneNumber: formData.phoneNumber,
			currency: formData.currency,
			tax: formData.tax,
			companyNumber: formData.companyNumber,
			companyCountry: formData.companyCountry,
		};

		try {
			Axios({
				url: `${config.apiv1}/team/team.create`,
				method: 'POST',
				data: data,
			}).then((res) => {
				if (res.data?.error) {
					// Put file with parsing different error codes to text
					setErrorMessage('Could not create team, contact site administrator');
					scrollToTop(pageStart);
				} else {
					teamDispatch({ type: 'SET_TEAM', payload: res.data.data.teamUuid });
					if (!userTeams[0]) {
						teamDispatch({ type: 'SET_USER_TEAMS', payload: [...userTeams, res.data.data] });
					} else {
						teamDispatch({ type: 'SET_USER_TEAMS', payload: [res.data.data] });
					}
					window.location.assign('/');
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<TeamForm
				formTitle={'Register team'}
				registering={true}
				refs={{
					acceptCollectRef: acceptCollectRef,
					pageStart: pageStart,
					pageEnd: pageEnd,
					isCompanyRef: isCompanyRef,
					isDeliveryAddressRef: isDeliveryAddressRef,
				}}
				scrollToRef={scrollToRef}
				scrollToBottom={scrollToBottom}
				handleSubmit={handleSubmit}
				errorMessage={errorMessage}
				formData={formData}
				handleFormData={handleFormData}
				registeredAddress={registeredAddress}
				handleRegisteredAddressInput={handleRegisteredAddressInput}
				collection={collection}
				handleCollectionInput={handleCollectionInput}
				deliveryAddress={deliveryAddress}
				handleDeliveryAddressInput={handleDeliveryAddressInput}
				isDeliveryAddress={isDeliveryAddress}
				setIsDeliveryAddress={setIsDeliveryAddress}
			/>
		</>
	);
}
