import React, { useContext, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseToOptions, splitUserId } from '../../helpers';
import { v4 as uuidv4 } from 'uuid';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import Loading from '../../components/Loading/Loading';
import { Checkmark, ErrorIcon, SaveIcon, UploadIcon } from '../../components/SVGIcons/SVGIcons';
import AddressForm from '../../components/Forms/AddressForm';
import Addresses from '../global/Addresses/Addresses';
import SelectCustom from '../../components/Select/SelectCustom';
import ProcessingModal from '../../components/Modals/ProcessingModal';
import Overlay from '../../components/Overlay/Overlay';

export default function OrderForm(props) {
	let location = useLocation();
	const name = new URLSearchParams(location.search).get('name');
	const uuid = new URLSearchParams(location.search).get('uuid');
	const parentUuid = new URLSearchParams(location.search).get('parentUuid');

	const [hint, setHint] = useState('');
	const [orderPrescription, setOrderPrescription] = useState('');
	const [date, setDate] = useState(new Date());
	const [authorization, setAuthorization] = useState(false);
	const [address, setAddress] = useState(null);
	const [orderPriority, setOrderPriority] = useState(null);
	const [behalfOf, setBehalfOf] = useState('');
	const [gradeOfWork, setGradeOfWork] = useState(null);
	const [acceptEstimate, setAcceptEstimate] = useState(null);
	const [acceptPricing, setAcceptPricing] = useState(null);
	const [formFiles, setFormFiles] = useState([]);

	const [dateValid, setDateValid] = useState(true);
	const [chosenSupplier, setChosenSupplier] = useState(uuid && name ? { value: uuid, name: name } : null);
	const [chosenAddress, setChosenAddress] = useState(null);
	const [hasAddress, setHasAddress] = useState(false);
	const [focused, setFocused] = useState(false);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [message, setMessage] = useState(null);
	const [orderUri, setOrderUri] = useState(null);

	const pageEnd = useRef(null);

	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const { loaded, suppliers } = props || {};

	const handleAddressInput = (e) => {
		const { name, value } = e.target;
		setAddress((address) => ({
			...address,
			[name]: value,
		}));
	};

	const handleFocused = (e) => {
		const { name } = e.target;
		setFocused((focused) => ({
			...focused,
			[name]: !focused[name],
		}));
	};

	function scrollToBottom() {
		setTimeout(function () {
			if (pageEnd) {
				pageEnd.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
			}
		}, 1);
	}

	function handleIconClass(valid, focus) {
		if (valid) {
			return 'inputIconSuccess';
		} else if (!valid && focus) {
			return 'inputIconError';
		} else {
			return 'hidden';
		}
	}

	async function handleFiles(e) {
		let files = [];
		for (let i = 0; i < e.target.files.length; i++) {
			files.push(e.target.files[i]);
		}
		setFormFiles(files);
	}

	async function removeFile(file) {
		let newArr = formFiles;
		let i = newArr.indexOf(file);
		newArr.splice(i, i + 1);
		setFormFiles((newArr) => [...newArr]);
	}

	async function postForm(status) {
		setLoading(true);
		setShow(true);
		let orderStatus = 2;
		if (status === 'draft') {
			orderStatus = 1;
		}

		const data = new FormData();

		data.append('buyerUserUuid', splitUserId(userState.currUser.sub));
		data.append('buyerTeamUuid', teamState.currentTeam.teamUuid);
		data.append('courierUuid', null);
		data.append('authorization', authorization);
		data.append('behalfOf', behalfOf.value);
		data.append('acceptEstimate', acceptEstimate);
		data.append('acceptPricing', acceptPricing);
		data.append('dispatchDueDateTime', date);
		data.append('createdAt', new Date());
		data.append('orderProcessingStatus', orderStatus);
		data.append('buyerHint', hint);
		data.append('orderType', 1);
		if (parentUuid) {
			data.append('parentUuid', parentUuid);
		}
		if (!uuid) {
			data.append('sellerTeamUuid', props.chooseSupplier === true ? chosenSupplier.value : props.supplierId);
		} else {
			data.append('sellerTeamUuid', props.chooseSupplier === true ? uuid : chosenSupplier.value);
		}
		data.append('sellerUserUuid', null);
		data.append('sellerTeamName', props.chooseSupplier === true ? chosenSupplier.name : name);
		data.append(
			'buyerTeamName',
			teamState.currentTeam.teamInfo.teamName ? teamState.currentTeam.teamInfo.teamName : userState.currUser.name
		);
		data.append('orderPrescription', orderPrescription);
		data.append('gradeOfWork', gradeOfWork);
		if (!chosenAddress) {
			data.append(
				'shippingAddress',
				JSON.stringify({
					name: address.name,
					street: address.street,
					city: address.city,
					postalCode: address.postalCode,
					country: address.country,
				})
			);
		} else {
			data.append(
				'shippingAddress',
				JSON.stringify({
					name: chosenAddress.name,
					street: chosenAddress.street,
					city: chosenAddress.city,
					postalCode: chosenAddress.postalCode,
					country: chosenAddress.country,
				})
			);
		}

		data.append('orderUuid', uuidv4());
		data.append('priority', orderPriority);

		for (let i = 0; i < formFiles.length; i++) {
			data.append('files', formFiles[i]);
		}

		try {
			Axios({
				url: `${config.apiv1}/order/order.create`,
				method: 'POST',
				data: data,
			}).then((res) => {
				if (!res?.err) {
					setOrderUri(`/orders/${res?.data?.data?.orderUuid}`);
					setMessage('Order successfully placed!');
					setLoading(false);
				} else {
					setMessage('Error when placing order');
					setLoading(false);
					console.log(res.err);
				}
			});
		} catch (err) {
			setMessage('Error when placing order');
			setLoading(false);
			console.log(err);
		}
	}

	function getSupplierPreferences(teamUuid) {
		for (let i = 0; i < teamState?.currentTeam?.trustedSuppliers?.length; i++) {
			if (teamState.currentTeam.trustedSuppliers[i].teamUuid === teamUuid) {
				return teamState.currentTeam.trustedSuppliers[i].preferences || {};
			}
		}
	}

	useEffect(() => {
		if (teamState) {
			const addresses = teamState?.currentTeam?.teamInfo?.addresses;
			if (addresses) {
				// Check if first entry is null or undefined
				if (addresses[0]) {
					setHasAddress(true);
					for (let i = 0; i < addresses.length; i++) {
						if (addresses[i]?.isDefault) {
							setChosenAddress(addresses[i]);
						}
					}
					if (!chosenAddress) {
						setChosenAddress(addresses[0]);
					}
				}
			}
			if (chosenSupplier) {
				console.log(chosenSupplier);
				setAcceptEstimate(getSupplierPreferences(chosenSupplier.value)?.acceptEstimate);
				setAcceptPricing(getSupplierPreferences(chosenSupplier.value)?.acceptPricing);
			}
		}
	}, [chosenSupplier]); // eslint-disable-next-line

	return (
		<div className='mainFormCont'>
			<ProcessingModal
				loading={loading}
				show={show}
				message={message}
				uri={orderUri}
				reloadBtnTxt={'Place another order'}
				canReload
			/>
			<Overlay loadOverlay={show} showOverlay={show} />
			<div className='pageEnd' ref={pageEnd}></div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					postForm();
				}}>
				{props.chooseSupplier && (
					<>
						{loaded ? (
							<div className='formSection'>
								<div className='formRowCenter'>
									<div className='fieldTextContainer'>
										<p className='extraBold' htmlFor='chooseSupplier'>
											Choose your supplier
										</p>
									</div>
									<SelectCustom
										options={parseToOptions(suppliers, 'suppliers')}
										callback={setChosenSupplier}
										value={chosenSupplier}
										placeholder={'Select...'}
									/>
								</div>
							</div>
						) : (
							<Loading type='facebook' />
						)}
					</>
				)}
				<div className='formSection'>
					<h2>Place your order</h2>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='buyerHint'>
								Hint
							</p>
						</div>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								type='text'
								name='buyerHint'
								defaultValue={hint}
								onChange={(e) => setHint(e.target.value)}
							/>
							{hint !== '' ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='dateReq'>
								Date required
							</p>
						</div>
						<div className='inputContainer'>
							<DatePicker
								selected={date}
								name='dateReq'
								className='textFieldInput'
								onBlur={(e) => handleFocused(e)}
								onFocus={(e) => handleFocused(e)}
								onChange={(date) => {
									setDate(date);
									date !== null ? setDateValid(true) : setDateValid(false);
								}}
								minDate={new Date()}
								required
							/>
							{date !== '' && date !== null ? (
								<Checkmark iconClass={handleIconClass(dateValid, focused['dateReq'])} />
							) : (
								<ErrorIcon iconClass={handleIconClass(dateValid, focused['dateReq'])} />
							)}
						</div>
					</div>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='authorization'>
								Authorization
							</p>
						</div>
						<div className='formChoices'>
							<label className='checkboxLabel'>
								<input
									type='checkbox'
									name='authorization'
									defaultValue={authorization}
									onChange={(e) => setAuthorization(e.target.checked)}
								/>
								<div className='customCheckbox'></div>
								<span>
									I hereby verify that I am authorized to purchase on behalf of{' '}
									{teamState?.currentTeam?.teamInfo?.teamName}
								</span>
							</label>
						</div>
					</div>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='behalfOf'>
								Acting on behalf of...
							</p>
						</div>
						{teamState?.userPermissions['authorizedOnBehalf'] ? (
							<SelectCustom
								options={parseToOptions(teamState?.currentTeam?.members, 'members')}
								callback={setBehalfOf}
								placeholder={'Select...'}
							/>
						) : (
							<input
								className='textFieldInput'
								type='text'
								name='behalfOf'
								defaultValue={`${userState.currUser.name} (You)`}
								onChange={() => {
									setBehalfOf({
										value: splitUserId(userState.currUser.sub),
										name: userState.currUser.name,
									});
								}}
								required
								disabled
							/>
						)}
					</div>
				</div>
				<div className='formSection'>
					<h3>Permissions and order flow</h3>
					<div className='formRowTop'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='estimateAccept'>
								Accept estimate automatically
							</p>
						</div>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input
									type='radio'
									name='estimateAccept'
									checked={acceptEstimate === true}
									onChange={() => setAcceptEstimate(true)}
									required
								/>
								<div className='customRadio'></div>
								<span>Yes</span>
							</label>
							<label className='radioLabel'>
								<input
									type='radio'
									name='estimateAccept'
									checked={acceptEstimate === false}
									onChange={() => setAcceptEstimate(false)}
									required
								/>
								<div className='customRadio'></div>
								<span>No, authorization required</span>
							</label>
						</div>
					</div>
					<div className='formRowTop'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='pricingAccept'>
								Will you automatically accept changes to pricing throughout the manufacturing process?
							</p>
						</div>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input
									type='radio'
									name='pricingAccept'
									checked={acceptPricing === true}
									onChange={() => setAcceptPricing(true)}
									required
								/>
								<div className='customRadio'></div>
								<span>Yes</span>
							</label>
							<label className='radioLabel'>
								<input
									type='radio'
									name='pricingAccept'
									checked={acceptPricing === false}
									onChange={() => setAcceptPricing(false)}
									required
								/>
								<div className='customRadio'></div>
								<span>No, each pricing change needs approval</span>
							</label>
						</div>
					</div>
				</div>
				<div className='formSection'>
					<h3>Customize your order</h3>
					<div className='formColumnCenter'>
						<div className='fieldTextLeftContainer'>
							<p className='extraBold' htmlFor='orderPrescription'>
								Add a description of your prescription
							</p>
						</div>
						<textarea
							className='formTextarea'
							type='text'
							name='orderPrescription'
							defaultValue={orderPrescription}
							onChange={(e) => setOrderPrescription(e.target.value)}
						/>
					</div>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='uploadFile'>
								Optionally upload a scan of your prescription
							</p>
						</div>
						<div className='fileInput'>
							<input
								className='file'
								id='uploadFile'
								type='file'
								name='uploadFile'
								multiple
								onChange={(e) => handleFiles(e)}
							/>
							<label htmlFor='uploadFile'>
								<UploadIcon iconClass='uploadIcon' />
								<span>Choose files</span>
							</label>
						</div>
					</div>
					{formFiles[0] &&
						formFiles.map((file, key) => {
							return (
								<div key={key}>
									<p className='extraBold'>{file.name}</p>
									<p className='extraBold' onClick={() => removeFile(file)}>
										Remove
									</p>
								</div>
							);
						})}
					<div className='formRowTop'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='gradeOfWork'>
								Grade of work
							</p>
						</div>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input type='radio' name='gradeOfWork' onChange={(e) => setGradeOfWork(1)} required />
								<div className='customRadio'></div>
								<span>High</span>
							</label>
							<label className='radioLabel'>
								<input type='radio' name='gradeOfWork' onChange={(e) => setGradeOfWork(2)} required />
								<div className='customRadio'></div>
								<span>Medium</span>
							</label>
							<label className='radioLabel'>
								<input type='radio' name='gradeOfWork' onChange={(e) => setGradeOfWork(3)} required />
								<div className='customRadio'></div>
								<span>Low</span>
							</label>
						</div>
					</div>
				</div>
				<div className='formSection'>
					<h3>Attachments</h3>
					<div className='formRowCenter'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='uploadFile'>
								Click/tab to upload additional files
							</p>
						</div>
						<div className='fileInput'>
							<input
								className='file'
								id='uploadFile'
								type='file'
								name='uploadFile'
								multiple
								onChange={(e) => handleFiles(e)}
							/>
							<label htmlFor='uploadFile'>
								<UploadIcon iconClass='uploadIcon' />
								<span>Choose files</span>
							</label>
						</div>
					</div>
					{formFiles[0] &&
						formFiles.map((file, key) => {
							return (
								<div key={key}>
									<p className='extraBold'>{file.name}</p>
									<p className='extraBold' onClick={() => removeFile(file)}>
										Remove
									</p>
								</div>
							);
						})}
				</div>
				<div className='formSection'>
					<h3>Delivery</h3>
					<div className='formRowTop'>
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor='deliveryType'>
								Choose a type of delivery
							</p>
						</div>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input
									type='radio'
									defaultValue={1}
									onChange={(e) => {
										setOrderPriority(e.target.value);
									}}
									name='deliveryType'
									required
								/>
								<div className='customRadio'></div>
								<span>Standard</span>
							</label>
							<label className='radioLabel'>
								<input
									type='radio'
									defaultValue={2}
									onChange={(e) => {
										setOrderPriority(e.target.value);
									}}
									name='deliveryType'
									required
								/>
								<div className='customRadio'></div>
								<span>Express</span>
							</label>
						</div>
					</div>
					<h4>Enter delivery address</h4>

					{hasAddress ? (
						<>
							<Addresses
								address={address}
								setAddress={setAddress}
								addresses={teamState?.currentTeam?.teamInfo?.addresses}
								chosenAddress={chosenAddress}
								setChosenAddress={setChosenAddress}
								handleAddressInput={handleAddressInput}
								scrollToBottom={scrollToBottom}
								layoutType={'addressPicker'}
							/>
						</>
					) : (
						<AddressForm address={address} handleAddressInput={handleAddressInput} required={false} />
					)}
				</div>
				<div className='formSubmit'>
					<button
						className='submitBtnSecondary'
						onClick={() => {
							postForm('draft');
						}}
						disabled={!teamState.userPermissions['manageOrders'] || loading}>
						<SaveIcon iconClass='saveIcon' />
						Save as draft
					</button>
					<button className='submitBtn' disabled={!teamState.userPermissions['manageOrders'] || loading}>
						Submit enquiry
					</button>
				</div>
			</form>
		</div>
	);
}
