import React from 'react';

export default function PermissionsForm(props) {
	const { role, permissions, handlePermissionsInput, setRole, setPermissions, required } = props || {};

	function setPermissionsFromRole(e) {
		setRole(e.target.value);
		switch (e.target.value) {
			case 'administrator':
				setPermissions({
					isAdmin: true,
					isBuyer: true,
					manageOrders: true,
					authorizedOnBehalf: true,
					canInvite: true,
					canAddTrusted: true,
				});
				break;
			case 'manager':
				setPermissions({
					isAdmin: false,
					isBuyer: true,
					manageOrders: true,
					authorizedOnBehalf: false,
					canInvite: true,
					canAddTrusted: true,
				});
				break;
			case 'buyer':
				setPermissions({
					isAdmin: false,
					isBuyer: true,
					manageOrders: false,
					authorizedOnBehalf: false,
					canInvite: false,
					canAddTrusted: false,
				});
				break;
			case 'officer':
				setPermissions({
					isAdmin: false,
					isBuyer: false,
					manageOrders: false,
					authorizedOnBehalf: true,
					canInvite: false,
					canAddTrusted: false,
				});
				break;
			case 'member':
				setPermissions({
					isAdmin: false,
					isBuyer: false,
					manageOrders: false,
					authorizedOnBehalf: false,
					canInvite: false,
					canAddTrusted: false,
				});
				break;
			default:
				return null;
		}
	}

	return (
		<>
			<h3>Set user permissions</h3>
			<div className='modalRow'>
				<div className='modalColumn'>
					<h4>Member role</h4>
					<div className='formChoices'>
						<label className='radioLabel'>
							<input
								type='radio'
								name='administrator'
								value='administrator'
								checked={role === 'administrator' || ''}
								onChange={(e) => setPermissionsFromRole(e)}
								required={required}
							/>
							<div className='customRadio'></div>
							<span>Administrator</span>
						</label>
						<label className='radioLabel'>
							<input
								type='radio'
								name='manager'
								value='manager'
								checked={role === 'manager' || ''}
								onChange={(e) => setPermissionsFromRole(e)}
								required={required}
							/>
							<div className='customRadio'></div>
							<span>Manager</span>
						</label>
						<label className='radioLabel'>
							<input
								type='radio'
								name='buyer'
								value='buyer'
								checked={role === 'buyer' || ''}
								onChange={(e) => setPermissionsFromRole(e)}
								required={required}
							/>
							<div className='customRadio'></div>
							<span>Buyer</span>
						</label>
						<label className='radioLabel'>
							<input
								type='radio'
								name='officer'
								value='officer'
								checked={role === 'officer' || ''}
								onChange={(e) => setPermissionsFromRole(e)}
								required={required}
							/>
							<div className='customRadio'></div>
							<span>Officer</span>
						</label>
						<label className='radioLabel'>
							<input
								type='radio'
								name='member'
								value='member'
								checked={role === 'member' || ''}
								onChange={(e) => setPermissionsFromRole(e)}
								required={required}
							/>
							<div className='customRadio'></div>
							<span>Member</span>
						</label>
					</div>
				</div>
				<div className='modalColumn'>
					<h4>Permissions</h4>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='isAdmin'
							checked={permissions.isAdmin || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>Is Administrator?</span>
					</label>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='isBuyer'
							checked={permissions.isBuyer || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>Is a Buyer?</span>
					</label>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='manageOrders'
							checked={permissions.manageOrders || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>See and manage all orders?</span>
					</label>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='authorizedOnBehalf'
							checked={permissions.authorizedOnBehalf || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>Is this person authorized to act on behalf of a buyer?</span>
					</label>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='canInvite'
							checked={permissions.canInvite || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>Can they invite people into the team?</span>
					</label>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name='canAddTrusted'
							checked={permissions.canAddTrusted || ''}
							onChange={(e) => handlePermissionsInput(e)}
							required={required}
						/>
						<div className='customCheckbox'></div>
						<span>Can they add Trusted Sellers?</span>
					</label>
				</div>
			</div>
		</>
	);
}
