import React from "react";

export default function Loading(props) {
    return (
        <>
            {props.type === "facebook" && (
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            )}
            {props.type === "circle" && (
                <div className="lds-dual-ring"></div>
            )}
        </>
    )
}