import React from 'react';
import { Link } from 'react-router-dom';
import trandactorLite from '../../assets/img/lab-manager-logo.PNG';

export default function TeamAppDetails() {
	return (
		<div id='apps_overview'>
			<div className='app_tile'>
				<img src={trandactorLite} width='120' height='120' alt='Transactor Lite' />

				<p>
					Transactor's LabManager is the UK's Leading Dental Laboratory Software. Add your orders to
					LabManager automatically as they come in and sync your messages.
				</p>

				<p>
					Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
					Lorem Ipsum Lorem Ipsum
				</p>

				<div className='centered_button'>
					<Link
						to={`https://pconnectserver.azurewebsites.net/Identity/Account/RegisterLink?returnUrl=${window.location.href}&email=${userState.currUser.email}&sellerteamuuid=${teamState.currentTeam.teamUuid}`}>
						<button>ADD</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
