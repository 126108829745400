let config;

if (process.env.REACT_APP_ENV === 'staging') {
	config = {
		host: 'https://staging.procuur.com',
		api: 'https://staging-api.procuur.com',
		apiv1: 'https://staging-api.procuur.com/v1',
		auth: 'https://staging-accounts.procuur.com',
	};
} else if (process.env.REACT_APP_ENV === 'development') {
	config = {
		host: 'http://localhost:8080',
		host2: 'http://localhost:3000',
		api: 'https://api.procuur.com',
		apiv1: 'https://api.procuur.com/v1',
		auth: 'https://accounts.procuur.com',
	};
} else if (process.env.REACT_APP_ENV === 'development-local') {
	config = {
		host: 'http://localhost:8080',
		host2: 'http://localhost:3000',
		api: 'http://localhost:8086',
		apiv1: 'http://localhost:8086/v1',
		auth: 'http://localhost:3006',
	};
} else if (process.env.ENVIRONMENT === 'staging') {
	config = {
		host: 'https://staging.procuur.com',
		api: 'https://staging-api.procuur.com',
		apiv1: 'https://staging-api.procuur.com/v1',
		auth: 'https://staging-accounts.procuur.com',
	};
} else {
	config = {
		host: 'https://staging.procuur.com',
		api: 'https://staging-api.procuur.com',
		apiv1: 'https://staging-api.procuur.com/v1',
		auth: 'https://staging-accounts.procuur.com',
	};
}

export default config;
