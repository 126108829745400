import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

export default function OrderConfirm() {
	const [pageMessage, setPageMessage] = useState('');

	const { userState } = useContext(CurrentUserContext);

	let location = useLocation();

	useEffect(() => {
		const { search } = location;

		const uuid = new URLSearchParams(search).get('uuid');
		Axios(`${config.apiv1}/order/order.confirm/${uuid}`, {
			method: 'PATCH',
		}).then((res) => {
			if (res.data.ok) {
				setPageMessage('Order successfully registered as delivered!');
			} else {
				setPageMessage("Order either doesn't exist or has already been registered as delivered");
			}
		});
	}, [location, userState]);
	return (
		<div>
			<h1>{pageMessage}</h1>
		</div>
	);
}
