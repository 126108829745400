import React, { useState, useEffect, useRef, useContext } from 'react';
import { areDifferentDays, checkWhichDay, splitUserId, timestampConverter } from '../../../helpers';
import Axios from 'axios';
import config from '../../../config';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import Loading from '../../../components/Loading/Loading';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import { PaperPlaneIcon } from '../../../components/SVGIcons/SVGIcons';
import { Link } from 'react-router-dom';

export default function Chat(props) {
	const [inputFilled, setInputFilled] = useState(false);
	const [room, setRoom] = useState({ messages: [] });
	const [loaded, setLoaded] = useState(false);
	const [chatStarted, setChatStarted] = useState(false);
	const [socketJoined, setSocketJoined] = useState(false);
	const divEnd = useRef(null);
	const message = useRef(null);
	const { order, socket } = props || {};

	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	function handleChange() {
		if (message.current.value !== '') {
			setInputFilled(true);
		} else {
			setInputFilled(false);
		}
	}

	function prepMessages(room) {
		const { messages } = room;
		let newRoom = { uuid: room.uuid, messages: [] };
		for (let i = 0; i < messages.length; i++) {
			if (
				messages[i - 1] &&
				messages[i] &&
				areDifferentDays(messages[i - 1].sentDateTime, messages[i].sentDateTime)
			) {
				newRoom.messages.push({ type: 'separator', date: messages[i].sentDateTime });
			} else if (i === 0) {
				newRoom.messages.push({ type: 'separator', date: messages[i].sentDateTime });
			}
			newRoom.messages.push(room.messages[i]);
		}
		setRoom(newRoom);
	}

	function pushMessage() {
		// Update this newMessage object to be more dynamic, and also to take in new data depending on if buyer or
		// seller data is discovered
		const newMessage = {
			messageText: message.current.value,
			orderUuid: order.orderUuid,
			roomUuid: order.orderUuid,
			buyerTeamUuid: order.buyerTeamUuid,
			sellerTeamUuid: order.sellerTeamUuid,
			buyerUserUuid:
				order.buyerTeamUuid === teamState.currentTeam.teamUuid ? splitUserId(userState.currUser.sub) : null,
			sellerUserUuid:
				order.sellerTeamUuid === teamState.currentTeam.teamUuid ? splitUserId(userState.currUser.sub) : null,
			buyerUserRef: order.buyerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.given_name : null,
			sellerUserRef:
				order.sellerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.given_name : null,
			messageSenderUuid: teamState.currentTeam.teamUuid,
		};
		socket.emit('send-chat-message', newMessage);
		newMessage.sentDateTime = new Date();
		let updatedRoom = room;
		updatedRoom.messages.push(newMessage);
		if (!chatStarted) {
			setChatStarted(true);
		}
		setRoom((room) => ({
			...room,
			messages: updatedRoom.messages,
		}));
		for (let i = 0; i < divEnd.length; i++) {
			divEnd[i].current.scrollIntoView({ behavior: 'smooth' });
		}
		message.current.value = '';
		setInputFilled(false);
	}

	useEffect(() => {
		const getOrderRoom = async () => {
			try {
				Axios({
					url: `${config.apiv1}/chat/room.read/${order.orderUuid}`,
					method: 'GET',
				})
					.catch((err) => {
						console.log(err);
						return err;
					})
					.then((res) => {
						if (res?.data?.data) {
							console.log(res);
							if (!res?.data?.data?.messages[0]) {
								setRoom({ uuid: order.orderUuid, messages: [] });
								setChatStarted(false);
								setLoaded(true);
							} else {
								prepMessages(res.data.data);
							}
							setLoaded(true);
						} else {
							Axios({
								url: `${config.apiv1}/chat/room.create`,
								method: 'POST',
								data: {
									uuid: order.orderUuid,
									users: [order.buyerUserUuid, order.sellerUserUuid],
								},
							})
								.catch((err) => {
									console.log(err);
								})
								.then((res) => {
									setRoom({ uuid: order.orderUuid, messages: [] });
									setLoaded(true);
									setChatStarted(true);
								});
						}
					});
			} catch (err) {
				console.log(err);
				if (err.message === 'ROOM_NOT_FOUND') {
					setRoom({ uuid: order.orderUuid, messages: [] });
					setLoaded(true);
				}
			}
		};
		getOrderRoom();
	}, [order]); // eslint-disable-next-line

	useEffect(() => {
		if (loaded && socket !== null) {
			if (room && !socketJoined) {
				socket.emit('join-room', room.uuid, userState.currUser.given_name);
				setSocketJoined(true);

				// Socket chat events
				socket.on('chat-message', (message) => {
					if (message.roomUuid === room.uuid) {
						let updatedRoom = room;
						updatedRoom.messages.push(message);

						setRoom((room) => ({
							...room,
							messages: updatedRoom.messages,
						}));
					}
				});
			}
			divEnd.current.scrollIntoView();
		}
	}, [loaded, socket, chatStarted]); // eslint-disable-next-line

	useEffect(() => {
		if (loaded && chatStarted) {
			divEnd.current.scrollIntoView();
			message.current.focus();
		}
	}, [room]); // eslint-disable-next-line

	return (
		<>
			<div className='chatContainer'>
				{loaded ? (
					<>
						<div className='chatContactHeader'>
							<Link to={`/suppliers/${order?.sellerTeamUuid}?name=${order?.sellerTeamName}`}>
								<h4 className='contactName'>
									{teamState.currentTeam.teamUuid === order.sellerTeamUuid
										? order.buyerTeamName
										: order.sellerTeamName}
								</h4>
							</Link>
						</div>
						<div className='chatBox'>
							{room.messages ? (
								room.messages.map((message, key) => {
									return (
										<React.Fragment key={key}>
											{message.type === 'separator' ? (
												<>
													<h4>{checkWhichDay(message.date)}</h4>
												</>
											) : (
												<>
													<div
														className={
															message.messageSenderUuid === teamState.currentTeam.teamUuid
																? 'chatMessageUser'
																: 'chatMessage'
														}>
														<div className='messageTopCont'>
															{message.messageSenderUuid !==
																teamState.currentTeam.teamUuid && (
																<p className='messageUserName'>
																	{message.messageSenderUuid === order.buyerTeamUuid
																		? message.buyerUserRef
																		: message.sellerUserRef}
																</p>
															)}
															<p className='chatTimestamp'>
																{timestampConverter(
																	message.sentDateTime,
																	'hh:mm',
																	'Europe/London'
																)}
															</p>
														</div>
														<p className='messageContent'>{message.messageText}</p>
													</div>
												</>
											)}
										</React.Fragment>
									);
								})
							) : (
								<div />
							)}
							<div style={{ visibility: 'hidden' }} ref={divEnd} />
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								pushMessage();
								handleChange();
							}}
							className='submitCont'
							autoComplete='off'>
							<input
								placeholder='Message'
								onChange={() => {
									handleChange();
								}}
								ref={message}
								name='message'
								className='messageInput'
							/>
							<button disabled={!inputFilled} className='chatSubmitBtn'>
								<PaperPlaneIcon iconClass={inputFilled ? 'paperPlaneIcon' : 'paperPlaneIcon'} />
							</button>
						</form>
					</>
				) : (
					<>
						<Loading type='circle' />
						<div style={{ visibility: 'hidden' }} ref={divEnd} />
					</>
				)}
			</div>
		</>
	);
}
