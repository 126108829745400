import React from 'react';

import { ReactComponent as GotoDiscBlack40 } from '../../assets/svg/40x40_bold/iconB-goto-disc-black-40.svg';

import { ReactComponent as BellDiscDash } from '../../assets/svg/40x40_regular_disc/iconR-bell.svg';
import { ReactComponent as CartBuyDiscDash } from '../../assets/svg/40x40_regular_disc/iconR-cart-buy.svg';
import { ReactComponent as CartSellDiscDash } from '../../assets/svg/40x40_regular_disc/iconR-cart-sell.svg';
import { ReactComponent as AnalyticsDisc } from '../../assets/svg/40x40_regular_disc/iconR-analytics.svg';
import { ReactComponent as PlusDisc } from '../../assets/svg/40x40_regular_disc/iconR-plus.svg';

import { ReactComponent as PhoneOutDisc } from '../../assets/svg/40x40_regular_disc/iconR-phone-out.svg';
import { ReactComponent as CartPlus } from '../../assets/svg/24x24_regular/iconR-cart-plus.svg';

import { ReactComponent as TeamRegisterDiscF2 } from '../../assets/svg/navigation/nav-topbar/iconR-team-register-disc-f2.svg';
import { ReactComponent as CartBuyDiscF2 } from '../../assets/svg/navigation/nav-topbar/iconR-cart-buy-disc-f2.svg';
import { ReactComponent as FeedbackDiscF2 } from '../../assets/svg/navigation/nav-topbar/iconR-feedback-disc-f2.svg';
import { ReactComponent as InviteDiscF2 } from '../../assets/svg/navigation/nav-topbar/iconR-invite-disc-f2.svg';
import { ReactComponent as SupplierDiscF2 } from '../../assets/svg/navigation/nav-topbar/iconR-supplier-disc-f2.svg';

import { ReactComponent as ManageTeam } from '../../assets/svg/users-gear.svg';
import { ReactComponent as ArrowPrevLeft } from '../../assets/svg/navigation/iconR-previous-left.svg';
import { ReactComponent as ArrowNextRight } from '../../assets/svg/navigation/iconR-next-right.svg';
import { ReactComponent as SignOut } from '../../assets/svg/24x24_light/iconL-sign-out.svg';
import { ReactComponent as SignIn } from '../../assets/svg/24x24_light/iconL-sign-in.svg';
import { ReactComponent as MultiSignOut } from '../../assets/svg/24x24_light/iconL-multi-sign-out.svg';
import { ReactComponent as Bell } from '../../assets/svg/24x24_light/iconL-bell.svg';

import { ReactComponent as TopnavMenu } from '../../assets/svg/navigation/nav-header/iconR-topnav-menu.svg';
import { ReactComponent as TopnavClose } from '../../assets/svg/navigation/nav-header/iconR-topnav-close.svg';
import { ReactComponent as TopnavUserSwitch } from '../../assets/svg/navigation/nav-header/iconL-topnav-user-switch.svg';
import { ReactComponent as TopnavUserCircle } from '../../assets/svg/navigation/nav-header/iconL-topnav-user-circle.svg';
import { ReactComponent as TopnavTeamCircle } from '../../assets/svg/navigation/nav-header/iconL-topnav-team-circle.svg';
import { ReactComponent as TopnavBell } from '../../assets/svg/navigation/nav-header/iconR-topnav-bell.svg';

import { ReactComponent as Apps } from '../../assets/svg/navigation/nav-sidebar/iconR-apps.svg';
import { ReactComponent as CartBuy } from '../../assets/svg/navigation/nav-sidebar/iconR-cart-buy.svg';
import { ReactComponent as CartSell } from '../../assets/svg/navigation/nav-sidebar/iconR-cart-sell.svg';
import { ReactComponent as Chart } from '../../assets/svg/navigation/nav-sidebar/iconR-chart.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/navigation/nav-sidebar/iconR-dashboard.svg';
import { ReactComponent as Legal } from '../../assets/svg/navigation/nav-sidebar/iconR-legal.svg';
import { ReactComponent as Support } from '../../assets/svg/navigation/nav-sidebar/iconR-support.svg';

import { ReactComponent as AddressesDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-addressbook-disc.svg';
import { ReactComponent as AppDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-app.svg';
import { ReactComponent as BillDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-bill-disc.svg';
import { ReactComponent as CartBuyDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-cart-buy-disc.svg';
import { ReactComponent as CartSellDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-cart-sell-disc.svg';
import { ReactComponent as ChartDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-chart-disc.svg';
import { ReactComponent as CustomerDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-customer.svg';
import { ReactComponent as LegalDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-legal-disc.svg';
import { ReactComponent as MemberAddDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-user-plus.svg';
import { ReactComponent as TeamEditDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-team-plus.svg';
import { ReactComponent as ProductDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-boxes-stacked-disc.svg';
import { ReactComponent as SupplierDisc } from '../../assets/svg/40x40_disc-negative__ie_TEAM-DASHBOARD/iconR-supplier-disc.svg';

import { ReactComponent as Shop } from '../../assets/svg/24x24_regular/iconR-shop.svg';
import { ReactComponent as PhoneOut } from '../../assets/svg/24x24_regular/iconR-phone-out.svg';
import { ReactComponent as Chat } from '../../assets/svg/24x24_regular/iconR-chat.svg';
import { ReactComponent as Question } from '../../assets/svg/24x24_regular/iconR-question.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/24x24_regular/iconR-bookmark.svg';
import { ReactComponent as TruckLeft } from '../../assets/svg/24x24_regular/iconR-truck-left.svg';
import { ReactComponent as Bill } from '../../assets/svg/24x24_regular/iconR-bill.svg';
import { ReactComponent as ListOrdered } from '../../assets/svg/24x24_regular/iconR-list-ordered.svg';
import { ReactComponent as File } from '../../assets/svg/24x24_regular/iconR-file.svg';
import { ReactComponent as Tracking } from '../../assets/svg/24x24_regular/iconR-tracking.svg';
import { ReactComponent as Inbox } from '../../assets/svg/24x24_regular/iconR-inbox.svg';

import { ReactComponent as NewOrder } from '../../assets/svg/product-related/iconR-new-order.svg';
import { ReactComponent as SupplierTrusted } from '../../assets/svg/product-related/iconS-supplier-trusted.svg';
import { ReactComponent as SupplierNot } from '../../assets/svg/product-related/iconS-supplier-not.svg';

import { ReactComponent as Learning1 } from '../../assets/SVG_Learning-Zone/learning-01.svg';
import { ReactComponent as Learning2 } from '../../assets/SVG_Learning-Zone/learning-02.svg';
import { ReactComponent as Learning3 } from '../../assets/SVG_Learning-Zone/learning-03.svg';
import { ReactComponent as Learning4 } from '../../assets/SVG_Learning-Zone/learning-04.svg';

import { ReactComponent as RankCommBlack } from '../../assets/svg/ranking/iconS-rank-comm-black.svg';
import { ReactComponent as RankCommWhite } from '../../assets/svg/ranking/iconS-rank-comm-white.svg';

import { ReactComponent as Print } from '../../assets/svg/24x24_light/iconL-print.svg';

import { ReactComponent as ProductPlaceholder } from '../../assets/svg/product-img-blank.svg';

// * CUSTOM ICONS *

// Order page
export const BookmarkIcon = (props) => {
	return <Bookmark className={props.iconClass} onClick={props.onClick} />;
};
export const TruckLeftIcon = (props) => {
	return <TruckLeft className={props.iconClass} onClick={props.onClick} />;
};
export const BillIcon = (props) => {
	return <Bill className={props.iconClass} onClick={props.onClick} />;
};
export const ListOrderedIcon = (props) => {
	return <ListOrdered className={props.iconClass} onClick={props.onClick} />;
};
export const FileIcon = (props) => {
	return <File className={props.iconClass} onClick={props.onClick} />;
};
export const TrackingIcon = (props) => {
	return <Tracking className={props.iconClass} onClick={props.onClick} />;
};
export const InboxIcon = (props) => {
	return <Inbox className={props.iconClass} onClick={props.onClick} />;
};
export const PlusDiscIcon = (props) => {
	return <PlusDisc className={props.iconClass} onClick={props.onClick} />;
};

// System
export const ManageTeamIcon = (props) => {
	return <ManageTeam className={props.iconClass} onClick={props.onClick} />;
};
export const ArrowPrevLeftIcon = (props) => {
	return <ArrowPrevLeft className={props.iconClass} onClick={props.onClick} />;
};
export const ArrowNextRightIcon = (props) => {
	return <ArrowNextRight className={props.iconClass} onClick={props.onClick} />;
};
export const SigninIcon = (props) => {
	return <SignIn className={props.iconClass} onClick={props.onClick} />;
};
export const SignOutIcon = (props) => {
	return <SignOut className={props.iconClass} onClick={props.onClick} />;
};
export const MultiSignOutIcon = (props) => {
	return <MultiSignOut className={props.iconClass} onClick={props.onClick} />;
};
export const GotoDiscBlack40Icon = (props) => {
	return <GotoDiscBlack40 className={props.iconClass} onClick={props.onClick} />;
};
export const BellIcon = (props) => {
	return <Bell className={props.iconClass} onClick={props.onClick} />;
};
export const ProductPlaceholderIcon = (props) => {
	return <ProductPlaceholder className={props.iconClass} onClick={props.onClick} />;
};

// Team Dash Icons
export const BellDiscDashIcon = (props) => {
	return <BellDiscDash className={props.iconClass} onClick={props.onClick} />;
};
export const CartBuyDiscDashIcon = (props) => {
	return <CartBuyDiscDash className={props.iconClass} onClick={props.onClick} />;
};
export const CartSellDiscDashIcon = (props) => {
	return <CartSellDiscDash className={props.iconClass} onClick={props.onClick} />;
};
export const AnalyticsDiscIcon = (props) => {
	return <AnalyticsDisc className={props.iconClass} onClick={props.onClick} />;
};

// Nav-header
export const TopnavUserCircleIcon = (props) => {
	return <TopnavUserCircle className={props.iconClass} onClick={props.onClick} />;
};
export const TopnavTeamCircleIcon = (props) => {
	return <TopnavTeamCircle className={props.iconClass} onClick={props.onClick} />;
};
export const TopnavMenuIcon = (props) => {
	return <TopnavMenu className={props.iconClass} onClick={props.onClick} />;
};
export const TopnavCloseIcon = (props) => {
	return <TopnavClose className={props.iconClass} onClick={props.onClick} />;
};
export const TopnavUserSwitchIcon = (props) => {
	return <TopnavUserSwitch className={props.iconClass} onClick={props.onClick} />;
};
export const TopnavBellIcon = (props) => {
	return <TopnavBell className={props.iconClass} onClick={props.onClick} />;
};

// Nav-topbar
export const TeamRegisterDiscF2Icon = (props) => {
	return <TeamRegisterDiscF2 className={props.iconClass} onClick={props.onClick} />;
};
export const CartBuyDiscF2Icon = (props) => {
	return <CartBuyDiscF2 className={props.iconClass} onClick={props.onClick} />;
};
export const FeedbackDiscF2Icon = (props) => {
	return <FeedbackDiscF2 className={props.iconClass} onClick={props.onClick} />;
};
export const InviteDiscF2Icon = (props) => {
	return <InviteDiscF2 className={props.iconClass} onClick={props.onClick} />;
};
export const SupplierDiscF2Icon = (props) => {
	return <SupplierDiscF2 className={props.iconClass} onClick={props.onClick} />;
};

// Nav-sidebar
export const AppsIcon = (props) => {
	return <Apps className={props.iconClass} onClick={props.onClick} />;
};
export const CartBuyIcon = (props) => {
	return <CartBuy className={props.iconClass} onClick={props.onClick} />;
};
export const CartSellIcon = (props) => {
	return <CartSell className={props.iconClass} onClick={props.onClick} />;
};
export const ChartIcon = (props) => {
	return <Chart className={props.iconClass} onClick={props.onClick} />;
};
export const DashboardIcon = (props) => {
	return <Dashboard className={props.iconClass} onClick={props.onClick} />;
};
export const LegalIcon = (props) => {
	return <Legal className={props.iconClass} onClick={props.onClick} />;
};
export const SupportIcon = (props) => {
	return <Support className={props.iconClass} onClick={props.onClick} />;
};

// Supplier card
export const PhoneOutDiscIcon = (props) => {
	return <PhoneOutDisc className={props.iconClass} onClick={props.onClick} />;
};
export const CartPlusIcon = (props) => {
	return <CartPlus className={props.iconClass} onClick={props.onClick} />;
};
export const ChatIcon = (props) => {
	return <Chat className={props.iconClass} onClick={props.onClick} />;
};

// Supplier listing
export const ShopIcon = (props) => {
	return <Shop className={props.iconClass} onClick={props.onClick} />;
};
export const PhoneOutIcon = (props) => {
	return <PhoneOut className={props.iconClass} onClick={props.onClick} />;
};

// Team management
export const AddressesDiscIcon = (props) => {
	return <AddressesDisc className={props.iconClass} onClick={props.onClick} />;
};
export const AppDiscIcon = (props) => {
	return <AppDisc className={props.iconClass} onClick={props.onClick} />;
};
export const BillDiscIcon = (props) => {
	return <BillDisc className={props.iconClass} onClick={props.onClick} />;
};
export const CartBuyDiscIcon = (props) => {
	return <CartBuyDisc className={props.iconClass} onClick={props.onClick} />;
};
export const CartSellDiscIcon = (props) => {
	return <CartSellDisc className={props.iconClass} onClick={props.onClick} />;
};
export const ChartDiscIcon = (props) => {
	return <ChartDisc className={props.iconClass} onClick={props.onClick} />;
};
export const CustomerDiscIcon = (props) => {
	return <CustomerDisc className={props.iconClass} onClick={props.onClick} />;
};
export const LegalDiscIcon = (props) => {
	return <LegalDisc className={props.iconClass} onClick={props.onClick} />;
};
export const MemberAddDiscIcon = (props) => {
	return <MemberAddDisc className={props.iconClass} onClick={props.onClick} />;
};
export const ProductDiscIcon = (props) => {
	return <ProductDisc className={props.iconClass} onClick={props.onClick} />;
};
export const TeamEditDiscIcon = (props) => {
	return <TeamEditDisc className={props.iconClass} onClick={props.onClick} />;
};
export const SupplierDiscIcon = (props) => {
	return <SupplierDisc className={props.iconClass} onClick={props.onClick} />;
};

// Product-related
export const NewOrderIcon = (props) => {
	return <NewOrder className={props.iconClass} onClick={props.onClick} />;
};
export const SupplierTrustedIcon = (props) => {
	return <SupplierTrusted className={props.iconClass} onClick={props.onClick} />;
};
export const SupplierNotIcon = (props) => {
	return <SupplierNot className={props.iconClass} onClick={props.onClick} />;
};

// SVG Learning Zone
export const Learning1Icon = (props) => {
	return <Learning1 className={props.iconClass} onClick={props.onClick} />;
};
export const Learning2Icon = (props) => {
	return <Learning2 className={props.iconClass} onClick={props.onClick} />;
};
export const Learning3Icon = (props) => {
	return <Learning3 className={props.iconClass} onClick={props.onClick} />;
};
export const Learning4Icon = (props) => {
	return <Learning4 className={props.iconClass} onClick={props.onClick} />;
};
export const QuestionIcon = (props) => {
	return <Question className={props.iconClass} onClick={props.onClick} />;
};

// Ranking
export const RankCommBlackIcon = (props) => {
	return <RankCommBlack className={props.iconClass} onClick={props.onClick} />;
};
export const RankCommWhiteIcon = (props) => {
	return <RankCommWhite className={props.iconClass} onClick={props.onClick} />;
};

// Buttons
export const PrintIcon = (props) => {
	return <Print className={props.iconClass} onClick={props.onClick} />;
};

// FONTAWESOME
// ICONS
export const CaretDownIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M310.625 246.625L182.625 374.625C176.375 380.875 168.188 384 160 384S143.625 380.875 137.375 374.625L9.375 246.625C0.219 237.469 -2.516 223.719 2.438 211.75S19.063 192 32 192H288C300.938 192 312.609 199.781 317.563 211.75S319.781 237.469 310.625 246.625Z' />
		</svg>
	);
};
export const CaretRightIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 256 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M118.625 105.375L246.625 233.375C252.875 239.625 256 247.812 256 256C256 264.187 252.875 272.375 246.625 278.625L118.625 406.625C109.469 415.781 95.719 418.516 83.75 413.562S64 396.937 64 384V128C64 115.062 71.781 103.391 83.75 98.437S109.469 96.219 118.625 105.375Z' />
		</svg>
	);
};
export const CaretUpIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M9.375 265.375L137.375 137.375C143.625 131.125 151.813 128 160 128S176.375 131.125 182.625 137.375L310.625 265.375C319.781 274.531 322.516 288.281 317.563 300.25S300.938 320 288 320H32C19.063 320 7.391 312.219 2.438 300.25S0.219 274.531 9.375 265.375Z' />
		</svg>
	);
};
export const GetSupportIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M213.125 32H106.656C47.844 32 0 79.844 0 138.656V160C0 168.844 7.156 176 16 176S32 168.844 32 160V138.656C32 97.484 65.5 64 106.656 64H213.125C254.406 64 288 97.578 288 138.859C288 165.859 273.375 190.859 249.844 204.109L152.469 258.891C137.375 267.391 128 283.422 128 300.719V336C128 344.844 135.156 352 144 352S160 344.844 160 336V300.719C160 294.953 163.125 289.609 168.156 286.766L265.531 231.984C299.125 213.078 320 177.391 320 138.859C320 79.938 272.062 32 213.125 32ZM144 400C126.326 400 112 414.326 112 432S126.326 464 144 464S176 449.674 176 432S161.674 400 144 400Z' />
		</svg>
	);
};
export const UploadIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 640 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M571.75 238.75C574.5 228.875 576 218.625 576 208C576 146.125 525.875 96 464 96C447.25 96 431.125 99.625 416 106.75C384.375 61.75 331.75 32 272 32C177.625 32 100.25 106.5 96.25 200.25C39.25 220.25 0 274.25 0 336C0 415.625 64.375 480 144 480H512C582.75 480 640 422.75 640 352C640 305 614.25 261.25 571.75 238.75ZM507.541 448H149.473C91.164 448 38.564 405.836 32.609 347.832C26.795 291.215 63.266 241.938 114.271 227.975C122.639 225.684 128.051 217.715 128.002 209.039C128 208.705 128 208.369 128 208.033C127.99 147.34 165.129 91.375 222.709 72.186C294.18 48.365 361.916 78.912 394.988 133.078C400.973 142.881 413.342 145.07 423.207 139.189C440.092 129.125 460.986 125.121 483.15 130.201C509.998 136.355 532.596 157.201 540.406 183.615C546.205 203.227 544.713 222.357 537.898 238.775C533.762 248.744 539.959 259.525 549.883 263.771C587.223 279.746 612.301 318.83 607.387 363.018C601.902 412.318 557.146 448 507.541 448ZM251.312 267.312L304 214.625V384C304 392.844 311.156 400 320 400S336 392.844 336 384V214.625L388.688 267.312C394.938 273.562 405.063 273.562 411.312 267.312S417.562 250.937 411.312 244.688L331.312 164.688C328.188 161.562 324.094 160 320 160S311.812 161.562 308.688 164.688L228.688 244.688C222.438 250.938 222.438 261.063 228.688 267.312S245.062 273.562 251.312 267.312Z' />
		</svg>
	);
};
export const PaperPlaneIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M498.123 5.629C492.68 1.891 486.352 0 480.002 0C474.541 0 469.065 1.398 464.123 4.223L16.123 260.221C5.53 266.268 -0.689 277.814 0.061 289.986S8.436 312.846 19.686 317.533L122.911 360.543L184.623 507.109L184.899 506.992C185.692 508.873 186.916 510.533 188.858 511.359C189.873 511.797 190.936 512 191.998 512C194.03 512 196.014 511.234 197.545 509.766L283.297 427.369L403.686 477.531C407.655 479.188 411.811 480 415.998 480C421.405 480 426.811 478.625 431.655 475.906C440.248 471.078 446.123 462.594 447.623 452.859L511.623 36.863C513.498 24.723 508.248 12.582 498.123 5.629ZM16.032 289.002C15.653 282.861 18.727 277.158 24.063 274.113L472.065 18.113C472.899 17.637 473.903 17.703 474.801 17.385L124.84 344.014L25.84 302.764C20.252 300.436 16.403 295.035 16.032 289.002ZM411.508 98.33L185.764 378.984C184.623 380.406 183.998 382.172 183.998 384V464.375L137.545 354.047L411.508 98.33ZM199.998 485.219V392.664L267.188 420.658L199.998 485.219ZM431.811 450.422C431.055 455.324 428.143 459.527 423.823 461.953C421.438 463.293 418.733 464 415.998 464C413.881 464 411.811 463.584 409.84 462.762L206.918 378.211L492.567 23.086C494.924 26.424 496.452 30.279 495.811 34.43L431.811 450.422Z' />
		</svg>
	);
};
export const ChevronIconLeft = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M206.668 464.592L22.688 272.594C18.219 267.969 16 261.969 16 256.001S18.219 244.032 22.688 239.407L206.668 47.409C215.82 37.815 231.008 37.503 240.57 46.69C250.195 55.815 250.508 71.065 241.289 80.596L73.242 256.001L241.289 431.405C250.508 440.905 250.195 456.186 240.57 465.311C231.008 474.499 215.82 474.186 206.668 464.592Z' />
		</svg>
	);
};
export const ChevronIconRight = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M113.333 47.409L297.314 239.407C301.783 244.032 304.001 250.032 304.001 256.001S301.783 267.969 297.314 272.594L113.333 464.592C104.181 474.186 88.994 474.499 79.431 465.311C69.806 456.186 69.494 440.936 78.712 431.405L246.759 256.001L78.712 80.596C69.494 71.096 69.806 55.815 79.431 46.69C88.994 37.503 104.181 37.815 113.333 47.409Z' />
		</svg>
	);
};
export const Checkmark = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M480.969 128.969L208.969 400.969C204.281 405.656 198.156 408 192 408S179.719 405.656 175.031 400.969L31.031 256.969C21.656 247.594 21.656 232.406 31.031 223.031S55.594 213.656 64.969 223.031L192 350.062L447.031 95.031C456.406 85.656 471.594 85.656 480.969 95.031S490.344 119.594 480.969 128.969Z' />
		</svg>
	);
};
export const ErrorIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M256 16C123.451 16 16 123.451 16 256S123.451 496 256 496S496 388.549 496 256S388.549 16 256 16ZM64 256C64 211.703 79.225 170.979 104.52 138.457L373.543 407.48C341.021 432.775 300.297 448 256 448C150.131 448 64 361.869 64 256ZM407.48 373.543L138.457 104.52C170.979 79.225 211.703 64 256 64C361.869 64 448 150.131 448 256C448 300.297 432.775 341.021 407.48 373.543Z' />
		</svg>
	);
};
export const CircleSolidIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M496 256C496 388.548 388.548 496 256 496S16 388.548 16 256S123.452 16 256 16S496 123.452 496 256Z' />
		</svg>
	);
};
export const PlusIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'>
			Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License -
			https://fontawesome.com/license (Commercial License)
			<path d='M432 256C432 264.844 424.844 272 416 272H240V448C240 456.844 232.844 464 224 464S208 456.844 208 448V272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H208V64C208 55.156 215.156 48 224 48S240 55.156 240 64V240H416C424.844 240 432 247.156 432 256Z' />
		</svg>
	);
};
export const SaveIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z' />
		</svg>
	);
};
export const UserClockIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 640 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z' />
		</svg>
	);
};
export const UserHeadsetIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M320 352h-23.1a174.08 174.08 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM48 224a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.13 329.87 0 224 0S32 86.13 32 192v16a16 16 0 0 0 16 16zm160 0h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64z' />
		</svg>
	);
};
