import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import Overlay from '../../components/Overlay/Overlay';
import config from '../../config';
import MemberEditModal from '../../components/Modals/MemberEditModal';

export default function MembersList() {
	const [showEdit, setShowEdit] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [loadOverlay, setLoadOverlay] = useState(false);
	const [permissions, setPermissions] = useState({});
	const [role, setRole] = useState(null);
	const [member, setMember] = useState(null);

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	function deleteMember(uuid) {
		Axios({
			url: `${config.apiv1}/team/team.update/member.remove`,
			method: 'PATCH',
			data: {
				userUuid: uuid,
				teamUuid: teamState.currentTeam.teamUuid,
			},
		})
			.catch((err) => {
				console.log(err);
				return err;
			})
			.then((res) => {
				if (res?.data?.ok) {
					window.location.reload();
				}
			});
	}

	function patchMember(member, permissions, role) {
		Axios({
			url: `${config.apiv1}/team/team.update/member.update`,
			method: 'PATCH',
			data: {
				userUuid: member.userUuid,
				permissions: permissions,
				role: role,
				teamUuid: teamState.currentTeam.teamUuid,
			},
		})
			.catch((err) => {
				console.log(err);
				return err;
			})
			.then((res) => {
				if (res?.data?.ok) {
					window.location.reload();
				}
			});
	}

	function showOverlaySetter(bool) {
		setShowEdit(bool);
	}

	const handlePermissionsInput = (e) => {
		const { name, checked } = e.target;
		console.log(name, checked);
		setPermissions((permissions) => ({
			...permissions,
			[name]: checked,
		}));
	};

	return (
		<div className='membersListContainer'>
			<Overlay loadOverlay={loadOverlay} showOverlay={showEdit} setShowOverlay={showOverlaySetter} />
			{showEdit && (
				<MemberEditModal
					role={role || {}}
					permissions={permissions || {}}
					updateLoading={updateLoading}
					member={member}
					setShowEdit={setShowEdit}
					setRole={setRole}
					setPermissions={setPermissions}
					handlePermissionsInput={handlePermissionsInput}
					patchMember={patchMember}
					setUpdateLoading={setUpdateLoading}
				/>
			)}
			<h2>Members list</h2>
			<div className='membersList'>
				{teamState.currentTeam.members.map((member, key) => {
					return (
						<React.Fragment key={key}>
							<div className='memberListItem'>
								<h4>{`Name: ${member.firstname} ${member.lastname}`}</h4>
								<h4>{`Email: ${member.email}`}</h4>
								<div>
									<button
										className='functionalButtonSecondary'
										disabled={member.userUuid === splitUserId(userState.currUser.sub)}
										onClick={() => {
											deleteMember(member.userUuid);
										}}>
										Remove
									</button>
									<button
										className='functionalButtonSecondary'
										disabled={member.userUuid === splitUserId(userState.currUser.sub)}
										onClick={() => {
											setShowEdit(true);
											setLoadOverlay(true);
											setMember(member);
											setRole(member.role);
											setPermissions(member.permissions);
										}}>
										Edit
									</button>
								</div>
							</div>
							<img src={member.picture} alt='' />
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
}
