import Axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

export default function InvitesList() {
	const [loaded, setLoaded] = useState(false);
	const [invitations, setInvitations] = useState(null);

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	function checkStatusStyle(status) {
		switch (status) {
			case 'accepted':
				return 'statusGreen';
			case 'declined':
				return 'statusRed';
			case 'pending':
				return 'statusYellow';
			default:
				return '';
		}
	}

	useEffect(() => {
		let mounted = true;
		function fetchInvitations() {
			Axios({
				url: `${config.apiv1}/invitation/invitations.read/${teamState.currentTeam.teamUuid}`,
				method: 'GET',
			}).then((res) => {
				if (res?.data?.data && mounted) {
					setInvitations(res.data.data);
					setLoaded(true);
				}
			});
		}
		if (mounted) {
			fetchInvitations();
		}
		return () => (mounted = false);
	}, [teamState, userState]);

	return (
		<div className='invitationsListContainer'>
			<h2>Current invites</h2>
			{loaded && (
				<div className='invitationsList'>
					{invitations.map((invitation, key) => {
						return (
							<div key={key}>
								<h3>{invitation.email}</h3>
								<h3 className={checkStatusStyle(invitation.status)}>{invitation.status}</h3>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
