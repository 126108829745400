import React from 'react';
import Loading from '../Loading/Loading';
import OrderEditForm from '../Forms/OrderEditForm';

export default function OrderEditModal(props) {
	const { orderData, setShow, updateLoading, setUpdateLoading, patchOrder, setShowOverlay } = props || {};

	return (
		<div className='wideModal'>
			{!updateLoading ? (
				<div className='mainFormCont'>
					<OrderEditForm />
					<div className='btnCont'>
						<input
							type='button'
							className='functionalButtonPrimary'
							onClick={() => {
								patchOrder();
								setUpdateLoading(true);
							}}
							defaultValue='Update order'
						/>
						<input
							type='button'
							className='functionalButtonSecondary marginLeft-10'
							onClick={() => {
								setShow(false);
								setShowOverlay(false);
							}}
							defaultValue='Cancel'
						/>
					</div>
				</div>
			) : (
				<>
					<Loading type='circle' />
				</>
			)}
		</div>
	);
}
