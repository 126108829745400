import React from 'react';
import { ArrowNextRightIcon } from '../SVGIcons/SVGIcons';

export default function ArrowRight(props) {
	const { disabled, color, next } = props || {};
	return (
		<div className='arrowRight' style={{ fill: color }} onClick={disabled ? null : next}>
			<ArrowNextRightIcon
				iconClass={`${color === 'white' ? 'circleArrowRightIconWhite' : 'circleArrowRightIcon'}${
					disabled ? ' disabledDiv' : ''
				}`}
			/>
		</div>
	);
}
