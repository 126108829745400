import React from 'react';
import { Link } from 'react-router-dom';
import DirectedTutorialModal from '../../Tutorial/DirectedTutorialModal';

export default function LearningZoneHeader() {
	return (
		<div className='carouselHeader'>
			<DirectedTutorialModal
				page='dashboard'
				order={6}
				header='Getting support'
				size='wide'
				body={
					<>
						We have a great <a href='/tutorials/en/tutorial'>Learning Zone</a> full of articles to help you.
						We are also available to
						<a href='/tutorials'> chat</a> if you need us. We also would love to hear your{' '}
						<a href='/tutorials/en/tutorial'>feedback</a> at any time. You can replay this tour at any time
						by clicking on the question mark and selecting 'start tour'.
					</>
				}
				isLast
			/>
			<h2>Latest from the Learning Zone</h2>
			<div className='carouselHeaderBtnCont'>
				<Link to={`/tutorials/en/tutorial`}>
					<button className='dashboardBtnSeeAll'>Visit Learning Zone</button>
				</Link>
			</div>
		</div>
	);
}
