import React, { useEffect, useState } from 'react';
import { CaretDownIcon, CaretUpIcon } from '../SVGIcons/SVGIcons';

export default function SelectCustom(props) {
	const { options, placeholder, warning, htmlOptions, callback, fieldName, value } = props || {};

	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(value);
	const [parsedOption, setParsedOption] = useState(null);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (selectedOption?.name) {
				setParsedOption(selectedOption?.name.slice(0, 28));
			} else if (placeholder) {
				setParsedOption(placeholder);
			}

			if (options || htmlOptions) {
				setLoaded(true);
			}

			if (options) {
				for (let i = 0; i < options.length; i++) {
					if (options[i].value === value) {
						setParsedOption(options[i].props.children);
					}
				}
			} else if (htmlOptions) {
				for (let i = 0; i < htmlOptions.length; i++) {
					if (htmlOptions[i].props.value === value) {
						setParsedOption(htmlOptions[i].props.children);
					}
				}
			}
		}
		return () => (mounted = false);
	}, [selectedOption]);

	return (
		<>
			{loaded && (
				<div
					className={`selectCustom ${open ? 'focus' : ''} ${!warning && selectedOption ? 'success' : ''} ${
						warning ? 'warning' : ''
					}`}
					onClick={() => {
						setOpen(!open);
					}}
					onBlur={() => {
						setOpen(false);
					}}
					tabIndex={0}>
					<p>{parsedOption}</p>
					<div className={`options ${!open ? 'hidden' : ''}`}>
						<div className='option'>{placeholder || value || 'Select...'}</div>
						{htmlOptions ? (
							<>
								{htmlOptions.map((option, key) => {
									return (
										<div
											className='option'
											key={key}
											onClick={() => {
												setSelectedOption({
													name: option.props.children,
													value: option.props.value,
												});
												callback(option.props, fieldName);
											}}>
											{option.props.children}
										</div>
									);
								})}
							</>
						) : (
							<>
								{options.map((option, key) => {
									return (
										<div
											className='option'
											key={key}
											onClick={() => {
												setSelectedOption(option);
												callback(option, fieldName);
											}}>
											{option.name}
										</div>
									);
								})}
							</>
						)}
					</div>
					{open ? (
						<CaretUpIcon iconClass={'caretIconAbsoluteTurq'} />
					) : (
						<CaretDownIcon
							iconClass={`caretIconAbsoluteGray ${
								!warning && selectedOption && 'caretIconAbsoluteSuccess'
							} ${warning && 'caretIconAbsoluteWarning'}`}
						/>
					)}
				</div>
			)}
		</>
	);
}
