import React from 'react';
import ReactPaginate from 'react-paginate';
import { ArrowNextRightIcon, ArrowPrevLeftIcon } from '../SVGIcons/SVGIcons';

export default function ListingPagination(props) {
	const { pages, handleClick } = props || {};

	return (
		<div className='paginationContainer'>
			<ReactPaginate
				previousLabel={<ArrowPrevLeftIcon iconClass='chevronIconGray' />}
				nextLabel={<ArrowNextRightIcon iconClass='chevronIconGray' />}
				breakLabel={'...'}
				breakClassName={'paginationBreak'}
				pageCount={pages}
				marginPagesDisplayed={2}
				pageRangeDisplayed={5}
				onPageChange={handleClick}
				containerClassName={'paginationContainer'}
				pageClassName={'paginationPage'}
				pageLinkClassName={'paginationLink'}
				activeClassName={'paginationActive'}
				previousClassName={'paginationPrevious'}
				nextClassName={'paginationNext'}
				disabledClassName={'disabledDiv'}
			/>
		</div>
	);
}
