import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import OrderForm from './OrderForm';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';

export default function CreateOrderPage() {
	const [suppliers, setSuppliers] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const { teamState } = useContext(CurrentTeamContext);

	async function fetchTrustedSuppliers() {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`,
				method: 'GET',
			});
			if (response?.data?.data?.length > 0) {
				setSuppliers(response?.data?.data);
			}
			setLoaded(true);
		} catch (err) {
			setLoaded(true);
			console.log(err);
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchTrustedSuppliers();
		}
		return () => (mounted = false);
	}, []);

	return (
		<div>
			<OrderForm chooseSupplier={true} suppliers={suppliers} loaded={loaded} />
		</div>
	);
}
