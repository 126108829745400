import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../config';
import { CurrentUserContext } from '../../../../contexts/CurrentUserContext';
import {
	GotoDiscBlack40Icon,
	Learning1Icon,
	Learning2Icon,
	Learning3Icon,
	Learning4Icon,
} from '../../../../components/SVGIcons/SVGIcons';

export default function LearningZoneCarousel(props) {
	const { settings, currOffset, carouselWidth, setTotalWidth } = props.values || {};
	const [learningCards, setLearningCards] = useState([
		{ card: <Learning1Icon iconClass='learningIcon' /> },
		{ card: <Learning2Icon iconClass='learningIcon' /> },
		{ card: <Learning3Icon iconClass='learningIcon' /> },
		{ card: <Learning4Icon iconClass='learningIcon' /> },
		{ card: <Learning1Icon iconClass='learningIcon' /> },
		{ card: <Learning2Icon iconClass='learningIcon' /> },
		{ card: <Learning3Icon iconClass='learningIcon' /> },
		{ card: <Learning4Icon iconClass='learningIcon' /> },
	]);

	let { userState } = useContext(CurrentUserContext);

	useEffect(() => {
		let mounted = true;
		// if (userState.isAuthenticated && mounted) {
		//     async function fetchSuppliers() {
		//         try {
		//             const response = await Axios({
		//                 url: `${config.apiv1}/team/team.read`,
		//                 method: 'GET'
		//             })
		//             setSuppliers(response.data.data);
		//             setTotalWidth(-((response.data.data.length) / 4) * settings.max.carouselWidth);
		//             setLoaded(true);
		//         } catch (err) {
		//             console.log(err)
		//             return err;
		//         }
		//     }
		//     fetchSuppliers();
		// }
		if (mounted) {
			setTotalWidth(-(learningCards.length / 4) * settings.max.carouselWidth);
		}
		return () => (mounted = false);
	}, [settings, userState]);

	return (
		<div
			className='learningZoneCont'
			style={{
				transform: `translateX(${currOffset + carouselWidth}px)`,
				transition: '0.6s ease-in-out',
				width: `${carouselWidth}`,
			}}>
			{learningCards.map((learningCard, key) => {
				if (learningCard) {
					return (
						<React.Fragment key={key}>
							<Link to={`/tutorials/en/tutorial`}>
								<div className='learningCard'>
									<div className='cardImg'>{learningCard.card}</div>
									<div className='cardFooter'>
										<h4>Tutorial</h4>
										<GotoDiscBlack40Icon iconClass={'gotoDiscBlackIcon'} />
									</div>
								</div>
							</Link>
						</React.Fragment>
					);
				} else {
					return <React.Fragment key={key}></React.Fragment>;
				}
			})}
		</div>
	);
}
