import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';

export default function ProcessingModal(props) {
	const { loading, show, message, uri, setShow, reloadBtnTxt, canReload } = props || {};
	return (
		<>
			{show && (
				<div className='fixedModal'>
					{!loading ? (
						<div>
							<h3>{message}</h3>
							<div className='btnCont'>
								<Link to={'/'}>
									<button className='functionalButtonSecondary'>Go to dashboard</button>
								</Link>
								{uri && (
									<Link to={uri}>
										<button className='functionalButtonSecondary'>Go to order</button>
									</Link>
								)}
								{canReload && (
									<button
										className='functionalButtonPrimary'
										onClick={() => {
											window.location.reload();
										}}>
										{reloadBtnTxt}
									</button>
								)}
								{setShow && (
									<button
										className='functionalButtonSecondary'
										onClick={() => {
											setShow(false);
										}}>
										Close
									</button>
								)}
							</div>
						</div>
					) : (
						<>
							<Loading type='circle' />
						</>
					)}
				</div>
			)}
		</>
	);
}
