import React from 'react';
import currencyOptions from '../Options/currencyOptions';
import companyTypeOptions from '../Options/companyTypeOptions';
import taxOptions from '../Options/taxOptions';
import SelectCustom from '../Select/SelectCustom';
import { Checkmark, ErrorIcon, UploadIcon } from '../SVGIcons/SVGIcons';
import langOptions from '../Options/langOptions';

export default function TeamForm(props) {
	const {
		scrollToBottom,
		scrollToRef,
		handleSubmit,
		errorMessage,
		formData,
		handleFormData,
		handleFormSelectData,
		registeredAddress,
		handleRegisteredAddressInput,
		collection,
		handleCollectionInput,
		deliveryAddress,
		handleDeliveryAddressInput,
		isDeliveryAddress,
		setIsDeliveryAddress,
		formTitle,
		registering,
	} = props || {};

	const { pageEnd, pageStart, acceptCollectRef, isCompanyRef, isDeliveryAddressRef } = props.refs;

	return (
		<div className='mainFormCont'>
			<div className='pageEnd' ref={pageEnd}></div>
			<form onSubmit={handleSubmit}>
				<h2>{formTitle}</h2>
				<h3 ref={pageStart} className={`errorMsg ${errorMessage ? '' : 'hidden'}`}>
					{errorMessage}
				</h3>
				<div className='formSection'>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='teamName'>
							Display name
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								name='teamName'
								defaultValue={formData.teamName || null}
								onChange={(e) => handleFormData(e.target.name, e.target.value)}
								required
							/>
							{formData.teamName ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='teamInitials'>
							Display initials
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								name='teamInitials'
								maxLength='3'
								defaultValue={formData.teamInitials || null}
								onChange={(e) => handleFormData(e.target.name, e.target.value)}
								required
							/>
							{formData.teamInitials ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='formProfileImg'>
							Upload logo
						</p>
						<div className='fileInput'>
							<input
								id='formProfileImg'
								type='file'
								name='formProfileImg'
								onChange={(e) => handleFormData(e.target.name, e.target.files[0])}
							/>
							<label htmlFor='formProfileImg'>
								<UploadIcon iconClass='uploadIcon' />
								<span>Choose image</span>
							</label>
						</div>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='formPicture'>
							Shop picture
						</p>
						<div className='fileInput'>
							<input
								id='formPicture'
								type='file'
								name='formPicture'
								onChange={(e) => handleFormData(e.target.name, e.target.files[0])}
							/>
							<label htmlFor='formPicture'>
								<UploadIcon iconClass='uploadIcon' />
								<span>Choose image</span>
							</label>
						</div>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='companyDescriptionHeader'>
							Team description header
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								name='companyDescriptionHeader'
								defaultValue={formData.companyDescriptionHeader || null}
								onChange={(e) => handleFormData(e.target.name, e.target.value)}
								required
							/>
							{formData.companyDescriptionHeader ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<div className='formColumnCenter'>
						<div className='fieldTextLeftContainer'>
							<p className='extraBold' htmlFor='companyDescription'>
								Team description
							</p>
						</div>
						<textarea
							className='formTextarea'
							name='companyDescription'
							defaultValue={formData.companyDescription || null}
							onChange={(e) => handleFormData(e.target.name, e.target.value)}
							required
						/>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='tax'>
							Tax jurisdiction
						</p>
						<SelectCustom
							htmlOptions={taxOptions}
							callback={handleFormSelectData}
							value={formData.tax}
							fieldName={'tax'}
							placeholder={'Select...'}
						/>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='spokenLanguage'>
							Language
						</p>
						<SelectCustom
							htmlOptions={langOptions}
							callback={handleFormSelectData}
							value={formData.spokenLanguage}
							fieldName={'spokenLanguage'}
							placeholder={'Select...'}
						/>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='phoneNumber'>
							Phone number
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								name='phoneNumber'
								type='tel'
								defaultValue={formData.phoneNumber || null}
								onChange={(e) => handleFormData(e.target.name, e.target.value)}
								required
							/>
							{formData.phoneNumber ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='currency'>
							Currency
						</p>
						<SelectCustom
							htmlOptions={currencyOptions}
							callback={handleFormSelectData}
							value={formData.currency}
							fieldName={'currency'}
							placeholder={'Select...'}
						/>
					</div>
				</div>
				<div className='formSection'>
					<h3>Selling</h3>
					<div className='formRowTop'>
						<p className='extraBold' htmlFor='acceptCollect'>
							If selling, do you allow collection?
						</p>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input
									type='radio'
									name='acceptCollect'
									checked={formData.acceptCollect || ''}
									onChange={(e) => {
										handleFormData(e.target.name, true);
										scrollToRef(acceptCollectRef);
									}}
								/>
								<div className='customRadio'></div>
								<span>Yes</span>
							</label>
							<label className='radioLabel'>
								<input
									type='radio'
									name='acceptCollect'
									checked={!formData.acceptCollect || ''}
									onChange={(e) => handleFormData(e.target.name, false)}
								/>
								<div className='customRadio'></div>
								<span>No</span>
							</label>
						</div>
					</div>
					{formData.acceptCollect && (
						<>
							<div ref={acceptCollectRef} className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressName'>
									Collection point:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionPoint'
									defaultValue={collection?.collectionPoint}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressStreet'>
									Street:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionStreet'
									defaultValue={collection?.collectionStreet}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCity'>
									City:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionCity'
									defaultValue={collection?.collectionCity}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressPostalCode'>
									Postal code:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionPostalCode'
									defaultValue={collection?.collectionPostalCode}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCountry'>
									Country:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionCountry'
									defaultValue={collection?.collectionCountry}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCountry'>
									Opening hours:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionHours'
									defaultValue={collection?.collectionHours}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCountry'>
									Instructions:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='collectionInstructions'
									defaultValue={collection?.collectionInstructions}
									onChange={handleCollectionInput}
								/>
								<span className='inputIcon'></span>
							</div>
						</>
					)}
				</div>
				<div className='formSection'>
					<h3>Company</h3>
					<div className='formRowCenter'>
						<p className='extraBold' htmlFor='isCompany'>
							Is the team pertaining to a company?
						</p>
						<div className='formChoices'>
							<label className='checkboxLabel'>
								<input
									type='checkbox'
									name='isCompany'
									checked={formData.isCompany || ''}
									onChange={(e) => {
										handleFormData(e.target.name, e.target.checked);
										scrollToRef(isCompanyRef);
									}}
								/>
								<div className='customCheckbox'></div>
								<span>Yes</span>
							</label>
						</div>
					</div>

					{formData.isCompany && (
						<>
							<div ref={isCompanyRef} className='formRowCenter'>
								<p className='extraBold' htmlFor='companyName'>
									Company name
								</p>
								<div className='inputContainer'>
									<input
										className='textFieldInput'
										name='companyName'
										defaultValue={formData.companyName || ''}
										onChange={(e) => handleFormData(e.target.name, e.target.value)}
										required
									/>
									{formData.companyName ? (
										<Checkmark iconClass={'inputIcon'} />
									) : (
										<ErrorIcon iconClass={'inputIcon'} />
									)}
								</div>
							</div>
							<div className='formRowCenter'>
								<p className='extraBold' htmlFor='companyType'>
									Company type
								</p>
								<SelectCustom
									htmlOptions={companyTypeOptions}
									callback={handleFormSelectData}
									value={formData.companyType}
									fieldName={'companyType'}
									placeholder={'Select...'}
								/>
							</div>
							<div className='formRowCenter'>
								<p className='extraBold' htmlFor='companyCountry'>
									Country of registration
								</p>
								<SelectCustom
									htmlOptions={taxOptions}
									callback={handleFormSelectData}
									value={formData.companyCountry}
									fieldName={'companyCountry'}
									placeholder={'Select...'}
								/>
							</div>
							<div className='formRowCenter'>
								<p className='extraBold' htmlFor='companyNumber'>
									Company number
								</p>
								<div className='inputContainer'>
									<input
										className='textFieldInput'
										name='companyNumber'
										type='text'
										defaultValue={formData.companyNumber || ''}
										onChange={(e) => handleFormData(e.target.name, e.target.value)}
										required
									/>
									{formData.companyNumber ? (
										<Checkmark iconClass={'inputIcon'} />
									) : (
										<ErrorIcon iconClass={'inputIcon'} />
									)}
								</div>
							</div>
							<h3>Registered Company Address</h3>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressName'>
									Name:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='name'
									defaultValue={registeredAddress?.name}
									onChange={handleRegisteredAddressInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressStreet'>
									Street:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='street'
									defaultValue={registeredAddress?.street}
									onChange={handleRegisteredAddressInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCity'>
									City:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='city'
									defaultValue={registeredAddress?.city}
									onChange={handleRegisteredAddressInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressPostalCode'>
									Postal code:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='postalCode'
									defaultValue={registeredAddress?.postalCode}
									onChange={handleRegisteredAddressInput}
								/>
								<span className='inputIcon'></span>
							</div>
							<div className='formAddressRowCenter'>
								<p className='extraBold' htmlFor='addressCountry'>
									Country:
								</p>
								<input
									className='textFieldInput'
									type='text'
									name='country'
									defaultValue={registeredAddress?.country}
									onChange={handleRegisteredAddressInput}
								/>
								<span className='inputIcon'></span>
							</div>
							{registering && (
								<>
									<div className='formRowTop'>
										<p className='extraBold' htmlFor='acceptCollect'>
											Is this also your delivery address?
										</p>
										<div className='formChoices'>
											<label className='radioLabel'>
												<input
													type='radio'
													name='isDeliveryAddress'
													checked={isDeliveryAddress || false}
													onChange={() => setIsDeliveryAddress(true)}
													required
												/>
												<div className='customRadio'></div>
												<span>Yes</span>
											</label>
											<label className='radioLabel'>
												<input
													type='radio'
													name='isDeliveryAddress'
													checked={isDeliveryAddress === false || false}
													onChange={() => {
														setIsDeliveryAddress(false);
														scrollToRef(isDeliveryAddressRef);
													}}
													required
												/>
												<div className='customRadio'></div>
												<span>No</span>
											</label>
										</div>
									</div>
									{isDeliveryAddress === false && (
										<>
											<h3>Delivery Address</h3>
											<div ref={isDeliveryAddressRef} className='formAddressRowCenter'>
												<p className='extraBold' htmlFor='addressName'>
													Name:
												</p>
												<input
													className='textFieldInput'
													type='text'
													name='name'
													defaultValue={deliveryAddress?.name}
													onChange={handleDeliveryAddressInput}
												/>
												<span className='inputIcon'></span>
											</div>
											<div className='formAddressRowCenter'>
												<p className='extraBold' htmlFor='addressStreet'>
													Street:
												</p>
												<input
													className='textFieldInput'
													type='text'
													name='street'
													defaultValue={deliveryAddress?.street}
													onChange={handleDeliveryAddressInput}
												/>
												<span className='inputIcon'></span>
											</div>
											<div className='formAddressRowCenter'>
												<p className='extraBold' htmlFor='addressCity'>
													City:
												</p>
												<input
													className='textFieldInput'
													type='text'
													name='city'
													defaultValue={deliveryAddress?.city}
													onChange={handleDeliveryAddressInput}
												/>
												<span className='inputIcon'></span>
											</div>
											<div className='formAddressRowCenter'>
												<p className='extraBold' htmlFor='addressPostalCode'>
													Postal code:
												</p>
												<input
													className='textFieldInput'
													type='text'
													name='postalCode'
													defaultValue={deliveryAddress?.postalCode}
													onChange={handleDeliveryAddressInput}
												/>
												<span className='inputIcon'></span>
											</div>
											<div className='formAddressRowCenter'>
												<p className='extraBold' htmlFor='addressCountry'>
													Country:
												</p>
												<input
													className='textFieldInput'
													type='text'
													name='country'
													defaultValue={deliveryAddress?.country}
													onChange={handleDeliveryAddressInput}
												/>
												<span className='inputIcon'></span>
											</div>
										</>
									)}
								</>
							)}
						</>
					)}
				</div>
				<div className='formSection'>
					<h3>Invoicing</h3>
					<div className='formRowTop'>
						<p className='extraBold' htmlFor='acceptCollect'>
							Order reference type
						</p>
						<div className='formChoices'>
							<label className='radioLabel'>
								<input
									type='radio'
									name='orderRefFormat'
									checked={formData.orderRefFormat === 1}
									onChange={(e) => {
										handleFormData(e.target.name, 1);
									}}
								/>
								<div className='customRadio'></div>
								<span>Team Initials - Invoice Count</span>
							</label>
							<label className='radioLabel'>
								<input
									type='radio'
									name='orderRefFormat'
									checked={formData.orderRefFormat === 2}
									onChange={(e) => handleFormData(e.target.name, 2)}
								/>
								<div className='customRadio'></div>
								<span>Team Initials - Invoice Count - Date</span>
							</label>
						</div>
					</div>
				</div>
				<div className='formRowCenter'>
					<p className='extraBold' htmlFor='isPublic'>
						Do you wish to make your Team public? (This can always be changed later)
					</p>
					<div className='formChoices'>
						<label className='checkboxLabel'>
							<input
								type='checkbox'
								name='isPublic'
								checked={formData.isPublic || false}
								onChange={(e) => {
									handleFormData(e.target.name, e.target.checked);
									scrollToBottom();
								}}
							/>
							<div className='customCheckbox'></div>
							<span>Yes</span>
						</label>
					</div>
				</div>
				<div className='formSubmit'>
					<input className='submitBtn' type='submit' value='Save' />
				</div>
			</form>
		</div>
	);
}
