import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	CartBuyDiscF2Icon,
	FeedbackDiscF2Icon,
	InviteDiscF2Icon,
	SupplierDiscF2Icon,
	TeamRegisterDiscF2Icon,
} from '../../../components/SVGIcons/SVGIcons';
import DirectedTutorialModal from '../Tutorial/DirectedTutorialModal';

export default function Topbar() {
	const navigate = useNavigate();

	return (
		<div className='topbar'>
			<div
				className='link'
				onClick={() => {
					navigate('/suppliers');
				}}>
				<SupplierDiscF2Icon iconClass='supplierDiscF2Icon' />
				<h4>Find a supplier</h4>
			</div>
			<div
				className='link'
				onClick={() => {
					navigate('/invite');
				}}>
				<InviteDiscF2Icon iconClass='inviteDiscF2Icon' />
				<h4>Invite a customer</h4>
			</div>
			<div
				className='link'
				onClick={() => {
					navigate('/order');
				}}>
				<DirectedTutorialModal
					page='dashboard'
					order={5}
					header='Orders'
					position='center'
					body={
						<>
							At any time you can place an order. <a href='/manage'>Find out more about orders</a> and how
							to place one.
						</>
					}
				/>
				<CartBuyDiscF2Icon iconClass='cartBuyDiscF2Icon' />
				<h4>Place an order</h4>
			</div>
			<div
				className='link'
				onClick={() => {
					navigate('/feedback');
				}}>
				<FeedbackDiscF2Icon iconClass='feedbackDiscF2Icon' />
				<h4>Give feedback</h4>
			</div>
			<div
				className='link'
				onClick={() => {
					navigate('/teamRegistration');
				}}>
				<TeamRegisterDiscF2Icon iconClass='teamRegisterDiscF2Icon' />
				<h4>Register a team</h4>
			</div>
		</div>
	);
}
