import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	parseProcessingStatus,
	dateConverter,
	parseOrderPriority,
	getUnreadMessageCount,
	checkFileType,
	sliceString,
} from '../../helpers';

import config from '../../config';
import Loading from '../../components/Loading/Loading';
import ThreeJsModel from '../global/Chat/ThreeJsTest';
import { useLocation, useParams } from 'react-router-dom';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SocketContext } from '../../App';
import {
	BillIcon,
	BookmarkIcon,
	CaretRightIcon,
	FileIcon,
	ListOrderedIcon,
	PlusDiscIcon,
	TrackingIcon,
	TruckLeftIcon,
	UploadIcon,
} from '../../components/SVGIcons/SVGIcons';
import OrderSuborders from './OrderSuborders';
import OrderTracking from './OrderTracking';
import OrderEditModal from '../../components/Modals/OrderEditModal';
import Overlay from '../../components/Overlay/Overlay';
import ImgModal from '../../components/Modals/ImgModal';
import TrustedAddBtn from '../global/TrustedAddBtn/TrustedAddBtn';

export default function Order() {
	const [order, setOrder] = useState({});
	const [modelHidden, setModelHidden] = useState(true);
	const [currViewedFile, setCurrViewedFile] = useState(null);
	const [imgs, setImgs] = useState([]);
	const [generalFiles, setGeneralFiles] = useState([]);
	const [newFiles, setNewFiles] = useState([]);
	const [changeMade, setChangeMade] = useState(false);
	const [showPriceInput, setShowPriceInput] = useState(false);
	const [newOrderData, setNewOrderData] = useState(null);
	const [suggestedPrice, setSuggestedPrice] = useState(null);
	const [suggestedPriceStatus, setSuggestedPriceStatus] = useState(null);
	const [message, setMessage] = useState(null);
	const [isSeller, setIsSeller] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const [showOverlay, setShowOverlay] = useState(false);
	const [imgOpen, setImgOpen] = useState(false);
	const [showImgModal, setShowImgModal] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);

	let params = useParams();
	let navigate = useNavigate();

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);
	const { notificationsState } = useContext(NotificationContext);
	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	const socket = useContext(SocketContext);
	let location = useLocation();

	async function removeFile(file) {
		let newArr = newFiles;
		let i = newArr.indexOf(file);
		newArr.splice(i, i + 1);
		setNewFiles((newArr) => [...newArr]);
		if (!newFiles[0]) {
			setChangeMade(false);
		}
	}

	async function createImgUrls(files) {
		if (files.length > 0) {
			let imgs = [];
			for (let i = 0; i < files.length; i++) {
				if (checkFileType(files[i]?.filename?.split('.')[1]) === 1) {
					let res = await Axios({
						url: `${config.api}/file?path=${files[i].path}`,
						method: 'GET',
						responseType: 'blob',
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						let file = files[i];
						file.url = url;
						imgs.push(file);
					});
				}
			}
			setImgs(imgs);
			setLoaded(true);
		}
	}

	async function patchOrder(data) {
		let formData;
		if (!data) {
			formData = new FormData();
			for (let key in newOrderData) {
				formData.append(key, newOrderData[key]);
			}
			if (newFiles.length > 0) {
				formData.append('fileRef', order.orderUuid);
				for (let i = 0; i < order.files.length; i++) {
					formData.append('files', JSON.stringify(order.files[i]));
				}
				for (let i = 0; i < newFiles.length; i++) {
					formData.append('files', newFiles[i]);
				}
			} else {
				setMessage('Please choose a file for upload');
				return;
			}
		}

		try {
			Axios({
				url: `${config.apiv1}/order/order.update/${order.orderUuid}`,
				method: 'PATCH',
				data: formData || data,
			}).then((res) => {
				if (!res.err) {
					window.location.reload();
				} else {
					console.log(res.err);
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	async function toggleHidden(file, hidden) {
		// Toggles the model viewed for STL between hidden and not hidden
		// in addition to fetching file from server
		if (file) {
			Axios({
				url: `${config.api}/file?path=${file.path}`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				setCurrViewedFile(url);
				setModelHidden(hidden);
			});
		} else {
			setCurrViewedFile(null);
			setModelHidden(hidden);
		}
	}

	function checkFilenameLength(filename, maxLength) {
		if (filename.length > maxLength) {
			return `${sliceString(filename.split('.')[0], 0, maxLength)}...${filename.split('.')[1]}`;
		} else {
			return filename;
		}
	}

	function downloadFile(file) {
		Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.filename);
			document.body.appendChild(link);
			link.click();
		});
	}

	function handleFiles(e) {
		if (!changeMade) {
			setChangeMade(true);
		}
		let files = [];
		for (let i = 0; i < e.target.files.length; i++) {
			files.push(e.target.files[i]);
		}
		setNewFiles(files);
	}

	function handleNewOrderData(name, value) {
		setNewOrderData((newOrderData) => ({
			...newOrderData,
			[name]: value,
		}));
	}

	function fillAndOpenRightSidebar(boxRef) {
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: order });
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 1 });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
		rightSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: { boxRef: boxRef, boxOpen: true },
		});
	}

	function checkSuggestedPrice(orderUuid) {
		// Check if there currently is a notification with a suggested price for order from seller
		Axios({
			url: `${config.apiv1}/notification/notifications.read/${
				teamState.currentTeam.teamUuid
			}?limit=1&sortDate=desc&type=${'order.priceSuggested'}&orderUuid=${orderUuid}`,
			method: 'GET',
		}).then((response) => {
			if (response?.data?.data) {
				console.log(response.data.data);
				setSuggestedPrice(response.data.data[0]);
				setSuggestedPriceStatus(response.data.data[0].notificationData.isAccepted);
			}
		});
	}

	function suggestPrice() {
		// Suggest price to buyer
		socket.emit('suggest-price', {
			price: newOrderData.totalPrice,
			orderUuid: order.orderUuid,
			targetTeamUuid: order.buyerTeamUuid,
			senderTeamUuid: teamState.currentTeam.teamUuid,
			orderRef: order.orderRef,
		});
		setMessage('Price suggestion sent');
	}

	function updateNotificationStatus(data) {
		Axios({
			url: `${config.apiv1}/notification/notification.update/${suggestedPrice.notificationUuid}`,
			method: 'PATCH',
			data: {
				'notificationData.isAccepted': data,
			},
		}).then((response) => {
			if (response?.data) {
				if (response.data.err) {
					console.log(response.data.err);
				}
				if (response.data.ok) {
					if (data === 'declined') {
						socket.emit('decline-price', {
							price: newOrderData.totalPrice,
							orderUuid: order.orderUuid,
							targetTeamUuid: order.sellerTeamUuid,
							senderTeamUuid: teamState.currentTeam.teamUuid,
							orderRef: order.orderRef,
						});
					}
					window.location.reload();
				}
			}
		});
	}

	useEffect(() => {
		let mounted = true;
		const getOrderInfo = async () => {
			function handleGeneralFiles(order) {
				let generalFiles = [];
				for (let i = 0; i < order.files.length; i++) {
					if (checkFileType(order.files[i]?.filename?.split('.')[1]) === 2) {
						generalFiles.push(order.files[i]);
					}
				}
				setGeneralFiles(generalFiles);
			}

			if (userState?.isAuthenticated && teamState?.currentTeam?.teamUuid) {
				try {
					Axios({
						url: `${config.apiv1}/order/order.read/${params.orderId}`,
						method: 'GET',
						responseType: 'stream',
					})
						.then((res) => res.data)
						.then((res) => {
							const data = res.data;
							setOrder(data);
							setIsSeller(data.sellerTeamUuid === teamState.currentTeam.teamUuid);
							handleGeneralFiles(data);
							if (
								teamState?.currentTeam?.teamUuid === data.sellerTeamUuid ||
								teamState?.currentTeam?.teamUuid === data.buyerTeamUuid
							) {
								if (!data.totalPrice) {
									checkSuggestedPrice(data.orderUuid);
								}
								createImgUrls(data?.files);
							} else {
								navigate('/');
							}
						});
				} catch (err) {
					navigate('/');
				}
			} else {
				navigate('/');
			}
		};

		if (mounted) {
			getOrderInfo();
		}

		return () => (mounted = false);
	}, [params]); // eslint-disable-next-line

	useEffect(() => {
		const openFocus = new URLSearchParams(location.search).get('openFocus');
		if (loaded && openFocus) {
			fillAndOpenRightSidebar(openFocus);
		}
	}, [loaded]); // eslint-disable-next-line

	if (order.orderProcessingStatus === 1 && isSeller) {
		navigate('/');
	}

	return (
		<>
			{showEdit && (
				<>
					<OrderEditModal
						setShow={setShowEdit}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
					/>
					<Overlay loadOverlay={showOverlay} showOverlay={showOverlay} />
				</>
			)}
			{showImgModal && (
				<>
					<ImgModal
						setShow={setShowImgModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						src={imgOpen.url}
						downloadImg={downloadFile}
						file={imgOpen}
					/>
					<Overlay loadOverlay={showOverlay} showOverlay={showOverlay} />
				</>
			)}
			{loaded ? (
				<div className={`orderCont ${isSeller ? 'sale' : 'purchase'}`}>
					{suggestedPriceStatus !== null && suggestedPriceStatus === 'declined' && isSeller && (
						<div className='orderNotificationBar'>
							<h4>Last price suggestion was declined by buyer</h4>
						</div>
					)}
					{suggestedPrice &&
						!isSeller &&
						suggestedPriceStatus !== 'accepted' &&
						suggestedPriceStatus !== 'declined' && (
							<div className='orderNotificationBar'>
								<p>Accept proposed price of {suggestedPrice.notificationData.price}£?</p>
								<button
									className='functionalButtonSecondary'
									onClick={() => {
										patchOrder({
											totalPrice: suggestedPrice.notificationData.price,
										});
										updateNotificationStatus('accepted');
									}}>
									Yes
								</button>
								<button
									className='functionalButtonSecondary'
									onClick={() => {
										updateNotificationStatus('declined');
									}}>
									No
								</button>
							</div>
						)}
					{message && (
						<div className='orderNotificationBar'>
							<h4>{message}</h4>
						</div>
					)}
					<div className='floatingHeader'>
						<h2 className='marginLeft-40'>{isSeller ? order?.buyerTeamName : order?.sellerTeamName}</h2>
						<div className='btnCont'>
							<TrustedAddBtn
								supplierUuid={isSeller ? order?.buyerTeamUuid : order?.sellerTeamUuid}
								supplierName={isSeller ? order?.buyerTeamName : order?.sellerTeamName}
								style={2}
							/>
							<button
								className='btn3-primary marginLeft-40'
								onClick={() => {
									setShowEdit(true);
									setShowOverlay(true);
								}}>
								Edit
							</button>
						</div>
					</div>
					<ThreeJsModel toggle={toggleHidden} hidden={modelHidden} file={currViewedFile} />
					<div className='orderDetailsCont'>
						<div className='row'>
							<div className='colHeader'>
								<BookmarkIcon iconClass='orderPageIcon' /> REFERENCES
							</div>
							<div className='colHeader'>
								<TruckLeftIcon iconClass='orderPageIcon' />
								STATUS & DELIVERY
							</div>
							<div className='colHeader'>
								<BillIcon iconClass='orderPageIcon' />
								FINANCIAL OVERVIEW
							</div>
						</div>
						<div className='orderSegment'>
							<div className='col'>
								<div className='segmentText highlight'>
									<p className='label'>Order Ref.</p>{' '}
									<div className='data bold'>{order.orderRef}</div>
								</div>
								{order.sellerHint || order.buyerHint ? (
									<div className='segmentText'>
										<p className='label'>Hint: </p>
										<div className='data bold'>
											{isSeller ? order.sellerHint : order.buyerHint || '...'}
										</div>
									</div>
								) : (
									<></>
								)}
								<div className='segmentText'>
									<p className='label'>Placed by </p>
									<div className='data bold'>{order.buyerUserName}</div>
								</div>
								<div className='segmentText'>
									<p className='label'>On behalf of </p>
									<div className='data bold'>{order.behalfOf.name}</div>
								</div>
								{order?.parentUuid && (
									<div className='segmentText'>
										<p className='label'>Parent Order </p>
										<div className='data bold'>{order.parentUuid}</div>
									</div>
								)}

								<div className='segmentText'>
									<p className='label'>Order Priority </p>
									<div className='data bold'>{parseOrderPriority(order.priority)}</div>
								</div>
								<div className='segmentBtnCont'>
									<button className='btn3-secondary' onClick={() => fillAndOpenRightSidebar('chat')}>
										Send a message (
										{getUnreadMessageCount(
											notificationsState.notifications,
											'message',
											order.orderUuid
										)}
										)
									</button>
								</div>
							</div>
							<div className='col'>
								<div className='segmentText highlight'>
									<p className='label'>Status </p>
									<div
										className={`data bold ${
											parseProcessingStatus(order.orderProcessingStatus).style
										}`}>
										{parseProcessingStatus(order.orderProcessingStatus).text}
									</div>
								</div>
								<div className='segmentText'>
									<p className='label'>Date required </p>
									<div className='data bold'>{dateConverter(order.sidebarDispatchDueDateTime)}</div>
								</div>
								<div className='segmentText'>
									<p className='label'>Date ordered </p>
									<div className='data bold'>{dateConverter(order.createdAt)}</div>
								</div>
								<div className='segmentAddress'>
									<h4 className='subHeader'>DELIVERY ADDRESS</h4>
									<p className='addressField'>{order?.shippingAddress?.name}</p>
									<p className='addressField'>{order?.shippingAddress?.street}</p>
									<p className='addressField'>{order?.shippingAddress?.city}</p>
									<p className='addressField'>{order?.shippingAddress?.postalCode}</p>
									<p className='addressField'>{order?.shippingAddress?.country}</p>
								</div>
								<div className='segmentBtnCont'>
									<button className='btn3-secondary' disabled>
										Track delivery
									</button>
								</div>
							</div>
							<div className='col'>
								<div className='segmentText highlight'>
									<h4 className='label'>Total</h4>
									{order?.totalPrice ? (
										<div className='data'>
											{`${order.totalPrice}£`}
											{!isSeller && !order.totalPrice && '(awaiting pricing)'}
										</div>
									) : (
										<div className='data'>
											{!isSeller && !order.totalPrice && '(awaiting pricing)'}
										</div>
									)}
									{isSeller &&
										!order.totalPrice &&
										!showPriceInput &&
										suggestedPriceStatus !== 'accepted' &&
										suggestedPriceStatus !== 'pending' && (
											<>
												<button
													className='btn3-primary'
													onClick={() => {
														setShowPriceInput(true);
													}}>
													Set price
												</button>
											</>
										)}
									{showPriceInput && order.acceptPricing && (
										<>
											<input
												className='textFieldInput'
												type='text'
												name='totalPrice'
												onChange={(e) => {
													handleNewOrderData(e.target.name, e.target.value);
												}}
											/>
											<button
												className='btn3-primary'
												disabled={newOrderData?.totalPrice !== null ? false : true}
												onClick={() => {
													patchOrder({ totalPrice: newOrderData?.totalPrice });
												}}>
												Update price
											</button>
										</>
									)}
									{showPriceInput && !order.acceptPricing && (
										<>
											<input
												className='orderInput'
												type='text'
												name='totalPrice'
												onChange={(e) => {
													handleNewOrderData(e.target.name, e.target.value);
												}}
											/>
											<button
												className='btn3-primary'
												onClick={() => {
													suggestPrice();
													setShowPriceInput(false);
												}}>
												Suggest price
											</button>
										</>
									)}
								</div>
								<div className='segmentText'>
									<p className='label'>Paid </p>
									<div className='data bold'>{order.isPaid ? 'Yes' : 'No'}</div>
								</div>
								<div className='segmentText'>
									<p className='label'>Accept Estimate </p>
									<div className='data bold'>{order.acceptEstimate ? 'Automatic' : 'Manual'}</div>
								</div>
								<div className='segmentText'>
									<p className='label'>Price change </p>
									<div className='data bold'>{order.acceptPricing ? 'Automatic' : 'Manual'}</div>
								</div>
								<div className='segmentBtnCont'>
									<button className='btn3-secondary' disabled>
										Print invoice
									</button>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='colHeader'>
								<ListOrderedIcon iconClass='orderPageIcon' />
								ORDERED ITEMS
							</div>
							<div className='colHeader'></div>
							<div className='colHeader'>
								<FileIcon iconClass='orderPageIcon' />
								IMAGES & FILES
								<div className='fileIcon marginLeft-auto'>
									<PlusDiscIcon iconClass='plus' />
									<input
										className='file'
										id='uploadFile'
										type='file'
										name='uploadFile'
										onChange={(e) => handleFiles(e)}
										multiple
									/>
								</div>
							</div>
						</div>
						<div className='orderSegment'>
							<div className='colWideCont'>
								<div className='colWide'>
									<div className='colRow'>
										<div className='rowItem'>
											<h4 className='subHeader'>PRESCRIPTION</h4>
										</div>
										<div className='rowItem'>
											<h4 className='subHeader'>PROPERTIES</h4>
										</div>
										<div className='rowItem'>
											<h4 className='subHeader'>PRICE</h4>
										</div>
									</div>
									<div className='colRow'>
										<div className='rowItem'>
											<div className='segmentText'>
												<p className='bodyText'>
													{order.orderPrescription.length > 200
														? `${sliceString(order.orderPrescription, 0, 200)}...`
														: order.orderPrescription}
												</p>
											</div>
										</div>
										<div className='rowItem'>
											<div className='segmentText'>
												<p className='label'>Grade of work: </p>
												<div className='data bold'>
													{order.gradeOfWork ? order.gradeOfWork : 'Unset'}
												</div>
											</div>
										</div>
										<div className='rowItem'>
											<div className='segmentText'>
												<div className='data bold'>
													{order?.totalPrice ? order?.totalPrice : 'Awaiting pricing'}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='colWide'>
									<div className='colRow'>
										<div className='rowItem'>
											<h4 className='subHeader'>ADD-ONS</h4>
										</div>
										<div className='rowItem'>
											<h4 className='subHeader'>QUANTITY</h4>
										</div>
										<div className='rowItem'></div>
									</div>
								</div>
							</div>
							<div className='col'>
								<div className='imgContainer'>
									{imgs?.slice(0, 6).map((img, key) => {
										return (
											<div className='imgItem' key={key}>
												<img
													className='imgThumbnail'
													src={img.url}
													alt='Thumbnail image'
													onClick={() => {
														setImgOpen(img);
														setShowOverlay(true);
														setShowImgModal(true);
													}}
												/>
											</div>
										);
									})}
								</div>
								<div className='fileContainer'>
									{generalFiles?.slice(0, 3).map((file, key) => {
										return (
											<div className='fileItem' key={key}>
												<FileIcon iconClass='orderPageFileIcon' />
												<p className='fileLink' onClick={() => downloadFile(file)}>
													{file.originalName
														? checkFilenameLength(file?.originalName, 20)
														: checkFilenameLength(file?.filename, 20)}
												</p>
												{file?.filename?.split('.')[1] === 'stl' ? (
													<button
														className='openBtn'
														onClick={() => toggleHidden(file, !modelHidden)}>
														Open in viewer
													</button>
												) : (
													<></>
												)}
											</div>
										);
									})}
									{changeMade && <h4>New Files: </h4>}
									{newFiles[0] &&
										newFiles.map((file, key) => {
											return (
												<div key={key}>
													<p>{file.name}</p>
													<p onClick={() => removeFile(file)}>Remove</p>
												</div>
											);
										})}
									{changeMade && (
										<button className='openBtn' onClick={() => patchOrder()}>
											Save changes
										</button>
									)}
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='colHeader'>
								<TrackingIcon iconClass='orderPageIcon' />
								TRACKING
							</div>
							<div className='colHeader'></div>
							<div className='colHeader'></div>
						</div>
						<div className='orderSegmentTop'>
							<OrderTracking />
						</div>
						<div className='orderSegmentTop'>
							<OrderSuborders
								orderUuid={order.orderUuid}
								parentUuid={order.parentUuid}
								suborders={order.suborders}
								isSeller={isSeller}
							/>
						</div>
						<div className='bottomRow'>
							<div className='orderLinkText' onClick={() => fillAndOpenRightSidebar('order')}>
								Order details
								<CaretRightIcon iconClass='caretRightIconWhite' />
							</div>
							<div className='orderLinkText' onClick={() => fillAndOpenRightSidebar('order')}>
								History log
								<CaretRightIcon iconClass='caretRightIconWhite' />
							</div>
							<div className='orderLinkText' onClick={() => fillAndOpenRightSidebar('order')}>
								Print order
								<CaretRightIcon iconClass='caretRightIconWhite' />
							</div>
							<div className='orderLinkText' onClick={() => fillAndOpenRightSidebar('order')}>
								Print invoice
								<CaretRightIcon iconClass='caretRightIconWhite' />
							</div>
							<div className='orderLinkText' onClick={() => fillAndOpenRightSidebar('order')}>
								Messages
								<CaretRightIcon iconClass='caretRightIconWhite' />
							</div>
						</div>
					</div>
				</div>
			) : (
				<Loading type='circle' />
			)}
		</>
	);
}
