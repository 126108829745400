import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SupplierTrustedIcon } from '../../../../components/SVGIcons/SVGIcons';
import { CurrentTeamContext } from '../../../../contexts/CurrentTeamContext';

export default function SupplierHeader() {
	const { teamState } = useContext(CurrentTeamContext);
	return (
		<div className='carouselHeader'>
			<div>
				<div className='flexRowStart'>
					<SupplierTrustedIcon iconClass='supplierTrustedIcon' />
					<h2 className='marginLeft-20'>Trusted Suppliers</h2>
				</div>
				<p>A Trusted Supplier is a business that you trust and would regularly purchase from.</p>
				<Link to='/tutorials/en/tutorial'>Learn more about Trusted Suppliers.</Link>
			</div>
			<div className='carouselHeaderBtnCont'>
				<Link to='/invite'>
					<button className='dashboardBtnSeeAll' disabled={!teamState.userPermissions['canInvite']}>
						Invite a supplier
					</button>
				</Link>
				<Link to='/suppliers'>
					<button className='dashboardBtnSeeAll'>Find a supplier</button>
				</Link>
			</div>
		</div>
	);
}
