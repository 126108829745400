import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';
import { decodeJwt, splitUserId } from '../helpers';
import Decode from 'jwt-decode';
import Cookies from 'js-cookie';
import Axios from 'axios';
import config from '../config';
import Loading from '../components/Loading/Loading';

let CurrentUserContext = React.createContext();

function CurrentUserContextProvider(props) {
	let initialState = {
		loading: true,
		reload: false,
		isAuthenticated: false,
		users: [],
		currUser: null,
		lastCode: null,
	};

	const location = useLocation();

	const reducer = (userState, action) => {
		switch (action.type) {
			case 'LOGOUT':
				let currentUser = action.payload;

				Cookies.remove(`${currentUser}.refreshToken`);
				Cookies.remove(`${currentUser}.accessToken`);
				Cookies.remove(`${currentUser}.idToken`);

				let filteredUsers = userState.users.filter((user) => {
					return splitUserId(user.sub) !== currentUser;
				});

				if (filteredUsers.length > 0) {
					if (filteredUsers[0]?.sub) {
						Cookies.set(`currentUser`, splitUserId(filteredUsers[0].sub), { expires: 7 });
					}
				}

				window.location.href = '/';

				return {
					...userState,
					loading: false,
					reload: true,
				};
			case 'LOGOUT_ALL':
				const cookies = Cookies.get();
				for (let cookie in cookies) {
					Cookies.remove(cookie);
				}
				return {
					...userState,
					loading: false,
					reload: true,
					isAuthenticated: false,
				};
			case 'LOGIN':
				const lastState = uuidv4();
				Cookies.set(`lastState`, lastState, { expires: 0.1 });

				window.location.assign(
					`${config.auth}/login?client_id=${props.clientId}&response_type=code&redirect_uri=${props.redirectUri}&state=${lastState}&scope=${props.scope}`
				);
				return {
					...userState,
				};
			case 'SET_REDIRECT_AFTER_AUTH':
				Cookies.set(`redirectAfterAuth`, JSON.stringify(action.payload), { expires: 0.1 });
				return {
					...userState,
					loading: false,
					reload: false,
				};
			case 'SET_CURR_USER':
				Cookies.set(`currentUser`, splitUserId(action.payload), { expires: 7 });
				return {
					...userState,
					loading: false,
					reload: true,
				};
			case 'MANAGE':
				window.location.assign(
					`${config.auth}/manage?email=${userState.currUser.email}&redirectUrl=${config.host2}`
				);
				return {
					...userState,
					loading: false,
					reload: false,
				};
			case 'SET_LAST_CODE':
				return {
					...userState,
					loading: false,
					reload: false,
					lastCode: action.payload,
				};
			case 'LOGIN_SUCCESS':
				let userData = extractUserData(action.payload.data.id_token);
				if (userData) {
					Cookies.set(`${splitUserId(userData.sub)}.refreshToken`, action.payload.data.refresh_token, {
						expires: 7,
					});
					Cookies.set(`${splitUserId(userData.sub)}.idToken`, action.payload.data.id_token, { expires: 1 });
					Cookies.set(`${splitUserId(userData.sub)}.accessToken`, action.payload.data.access_token, {
						expires: 1,
					});
					Cookies.set(`currentUser`, splitUserId(userData.sub), { expires: 7 });

					Axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`;

					if (action.payload.redirect) {
						window.location.assign(action.payload.redirect.redirectUri);
					}
				} else {
					return {
						...userState,
						loading: false,
						reload: true,
						isAuthenticated: false,
					};
				}

				return {
					...userState,
					loading: false,
					reload: true,
				};
			case 'TOKEN_FETCH_SUCCESS':
				let newUserData = extractUserData(action.payload.id_token);
				if (newUserData) {
					Cookies.set(`${splitUserId(newUserData.sub)}.refreshToken`, action.payload.refresh_token, {
						expires: 7,
					});
					Cookies.set(`${splitUserId(newUserData.sub)}.idToken`, action.payload.id_token, { expires: 1 });
					Cookies.set(`${splitUserId(newUserData.sub)}.accessToken`, action.payload.access_token, {
						expires: 1,
					});
					Cookies.set(`currentUser`, splitUserId(newUserData.sub), { expires: 7 });

					Axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`;
				} else {
					return {
						...userState,
						loading: false,
						reload: true,
						isAuthenticated: false,
					};
				}

				return {
					...userState,
					loading: false,
					reload: true,
					isAuthenticated: true,
					currUser: extractUserData(Cookies.get(`${splitUserId(newUserData.sub)}.idToken`)),
					accessToken: action.payload.access_token,
				};
			case 'RELOADED_AND_AUTHENTICATED':
				let currUser = Cookies.get('currentUser');
				const allCookies = Cookies.get();
				let redirectAfterAuth = Cookies.get('redirectAfterAuth');

				for (let cookie in allCookies) {
					if (cookie.split('.')[1] === 'idToken') {
						if (!userState.users.some((user) => extractUserData(allCookies[cookie]).sub === user.sub)) {
							userState.users.push(extractUserData(allCookies[cookie]));
						}
					}
				}

				if (redirectAfterAuth !== undefined) {
					let parsedData = JSON.parse(redirectAfterAuth);
					if (!parsedData.redirected) {
						parsedData.redirected = true;
						Cookies.set('redirectAfterAuth', JSON.stringify(parsedData));
						window.location.assign(
							`${parsedData.redirectUri}?invitationUuid=${parsedData.params.invitationUuid}&teamUuid=${parsedData.params.teamUuid}`
						);
					}
				}
				return {
					...userState,
					loading: false,
					reload: false,
					isAuthenticated: true,
					currUser: extractUserData(Cookies.get(`${currUser}.idToken`)),
					accessToken: Cookies.get(`${currUser}.accessToken`),
				};
			default:
				break;
		}
	};

	function checkIfTokenExpired(token) {
		if (String(token) !== 'null' && String(token) !== 'undefined') {
			const decodedToken = Decode(token);
			const currDate = new Date();
			if (decodedToken.exp < currDate.getTime() / 1000) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	function extractUserData(idToken) {
		if (String(idToken) !== 'null' && String(idToken) !== 'undefined') {
			return Decode(idToken);
		} else {
			return false;
		}
	}

	function getNewTokens() {
		const currentUser = Cookies.get('currentUser');
		const refreshToken = Cookies.get(`${currentUser}.refreshToken`);
		Axios({
			url: `${config.auth}/v1/oauth/token`,
			method: 'POST',
			data: {
				refresh_token: refreshToken,
				client_id: props.clientId,
				redirect_uri: window.location.origin,
				grant_type: 'refresh_token',
				scope: 'openid profile email offline_access',
			},
		})
			.catch((err) => {
				userDispatch({ type: 'LOGOUT', payload: currentUser });
				return err;
			})
			.then((res) => {
				if (res.data) {
					userDispatch({ type: 'TOKEN_FETCH_SUCCESS', payload: res.data });
				} else {
					userDispatch({ type: 'LOGOUT', payload: currentUser });
				}
			});
	}

	let [userState, userDispatch] = React.useReducer(reducer, initialState);
	let value = { userState, userDispatch };

	useEffect(() => {
		const code = new URLSearchParams(location.search).get('code');
		const state = new URLSearchParams(location.search).get('state');
		const currentUser = Cookies.get('currentUser');
		const lastState = Cookies.get('lastState');

		const redirectAfterAuth = Cookies.get('redirectAfterAuth');
		const accessToken = Cookies.get(`${currentUser}.accessToken`);
		const idToken = Cookies.get(`${currentUser}.idToken`);
		const refreshToken = Cookies.get(`${currentUser}.refreshToken`);

		Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

		if (code && userState.lastCode !== code && state === lastState) {
			Cookies.remove(`lastState`);
			Axios({
				url: `${config.auth}/v1/oauth/token`,
				method: 'POST',
				data: {
					code: code,
					client_id: props.clientId,
					redirect_uri: window.location.origin,
					grant_type: 'authorization_code',
				},
			})
				.then((res) => {
					if (res.data) {
						userDispatch({ type: 'SET_LAST_CODE', payload: code });
						if (redirectAfterAuth) {
							userDispatch({
								type: 'LOGIN_SUCCESS',
								payload: { data: res.data, redirect: redirectAfterAuth },
							});
						} else {
							userDispatch({ type: 'LOGIN_SUCCESS', payload: { data: res.data } });
						}
					} else {
						userDispatch({ type: 'LOGOUT', payload: currentUser });
					}
				})
				.catch((err) => {
					userDispatch({ type: 'LOGOUT', payload: currentUser });
					return err;
				});
		} else if (accessToken && idToken) {
			if (checkIfTokenExpired(accessToken) && checkIfTokenExpired(idToken)) {
				if (!checkIfTokenExpired(refreshToken)) {
					getNewTokens();
				} else {
					userDispatch({ type: 'LOGOUT', payload: currentUser });
				}
			} else {
				userDispatch({ type: 'RELOADED_AND_AUTHENTICATED' });
			}
		} else if (refreshToken && !accessToken) {
			if (!checkIfTokenExpired(refreshToken)) {
				getNewTokens();
			} else {
				userDispatch({ type: 'LOGOUT', payload: currentUser });
			}
		} else {
			userDispatch({ type: 'LOGIN' });
		}
	}, [userState?.reload]); // eslint-disable-next-line

	if (userState?.loading) {
		return <Loading type='circle' />;
	}

	return <CurrentUserContext.Provider value={value}>{props.children}</CurrentUserContext.Provider>;
}

let CurrentUserContextConsumer = CurrentUserContext.Consumer;

export { CurrentUserContext, CurrentUserContextProvider, CurrentUserContextConsumer };
