import React from 'react';
import SelectCustom from '../Select/SelectCustom';
import orderStatusOptions from '../Options/orderStatusOptions';
import { Checkmark, ErrorIcon } from '../SVGIcons/SVGIcons';

export default function OrderEditForm(props) {
	const { hint, setHint, status, setStatus } = props || {};
	return (
		<div>
			<div className='formRowCenter'>
				<p className='extraBold' htmlFor='companyName'>
					Hint
				</p>
				<div className='inputContainer'>
					<input
						className='textFieldInput'
						name='companyName'
						defaultValue={hint || ''}
						onChange={(e) => setHint(e.target.value)}
						required
					/>
					{hint ? <Checkmark iconClass='inputIcon' /> : <ErrorIcon iconClass='inputIcon' />}
				</div>
			</div>
			<div className='formRowCenter'>
				<div className='fieldTextContainer'>
					<p className='extraBold' htmlFor='chooseSupplier'>
						Status
					</p>
				</div>
				<SelectCustom
					htmlOptions={orderStatusOptions}
					callback={setStatus}
					value={status}
					placeholder={'Select...'}
				/>
			</div>
		</div>
	);
}
