import React, { useEffect, useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink, useLocation } from 'react-router-dom';

const SupplierCrumb = ({ match }) => (
    <span>{match.params.id}</span>
);

const routes = [
    { path: '/', breadcrumb: 'Dashboard' },
    { path: '/teamRegistration', breadcrumb: 'Team Registration' },
    { path: '/suppliers/:id', breadcrumb: SupplierCrumb },
];

function Breadcrumbs() {
    const [loaded, setLoaded] = useState(false);

    const breadcrumbs = useBreadcrumbs();
    const location = useLocation();

    let blacklist = ['/', '/manage'];


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let blacklisted = false;
            for (let i = 0; i < blacklist.length; i++) {
                if (blacklist[i] === location.pathname) {
                    blacklisted = true;
                }
            }
            setLoaded(!blacklisted);
        }
        return () => mounted = false;
    }, [location])

    return (
        <>
            {loaded ? (
                <div className="breadcrumbContainer">
                    {breadcrumbs.map(({
                        breadcrumb,
                        match
                    }, i) => (
                        <span key={match.pathname}>
                            <NavLink to={match.pathname}>{breadcrumb.props.children} {breadcrumbs.length > 1 && breadcrumbs.length !== i + 1 ? '|' : ''} </NavLink>
                        </span>
                    ))}
                </div>
            ) : (
                <div className="breadcrumbContainer"></div>
            )}
        </>
    )
}



export default Breadcrumbs;