import React from 'react';
import { parseOrderPriority } from '../../../../helpers';
import { CaretDownIcon, CaretUpIcon } from '../../../../components/SVGIcons/SVGIcons';

export default function DeliveryDetails(props) {
	const { order, textboxesOpen, openTextbox } = props || {};

	return (
		<>
			<div className='rightSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => openTextbox('delivery')}>
					<span className='bold'>Delivery details</span>
					{textboxesOpen['dellivery'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{order && textboxesOpen['delivery'] && (
					<div className='sidebarTextBody'>
						<p className='sidebarTextRow'>
							<span>Shipper:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Order status:</span> {parseOrderPriority(order.priority)}
						</p>
						<div className='sidebarTextHeader'>
							<span>Delivery address: </span>
							<div className='caretUpIconGray'></div>
						</div>
						<div>
							<p className='sidebarTextRow'>
								<span>Name:</span> {order.shippingAddress.name}
							</p>
							<p className='sidebarTextRow'>
								<span>Street:</span> {order.shippingAddress.street}
							</p>
							<p className='sidebarTextRow'>
								<span>City:</span> {order.shippingAddress.city}
							</p>
							<p className='sidebarTextRow'>
								<span>Postal code:</span> {order.shippingAddress.postalCode}
							</p>
							<p className='sidebarTextRow'>
								<span>Country:</span> {order.shippingAddress.country}
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
