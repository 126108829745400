import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { SocketContext } from '../App';
import config from '../config';
import { CurrentTeamContext } from './CurrentTeamContext';

let NotificationContext = React.createContext();

function NotificationContextProvider(props) {
	let { teamState } = useContext(CurrentTeamContext);
	const socket = useContext(SocketContext);

	let initialState = {
		loading: true,
		reload: false,
		notifications: [],
		unreadNotifications: false,
	};

	const reducer = (notificationsState, action) => {
		let index;
		let unreadNotifications;

		switch (action.type) {
			case 'ADD_NOTIFICATION':
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload.notificationUuid;
				});
				if (index < 0) {
					notificationsState.notifications.unshift(action.payload);
					if (!notificationsState.unreadNotifications) {
						notificationsState.unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
				};
			case 'ACK_NOTIFICATION':
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload.notificationUuid;
				});
				if (index > -1) {
					notificationsState.notifications.splice(index, 1);
				}
				return {
					...notificationsState,
					reload: true,
				};
			case 'SET_LOADING':
				return {
					...notificationsState,
					loading: action.payload || false,
				};
			case 'SET_READ':
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload.notificationUuid;
				});
				if (index > -1) {
					if (notificationsState.notifications[index].isRead !== true) {
						notificationsState.notifications[index].amount--;
						notificationsState.notifications[index].isRead = true;
					}
				}
				unreadNotifications = false;
				for (let notification of notificationsState.notifications) {
					if (!notification.isRead) {
						unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
					unreadNotifications: unreadNotifications,
				};
			case 'SET_ALL_MESSAGES_READ':
				for (let i = 0; i < notificationsState.notifications.length; i++) {
					if (notificationsState.notifications[i].notificationData.orderUuid === action.payload) {
						notificationsState.notifications[i].isRead = true;
					}
				}
				unreadNotifications = false;
				for (let notification of notificationsState.notifications) {
					if (!notification.isRead) {
						unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
					unreadNotifications: unreadNotifications,
				};
			case 'SET_UNREAD':
				return {
					...notificationsState,
					unreadNotifications: action.payload,
				};
			default:
				return {
					...notificationsState,
				};
		}
	};

	let [notificationsState, notificationsDispatch] = React.useReducer(reducer, initialState);
	let value = { notificationsState, notificationsDispatch };

	useEffect(() => {
		if (socket !== null) {
			Axios({
				url: `${config.apiv1}/notification/notifications.read/${teamState.currentTeam.teamUuid}?limit=10&sortDate=desc`,
				method: 'GET',
			})
				.catch((err) => {
					console.log(err);
					notificationsDispatch({ type: 'SET_LOADING', payload: false });
				})
				.then((res) => {
					let unread = false;
					if (res?.data?.ok) {
						for (let i = 0; i < res.data.data.length; i++) {
							if (!res.data.data[i].isRead && !notificationsState.unreadNotifications) {
								unread = true;
							}
							notificationsState.notifications.push(res.data.data[i]);
						}
						notificationsDispatch({ type: 'SET_LOADING', payload: false });
						notificationsDispatch({ type: 'SET_UNREAD', payload: unread });
					} else {
						notificationsDispatch({ type: 'SET_LOADING', payload: false });
					}
				});
			socket.on('new-notification', (notification) => {
				notificationsDispatch({
					type: 'ADD_NOTIFICATION',
					payload: {
						notificationType: notification.notificationType,
						notificationData: notification.notificationData,
						notificationUuid: notification.notificationUuid,
					},
				});
			});
		}
	}, [socket]);

	return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
}

let NotificationContextConsumer = NotificationContext.Consumer;

export { NotificationContext, NotificationContextProvider, NotificationContextConsumer };
