import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import config from '../../config';
import { splitUserId, toBase64 } from '../../helpers';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import TeamForm from '../../components/Forms/TeamForm';
import { v4 as uuidv4 } from 'uuid';

export default function TeamEdit() {
	const [collection, setCollection] = useState({});
	const [errorMessage, setErrorMessage] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [registeredAddress, setRegisteredAddress] = useState({});
	const [deliveryAddress, setDeliveryAddress] = useState({});
	const [isDeliveryAddress, setIsDeliveryAddress] = useState(null);
	const [formData, setFormData] = useState({});

	let { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const millisecond = 1;

	const acceptCollectRef = useRef(null);
	const isCompanyRef = useRef(null);
	const isDeliveryAddressRef = useRef(null);
	const pageStart = useRef(null);
	const pageEnd = useRef(null);

	function setTeam(team) {
		teamDispatch({ type: 'SET_TEAM', payload: team });
	}

	function scrollToTop() {
		pageStart.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	}

	function scrollToBottom() {
		setTimeout(function () {
			pageEnd.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}, millisecond);
	}

	function scrollToRef(ref) {
		setTimeout(function () {
			if (ref.current) {
				ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}, millisecond);
	}

	const handleRegisteredAddressInput = (e) => {
		const { name, value } = e.target;
		setRegisteredAddress((registeredAddress) => ({
			...registeredAddress,
			[name]: value,
		}));
	};

	const handleDeliveryAddressInput = (e) => {
		const { name, value } = e.target;
		setDeliveryAddress((deliveryAddress) => ({
			...deliveryAddress,
			[name]: value,
		}));
	};

	function handleFormData(name, value) {
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	}
	function handleFormSelectData(data, fieldName) {
		setFormData((formData) => ({
			...formData,
			[fieldName]: data.value,
		}));
	}

	const handleCollectionInput = (e) => {
		const { name, value } = e.target;
		setCollection((collection) => ({
			...collection,
			[name]: value,
		}));
	};

	async function handleSubmit(e) {
		e.preventDefault();
		e.target.disabled = true;

		// IMPORTANT!!! CHECK IF addressUuid gets updated with each change, should not happen
		const data = {
			id: splitUserId(userState.currUser.sub),
			registeredAddress: {
				addressUuid: registeredAddress.addressUuid,
				isDefault: registeredAddress.isDefault,
				addressType: 'registeredAddress',
				name: registeredAddress.name,
				street: registeredAddress.street,
				city: registeredAddress.city,
				postalCode: registeredAddress.postalCode,
				country: registeredAddress.country,
			},
			collection: formData.acceptCollect
				? {
						collectionPoint: collection.collectionPoint,
						collectionStreet: collection.collectionStreet,
						collectionCity: collection.collectionCity,
						collectionPostalCode: collection.collectionPostalCode,
						collectionCountry: collection.collectionCountry,
						collectionHours: collection.collectionHours,
						collectionInstructions: collection.collectionInstructions,
				  }
				: null,
			companyDescriptionHeader: formData.companyDescriptionHeader,
			companyDescription: formData.companyDescription,
			teamName: formData.teamName,
			isPublic: formData.isPublic,
			acceptCollect: formData.acceptCollect,
			isCompany: formData.isCompany,
			teamIcon: formData.formProfileImg ? await toBase64(formData.formProfileImg) : undefined,
			teamPicture: formData.formPicture ? await toBase64(formData.formPicture) : undefined,
			teamUuid: formData.teamUuid,
			companyType: formData.companyType,
			teamInitials: formData.teamInitials,
			spokenLanguage: formData.language,
			phoneNumber: formData.phoneNumber,
			currency: formData.currency,
			tax: formData.tax,
			companyName: formData.companyName,
			companyNumber: formData.companyNumber,
			companyCountry: formData.companyCountry,
			orderRefFormat: formData.orderRefFormat,
		};

		try {
			Axios({
				url: `${config.apiv1}/team/team.update`,
				method: 'PATCH',
				data: data,
			}).then((res) => {
				if (res.data?.error) {
					// Put file with parsing different error codes to text
					setErrorMessage('Could not create team, contact site administrator');
					scrollToTop(pageStart);
				} else {
					setTeam(res.data.data.teamUuid);
					window.location.assign('/');
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && teamState.currentTeam) {
			if (teamState.currentTeam.teamInitials === 'ME') return;
			function handleFields() {
				for (let field in teamState.currentTeam) {
					if (field === 'teamInfo') {
						handleTeamInfoFields();
					} else if (field === 'teamPreferences') {
						for (let infoField in teamState.currentTeam.teamPreferences) {
							handleFormData(infoField, teamState.currentTeam.teamPreferences[infoField]);
						}
					} else {
						handleFormData(field, teamState.currentTeam[field]);
					}
				}
			}

			function handleTeamInfoFields() {
				for (let infoField in teamState.currentTeam.teamInfo) {
					if (infoField === 'addresses') {
						let addresses = teamState.currentTeam.teamInfo[infoField];
						for (let i = 0; i < addresses.length; i++) {
							if (addresses[i].addressType === 'registeredAddress') {
								setRegisteredAddress(addresses[i]);
							}
						}
					} else if (infoField === 'collection') {
						setCollection(teamState.currentTeam.teamInfo[infoField]);
					} else {
						handleFormData(infoField, teamState.currentTeam.teamInfo[infoField]);
					}
				}
			}

			handleFields();

			setLoaded(true);
		}
		return () => (mounted = false);
	}, [teamState.currentTeam]);

	return (
		<>
			{loaded && (
				<>
					<TeamForm
						formTitle={'Edit team'}
						registering={false}
						refs={{
							acceptCollectRef: acceptCollectRef,
							pageStart: pageStart,
							pageEnd: pageEnd,
							isCompanyRef: isCompanyRef,
							isDeliveryAddressRef: isDeliveryAddressRef,
						}}
						scrollToRef={scrollToRef}
						scrollToBottom={scrollToBottom}
						handleSubmit={handleSubmit}
						errorMessage={errorMessage}
						formData={formData}
						handleFormData={handleFormData}
						handleFormSelectData={handleFormSelectData}
						registeredAddress={registeredAddress}
						handleRegisteredAddressInput={handleRegisteredAddressInput}
						collection={collection}
						handleCollectionInput={handleCollectionInput}
						deliveryAddress={deliveryAddress}
						handleDeliveryAddressInput={handleDeliveryAddressInput}
						isDeliveryAddress={isDeliveryAddress}
						setIsDeliveryAddress={setIsDeliveryAddress}
					/>
				</>
			)}
		</>
	);
}
