import React, { useContext } from 'react';
import trandactorLite from '../../assets/img/lab-manager-logo.PNG';
import quickBooks from '../../assets/img/quickbooks_app_icon.png';
import xero from '../../assets/img/xero_app_icon.png';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { Link } from 'react-router-dom';

export default function TeamApps() {
	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	return (
		<div className='teamAppsContainer'>
			<h1>Get yourself connected</h1>

			<p>
				We know you're busy. Connect Procuur to your favourite accountancy packs, workflow software and/or
				messaging tools and push your work to where you need it.
			</p>

			<div id='apps_overview'>
				<div className='app_tile'>
					<img src={trandactorLite} width='120' height='120' alt='Transactor Lite' />

					<div className='centered_button'>
						<a
							href={`https://pconnectserver.azurewebsites.net/Identity/Account/RegisterLink?returnUrl=${window.location.href}&email=${userState.currUser.email}&sellerteamuuid=${teamState.currentTeam.teamUuid}`}>
							<button>ADD</button>
						</a>
						<Link to={`/manage/apps/transactor`}>
							<button>Read More</button>
						</Link>
					</div>
				</div>
				<div className='app_tile disabled'>
					<img src={quickBooks} width='120' height='120' alt='QuickBooks' />

					{/* <p>
                        Send your orders over to Quickbooks and have them appear as invoices
                        at the touch of a button.
                    </p> */}

					<div className='centered_disabled_button'>
						<button disabled={true}>ADD</button>
					</div>
				</div>

				<div className='app_tile disabled'>
					<img src={xero} width='120' height='120' alt='Xero' />

					{/* <p>
                        Send your orders over to Xero and have them appear as invoices at
                        the touch of a button.
                    </p> */}

					<div className='centered_disabled_button'>
						<button disabled={true}>ADD</button>
					</div>
				</div>
			</div>
			{/* 
                <div className="app_tile">
                    <img src="./apps/img/sage_app_icon.png" width="120" height="120" alt="Sage" />

                    <p>
                        Send your orders over to Sage and have them appear as invoices as the touch of a button.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                </div>

                <div className="app_tile">
                    <img src="./apps/img/transactor_app_icon.png" width="120" height="120" alt="Transactor" />

                    <p>
                        Transactor is the Lab desktop software of choice. Add your orders to Transactor automatically as they come in and sync your messages.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                </div>

                <div className="app_tile">
                    <img src="./apps/img/whatsapp_app_icon.png" width="120" height="120" alt="Whatsapp" />

                    <p>
                        Whatsapp is a Messaging App. Sync your messages so that when a buyer contacts you you get the message realtime into your Whatsapp feed.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                </div>

                <div className="app_tile">
                    <img src="./apps/img/labstar_app_icon.png" width="120" height="120" alt="Labstar" />

                    <p>
                        Labstar is Simple, cloud-based lab management software that makes your lab better. Add your orders to Labstar automatically as they come in and sync your messages.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                </div>

                <div className="app_tile">
                    <img src="./apps/img/labtrac_app_icon.png" width="120" height="120" alt="LabTrac" />

                    <p>
                        Labtrac is Simple, cloud-based lab management software that makes your lab better. Add your orders to Labtrac automatically as they come in and sync your messages.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                </div>

                <div className="app_tile">
                    <img src="./apps/img/procureix_email_app_icon.png" width="120" height="120" alt="Email Sync" />

                    <p>
                        Fresh from Procuriex. Get emails notifying you of orders received if you are offline.
                    </p>

                    <div className="centered_button">
                        <button>ADD</button>
                    </div>
                 */}

			{/* <div className="app_tile">
                <h3>Missing your favourite App?</h3>

                <p>
                    Tell your App team about us! The can App their App htmlFor free!
                    </p>

                <div className="centered_disabled_button">
                    <button>HOW</button>
                </div>
            </div> */}
		</div>
	);
}
