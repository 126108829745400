import React from 'react';
import {
	AnalyticsDiscIcon,
	BellDiscDashIcon,
	CartBuyDiscDashIcon,
	CartSellDiscDashIcon,
} from '../../components/SVGIcons/SVGIcons';
import DashboardWidget from './DashboardWidget';
import DashboardWidgetLinks from './DashboardWidgetLinks';

export default function DashboardNotifications(props) {
	const { ordersCount, notificationsCount } = props || {};
	return (
		<>
			<div className='overviewSection'>
				<DashboardWidget
					color={'purchase'}
					icon={<CartBuyDiscDashIcon />}
					header={'Purchase Orders'}
					body={
						<DashboardWidgetLinks
							links={[
								{
									name: 'Drafts',
									count: ordersCount?.drafts,
									type: 1,
									status: 1,
									path: 'orders',
								},
								{
									name: 'Pending',
									count: ordersCount?.purchasesPending,
									type: 1,
									status: 2,
									path: 'orders',
								},
								{
									name: 'Processing',
									count: ordersCount?.purchasesProcessing,
									type: 1,
									status: 3,
									path: 'orders',
								},
								{
									name: 'Dispatched',
									count: ordersCount?.purchasesDispatched,
									type: 1,
									status: 5,
									path: 'orders',
								},
							]}
							isBuyer={true}
						/>
					}
					url={'orders'}
					type={1}
					linkBtn={'See all purchases'}
				/>
				<DashboardWidget
					color={'notification'}
					icon={<BellDiscDashIcon />}
					header={'Notifications'}
					body={
						<DashboardWidgetLinks
							links={[
								{ name: 'New messages', count: notificationsCount?.messages, path: 'notifications' },
								{
									name: 'Pricing reviews',
									count: notificationsCount?.pricingReviews,
									path: 'notifications',
								},
								{
									name: 'Unread notifications',
									count: notificationsCount?.unreadNotifications,
									path: 'notifications',
								},
							]}
						/>
					}
					url={'notifications'}
					linkBtn={'See all notifications'}
				/>
				<DashboardWidget
					color={'sale'}
					icon={<CartSellDiscDashIcon />}
					header={'Sales'}
					body={
						<DashboardWidgetLinks
							links={[
								{
									name: 'New sales',
									count: ordersCount?.salesPending,
									type: 2,
									status: 2,
									path: 'orders',
								},
								{
									name: 'Processing',
									count: ordersCount?.salesProcessing,
									type: 2,
									status: 3,
									path: 'orders',
								},
								{
									name: 'Awaiting suborder',
									count: ordersCount?.awaitingSuborder,
									type: 2,
									status: 4,
									path: 'orders',
								},
								{
									name: 'Awaiting dispatch',
									count: ordersCount?.awaitingDispatch,
									type: 2,
									status: 4,
									path: 'orders',
								},
							]}
							isBuyer={false}
						/>
					}
					url={'orders'}
					type={2}
					linkBtn={'See all sales'}
				/>
				<DashboardWidget
					color={'analytics'}
					icon={<AnalyticsDiscIcon />}
					header={'Analytics'}
					linkBtn={'See analytics'}
					url={'tutorials/en/tutorial'}
				/>
			</div>
		</>
	);
}
