import React from 'react';
import { Link } from 'react-router-dom';

export default function DashboardWidget(props) {
	const { color, icon, linkBtn, header, body, type, disabled, url } = props || {};
	return (
		<>
			<div className={`overviewItem${disabled ? ' disabledDiv' : ''}`}>
				<div className={`overviewItemHeader`} style={{ backgroundColor: `var(--${color || 'gray'})` }}>
					<h3>{header || ''}</h3>
					<div className='headerIcon'>{icon || ''}</div>
				</div>
				<div className='overviewItemBody'>
					<div>{body || ''}</div>
					<div className='overviewItemFooter'>
						{linkBtn && (
							<Link to={`/${url || ''}${type ? `?type=${type}` : ''}`}>
								<button className='dashboardBtnSeeAll'>{linkBtn || ''}</button>
							</Link>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
