import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TutorialContext } from '../../contexts/TutorialContext';

export default function SupportDropdown(props) {
	const { setOpen } = props || {};

	const { tutorialDispatch } = useContext(TutorialContext);

	return (
		<div className='dropdownFixed'>
			<div className='supportCol'>
				<div className='section'>
					<Link to='/orders'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Getting started
						</p>
					</Link>
					<Link to='/tutorials/en/tutorial'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Tutorials
						</p>
					</Link>
					<p
						onClick={() => {
							setOpen(false);
							tutorialDispatch({
								type: 'RESET',
								payload: {
									page: 'dashboard',
									data: {
										open: false,
										prompt: true,
										curr: 1,
									},
								},
							});
						}}>
						Take the tour
					</p>
				</div>
				<div className='section'>
					<Link to='/orders'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Give feedback
						</p>
					</Link>
					<Link to='/orders'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Contact support
						</p>
					</Link>
				</div>
				<div className='section'>
					<Link to='/orders'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Follow us
						</p>
					</Link>
					<Link to='/orders'>
						<p
							onClick={() => {
								setOpen(false);
							}}>
							Refer a friend
						</p>
					</Link>
				</div>
			</div>
		</div>
	);
}
