import React from 'react';
import { CaretDownIcon, CaretUpIcon } from '../../../../components/SVGIcons/SVGIcons';

export default function PriceDetails(props) {
	const { order, textboxesOpen, openTextbox } = props || {};
	return (
		<>
			<div className='rightSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => openTextbox('price')}>
					<span className='bold'>Price details</span>
					{textboxesOpen['price'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{order && textboxesOpen['price'] && (
					<div className='sidebarTextBody'>
						<p className='sidebarTextRow'>
							<span>Sum:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Shipping cost:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Total w/o VAT:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Applicable VAT:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Subtotal:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Discount:</span>{' '}
						</p>
						<p className='sidebarTextRow bold'>
							<span>Total:</span>{' '}
						</p>
						<p className='sidebarTextRow'>
							<span>Payment method:</span>{' '}
						</p>
					</div>
				)}
			</div>
		</>
	);
}
