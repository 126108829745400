import Axios from 'axios';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { splitNotificationType } from '../../helpers';

export default function BellDropdown() {
	let { notificationsState, notificationsDispatch } = useContext(NotificationContext);
	let { teamState } = useContext(CurrentTeamContext);

	function setRead(uuid) {
		Axios({
			url: `${config.apiv1}/notification/notification.update/${uuid}`,
			method: 'PATCH',
			data: {
				isRead: true,
			},
		}).then((response) => {
			notificationsDispatch({ type: 'SET_READ', payload: response.data.updatedNotification });
		});
	}
	function checkHref(target) {
		if (window.location.href === `${config.host2}${target}`) {
			window.location.reload();
		}
	}

	return (
		<div className='dropdown'>
			{notificationsState?.notifications?.length > 0 ? (
				notificationsState.notifications.map((notification, key) => {
					return (
						<div className='notification' key={key}>
							{splitNotificationType(notification.notificationType)[0] === 'order' && (
								<Link
									className={notification.isRead ? '' : 'notificationUnread'}
									onClick={() => {
										setRead(notification?.notificationUuid);
										checkHref(`/orders/${notification?.notificationData?.orderUuid}`);
									}}
									to={`/orders/${notification?.notificationData?.orderUuid}`}>
									{splitNotificationType(notification.notificationType)[1] === 'created'
										? `New order from ${notification?.senderTeamName} (${notification?.notificationData?.orderRef})`
										: ''}
									{splitNotificationType(notification.notificationType)[1] === 'updated'
										? `Order updated: ${notification?.notificationData?.orderRef}`
										: ''}
									{splitNotificationType(notification.notificationType)[1] === 'priceSuggested'
										? `Price change on order ${notification?.notificationData?.orderRef} needs review`
										: ''}
								</Link>
							)}
							{splitNotificationType(notification.notificationType)[0] === 'message' && (
								<>
									<Link
										className={notification.isRead ? '' : 'notificationUnread'}
										onClick={() => {
											setRead(notification?.notificationUuid);
										}}
										to={`/orders/${notification?.notificationData?.orderUuid}?openFocus=chat`}>
										{splitNotificationType(notification.notificationType)[1] === 'created'
											? `New message from ${
													notification?.notificationData?.sellerUserRef
														? notification?.notificationData?.sellerUserRef
														: notification?.notificationData?.buyerUserRef
											  }`
											: ''}
									</Link>
								</>
							)}
							{splitNotificationType(notification.notificationType)[0] === 'member' && (
								<>
									<Link
										className={notification.isRead ? '' : 'notificationUnread'}
										onClick={() => {
											setRead(notification?.notificationUuid);
										}}
										to={`/manage`}>
										{splitNotificationType(notification.notificationType)[1] === 'added'
											? `Welcome ${notification?.notificationData?.name} to the Team!`
											: ''}
									</Link>
								</>
							)}
							{splitNotificationType(notification.notificationType)[0] === 'team' &&
								teamState.userPermissions['isAdmin'] && (
									<>
										<Link
											className={notification.isRead ? '' : 'notificationUnread'}
											onClick={() => {
												setRead(notification?.notificationUuid);
											}}
											to={`/manage/trusted`}>
											{`Trusted supplier ${notification?.notificationData?.teamName} suggested by ${notification?.notificationData?.suggestedBy?.name}`}
										</Link>
									</>
								)}
						</div>
					);
				})
			) : (
				<h5 key={1}>No notifications yet</h5>
			)}
			<Link className='dashboardBtnSeeAll linkBtn' to={`/notifications`}>
				See all notifications
			</Link>
		</div>
	);
}
