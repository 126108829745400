import React from 'react';

export default function ImgModal(props) {
	const { src, downloadImg, setShowOverlay, setShow, file } = props;
	return (
		<div className='flexModal'>
			<img src={src} alt='Modal image' />
			<button
				className='btn3-primary marginTop-20 marginBottom-10'
				onClick={() => {
					downloadImg(file);
				}}>
				Download
			</button>
			<button
				className='btn3-secondary marginBottom-20'
				onClick={() => {
					setShow(false);
					setShowOverlay(false);
				}}>
				Cancel
			</button>
		</div>
	);
}
