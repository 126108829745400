import React from 'react';
import { ArrowPrevLeftIcon } from '../SVGIcons/SVGIcons';

export default function ArrowLeft(props) {
	const { disabled, color, previous } = props || {};
	return (
		<div className='arrowLeft' onClick={disabled ? null : previous}>
			<ArrowPrevLeftIcon
				iconClass={`${color === 'white' ? 'circleArrowLeftIconWhite' : 'circleArrowLeftIcon'}${
					disabled ? ' disabledDiv' : ''
				}`}
			/>
		</div>
	);
}
