import React, { useContext, useEffect, useState } from 'react';
import DashboardNotifications from './DashboardNotifications';
import Carousel from '../global/Carousel/Carousel';
import SupportIcon from '../global/Tutorial/SupportIcon';
import TutorialPrompt from '../global/Tutorial/TutorialPrompt';
import Axios from 'axios';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import config from '../../config';
import Loading from '../../components/Loading/Loading';

export default function Dashboard() {
	const [loaded, setLoaded] = useState();
	const [ordersCount, setOrdersCount] = useState();
	const [notificationsCount, setNotificationsCount] = useState();

	let { teamState } = useContext(CurrentTeamContext);
	let { notificationsState } = useContext(NotificationContext);

	const countOrdersByStatus = (data) => {
		const orderTypeCounts = {
			drafts: 0,
			purchasesPending: 0,
			purchasesProcessing: 0,
			purchasesDispatched: 0,
			salesPending: 0,
			salesProcessing: 0,
			awaitingSuborder: 0,
			awaitingDispatch: 0,
		};

		if (data) {
			for (let i = 0; i < data.length; i++) {
				switch (data[i].orderProcessingStatus) {
					case 1:
						if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
							orderTypeCounts.drafts++;
						}
						break;
					case 2:
						if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
							orderTypeCounts.purchasesPending++;
						} else {
							orderTypeCounts.salesPending++;
						}
						break;
					case 3:
						if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
							orderTypeCounts.purchasesProcessing++;
						} else {
							orderTypeCounts.salesProcessing++;
						}
						break;
					case 5:
						if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
							orderTypeCounts.purchasesDispatched++;
						} else {
							orderTypeCounts.salesDispatched++;
						}
						break;
				}
			}
		}
		return orderTypeCounts;
	};

	const countNotificationsByType = (data) => {
		const notificationCounts = {
			messages: 0,
			pricingReviews: 0,
			unreadNotifications: 0,
		};

		if (data) {
			for (let i = 0; i < data.length; i++) {
				switch (data[i].notificationType) {
					case 'message.created':
						notificationCounts.messages++;
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
					case 'order.priceSuggested':
						notificationCounts.pricingReviews++;
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
					default:
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
				}
			}
		}
		return notificationCounts;
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			function handleRes(data, loaded) {
				setOrdersCount(countOrdersByStatus(data?.data));
				setNotificationsCount(countNotificationsByType(notificationsState?.notifications));
				setLoaded(loaded);
			}
			try {
				Axios({
					url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?skip=0&search=&sortDate=desc&sortAlphabet=&teamType=`,
					method: 'GET',
				})
					.then((response) => {
						if (response?.data?.data) {
							handleRes(response?.data, true);
						} else {
							handleRes(null, true);
						}
					})
					.catch((err) => {
						console.log(err);
						handleRes(null, true);
						return err;
					});
			} catch (err) {
				handleRes(null, true);
				return err;
			}
		}
		return () => (mounted = false);
	}, []);
	return (
		<div className='dashboardContainer'>
			{loaded ? (
				<>
					<div className='mainOverview'>
						<DashboardNotifications ordersCount={ordersCount} notificationsCount={notificationsCount} />
					</div>
					<Carousel backColor={'trustedBg'} type={'suppliers'} mode={'light'} />
					<Carousel backColor={'learningZoneBack'} type={'learningZone'} mode={'dark'} />
					<TutorialPrompt page='dashboard' />
					<SupportIcon />
				</>
			) : (
				<Loading type={'circle'} />
			)}
		</div>
	);
}
