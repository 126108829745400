import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Axios from 'axios';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import NotificationSwitch from './NotificationSwitch';
import ListingPagination from '../../components/Pagination/ListingPagination';
import config from '../../config';

export default function Notifications() {
	const [loaded, setLoaded] = useState(false);
	const [reload, setReload] = useState(false);
	const [notifications, setNotifications] = useState(null);
	const [pages, setPages] = useState(0);
	const [skip, setSkip] = useState(0);
	const [message, setMessage] = useState(null);

	const topOfPage = useRef(null);

	const { teamState } = useContext(CurrentTeamContext);
	let { notificationsDispatch } = useContext(NotificationContext);

	const params = useParams();

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * 20);
		setSkip(newSkip);
		setReload(true);
		topOfPage.current.scrollIntoView({ behavior: 'smooth' });
	}

	function deleteNotification(notification, cb) {
		Axios({
			url: `${config.apiv1}/notification/notification.delete/${notification.notificationUuid}`,
			method: 'DELETE',
		}).then((response) => {
			if (response?.data) {
				const halfSecond = 500;
				notificationsDispatch({ type: 'ACK_NOTIFICATION', payload: notification });
				setReload(true);
				setTimeout(() => {
					cb(false);
				}, halfSecond);
			}
		});
	}

	function setRead(notificationUuid) {
		Axios({
			url: `${config.apiv1}/notification/notification.update/${notificationUuid}`,
			method: 'PATCH',
			data: {
				isRead: true,
			},
		}).then((response) => {
			notificationsDispatch({ type: 'SET_READ', payload: response.data.updatedNotification });
			setReload(true);
		});
	}

	function checkHref(target) {
		if (window.location.href === `${config.host2}${target}`) {
			window.location.reload();
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (!loaded || reload) {
				try {
					Axios({
						url: `${config.apiv1}/notification/notifications.read/${teamState.currentTeam.teamUuid}?skip=${
							skip || ''
						}&limit=${20}&type=${params.notificationType || ''}`,
						method: 'GET',
					}).then((res) => {
						if (res?.data?.data) {
							setPages(Math.ceil(res.data.totalDocuments / 20));
							setNotifications(res.data.data);
							setLoaded(true);
							setReload(false);
						} else {
							setMessage('You have no notifications');
						}
					});
				} catch (err) {
					setMessage('Error when fetching notifications');
					console.log(err);
				}
			}
		}
		return () => (mounted = false);
	}, [skip, reload]);

	return (
		<div className='orderListingContainer'>
			<h3>{message}</h3>
			<div ref={topOfPage}></div>
			{notifications &&
				notifications.map((notification, key) => {
					return (
						<React.Fragment key={key}>
							<NotificationSwitch
								deleteNotification={deleteNotification}
								notification={notification}
								checkHref={checkHref}
								setRead={setRead}
							/>
						</React.Fragment>
					);
				})}
			{pages > 0 && <ListingPagination handleClick={handlePagination} pages={pages} />}
		</div>
	);
}
