import Axios from 'axios';
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import OrderListItem from './OrderListItem';
import ListingPagination from '../../components/Pagination/ListingPagination';
import Filter from '../global/Filter/Filter';

export default function OrderList() {
	const [orders, setOrders] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [message, setMessage] = useState('');
	const [sortingDate, setSortingDate] = useState('desc');
	const [sortingAlphabet, setSortingAlphabet] = useState('desc');
	const [search, setSearch] = useState(null);
	const [limit, setLimit] = useState(20);
	const [skip, setSkip] = useState(0);
	const [pages, setPages] = useState(0);

	const topOfPage = useRef(null);

	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);
	let location = useLocation();

	async function handleSort(sortType, sortData) {
		if (sortType === 'date') {
			setSortingDate(sortData);
			fetchOrders(sortType, sortData);
		} else if (sortType === 'alphabet') {
			setSortingAlphabet(sortData);
			fetchOrders(sortType, sortData);
		}
	}

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * 20);
		setSkip(newSkip);
		topOfPage.current.scrollIntoView({ behavior: 'smooth' });
	}

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setSearch(searchValue);
		}),
		[]
	);

	function debounce(cb, delay = 700) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	function fetchOrders(sortType, sortData) {
		async function handleRes(data, message, loaded) {
			const onePage = 0;
			setOrders(data?.data);
			setPages(data?.data ? Math.ceil(data?.totalDocuments / limit) : onePage);
			setMessage(message);
			setLoaded(loaded);
		}

		if (userState.isAuthenticated && teamState.currentTeam) {
			try {
				const presetType = new URLSearchParams(location.search).get('type');
				const presetStatus = new URLSearchParams(location.search).get('status');
				Axios({
					url: `${config.apiv1}/order/orders.read/${
						teamState.currentTeam.teamUuid
					}?limit=${limit}&skip=${skip}&search=${search || ''}&sortDate=${
						sortType === 'date' ? sortData : ''
					}&sortAlphabet=${sortType === 'alphabet' ? sortData : ''}&status=${presetStatus || ''}&teamType=${
						presetType || ''
					}`,
					method: 'GET',
				})
					.then((response) => {
						if (response?.data?.data) {
							handleRes(response?.data, '', true);
						} else {
							console.log('False');
							handleRes(null, 'No orders found', true);
						}
					})
					.catch((err) => {
						console.log(err);
						handleRes(null, 'No orders found', true);
						return err;
					});
			} catch (err) {
				handleRes(null, 'Error fetching orders', true);
				return err;
			}
		}
	}

	useEffect(() => {
		fetchOrders('date', 'desc');
	}, [teamState.reload, skip, search]);

	return (
		<div className='orderListingContainer'>
			<div className='orderFilterOptions'>
				<label htmlFor='search'>Search</label>
				<input
					type='text'
					name='search'
					className='textFieldInputSearch'
					onChange={(e) => {
						updateDebounceSearch(e.target.value);
						setLoaded(false);
					}}
				/>
				<Filter
					filterTypes={[
						{ text: 'Certified', type: 'certified' },
						{ text: 'Trusted', type: 'trusted' },
					]}
				/>
			</div>
			{message && (
				<div className='listMessage'>
					<h4>{message}</h4>
				</div>
			)}
			{loaded ? (
				<>
					{pages > 0 && (
						<>
							{/* <ListSortBar
								sortingDate={sortingDate}
								sortingAlphabet={sortingAlphabet}
								handleSort={handleSort}
							/> */}
						</>
					)}
					<div ref={topOfPage}></div>
					{userState.isAuthenticated &&
						loaded &&
						orders?.map((order, key) => {
							return (
								<React.Fragment key={key}>
									<OrderListItem
										order={order}
										isSeller={order.sellerTeamUuid === teamState.currentTeam.teamUuid}
									/>
								</React.Fragment>
							);
						})}

					{pages > 0 && <ListingPagination pages={pages} handleClick={handlePagination} />}
				</>
			) : (
				<Loading type={'circle'} />
			)}
		</div>
	);
}
