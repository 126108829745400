import Axios from 'axios';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Checkmark, ErrorIcon } from '../../components/SVGIcons/SVGIcons';
import config from '../../config';
import authConfig from '../../authConfig.json';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import Loading from '../../components/Loading/Loading';
import PermissionsForm from '../../components/Forms/PermissionsForm';

export default function Invite(props) {
	const [permissions, setPermissions] = useState({});
	const [role, setRole] = useState(null);

	const [loading, setLoading] = useState(false);
	const [responseMsg, setResponseMsg] = useState(null);
	const [responseErrorMsg, setResponseErrorMsg] = useState(null);
	const [email, setEmail] = useState('');
	const [invitesList, setInvitesList] = useState([]);
	const [inviteType, setInviteType] = useState(null);
	const [customText, setCustomText] = useState(null);

	const { teamState } = useContext(CurrentTeamContext);

	function sendInvites(invites) {
		setLoading(true);
		Axios({
			url: `${config.apiv1}/email/member.invite`,
			method: 'POST',
			data: {
				senderName: teamState.currentTeam.teamInfo.teamName,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				invitations: invites,
				clientObject: {
					clientId: authConfig.clientId,
					redirectUri: window.location.origin,
					scope: 'openid profile email offline_access',
				},
			},
		})
			.then((res) => {
				if (res.data.ok) {
					setEmail('');
					setResponseErrorMsg('');
					setInvitesList([]);
					setResponseMsg('Invitation(s) successfully sent!');
				} else {
					setResponseErrorMsg('Something went wrong when sending the invitation(s)');
					setResponseMsg('');
				}
				setLoading(false);
			})
			.catch((err) => {
				setResponseErrorMsg('Something went wrong when sending the invitation(s)');
				setResponseMsg('');
				setLoading(false);
				return err;
			});
	}

	function addInviteToList(newInvite) {
		let inviteExists = invitesList.filter((email) => email === newInvite);
		if (!newInvite) {
			setResponseErrorMsg('Please enter a valid email');
			return;
		}
		if (inviteExists.length === 0) {
			setInvitesList([...invitesList, newInvite]);
			setResponseErrorMsg('');
			setResponseMsg('');
			setEmail('');
		} else {
			setResponseErrorMsg('Email already in list');
			setResponseMsg('');
		}
	}

	function checkEmail() {
		if (email.includes('@') && email.split('@')[1]) {
			return true;
		}
	}

	const handlePermissionsInput = (e) => {
		const { name, checked } = e.target;
		setPermissions((permissions) => ({
			...permissions,
			[name]: checked,
		}));
	};

	return (
		<div className='invitationContainer'>
			<h2>{props.header}</h2>
			{!inviteType && (
				<div className='buttonColumn'>
					<button
						className='submitBtn'
						onClick={() => {
							setInviteType('single');
						}}>
						Invite single member
					</button>
					<h4>Or</h4>
					<button
						className='submitBtn'
						onClick={() => {
							setInviteType('multiple');
						}}>
						Create invite list
					</button>
				</div>
			)}
			{inviteType === 'single' && (
				<>
					{responseMsg ? <h4>{responseMsg}</h4> : null}
					{responseErrorMsg ? <h4 className='error'>{responseErrorMsg}</h4> : null}
					{loading && <Loading type='circle' />}
					<div className='inputRow'>
						<p className='bold' htmlFor='email'>
							Email:
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								type='email'
								name='email'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								required
							/>
							{email !== '' && checkEmail() ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<textarea
						value={customText}
						cols='30'
						rows='10'
						onChange={(e) => {
							setCustomText(e.target.value);
						}}></textarea>
					<PermissionsForm
						role={role}
						permissions={permissions}
						setRole={setRole}
						setPermissions={setPermissions}
						handlePermissionsInput={handlePermissionsInput}
						required={true}
					/>
					<button
						className='functionalButtonPrimary'
						disabled={loading}
						onClick={() => {
							sendInvites([
								{ recipientEmail: email, role: role, permissions: permissions, customText: customText },
							]);
							setRole(null);
							setCustomText(null);
							setPermissions({});
						}}>
						Send invite
					</button>
				</>
			)}
			{inviteType === 'multiple' && (
				<>
					{responseMsg ? <h4>{responseMsg}</h4> : null}
					{responseErrorMsg ? <h4 className='error'>{responseErrorMsg}</h4> : null}
					{loading && <Loading type='circle' />}
					<div className='inputRow'>
						<p className='bold' htmlFor='email'>
							Email:
						</p>
						<div className='inputContainer'>
							<input
								className='textFieldInput'
								type='email'
								name='email'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								required
							/>
							{email !== '' && checkEmail() ? (
								<Checkmark iconClass={'inputIcon'} />
							) : (
								<ErrorIcon iconClass={'inputIcon'} />
							)}
						</div>
					</div>
					<textarea
						value={customText}
						cols='30'
						rows='10'
						onChange={(e) => {
							setCustomText(e.target.innerText);
						}}></textarea>
					<PermissionsForm
						role={role}
						permissions={permissions}
						setRole={setRole}
						setPermissions={setPermissions}
						handlePermissionsInput={handlePermissionsInput}
						required={true}
					/>
					<button
						className='functionalButtonSecondary'
						onClick={() => {
							addInviteToList({
								recipientEmail: email,
								role: role,
								permissions: permissions,
								customText: customText,
							});
							setRole(null);
							setCustomText(null);
							setPermissions({});
						}}>
						Add to list
					</button>
					<div className='multiInviteList'>
						{invitesList.map((invite, key) => {
							return (
								<React.Fragment key={key}>
									<h4>{invite.recipientEmail}</h4>
								</React.Fragment>
							);
						})}
					</div>
					{invitesList.length > 0 && (
						<button
							className='functionalButtonPrimary'
							disabled={loading}
							onClick={() => {
								sendInvites(invitesList);
							}}>
							Send invitations
						</button>
					)}
				</>
			)}
		</div>
	);
}
