import React from 'react';
import { Link } from 'react-router-dom';
import { dateConverter } from '../../helpers';

export default function MemberNotification(props) {
	const { notification, setRead, checkHref, deleteNotification } = props || {};
	const { notificationData } = notification;
	return (
		<>
			<div className='orderItemTopRow'>
				<div className='orderItemText'>
					<Link to={`/manage`}>
						<span
							className={`bold orderLink${notification.isRead ? '' : ' unread'}`}
							onClick={() => {
								setRead(notification.notificationUuid);
								checkHref(`/manage`);
							}}>
							{`Welcome ${notificationData.name} to the Team!`}
						</span>
					</Link>
				</div>
				<div className='orderItemText'>
					<span className='bold'>{dateConverter(notification.createdAt)}</span>
				</div>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						deleteNotification(notification);
					}}>
					Delete
				</button>
				<button
					className='functionalButtonSecondary'
					onClick={() => {
						setRead(notification?.notificationUuid);
					}}>
					Set read
				</button>
			</div>
			<div className='orderItemBottomRow'></div>
		</>
	);
}
